import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from "@mui/material/TablePagination";
import colors from '../../../Utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPartnerClientDetails, getPartnerUser, loginPartner } from '../../../Services';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheerioButton from '../../../Components/CheerioButton';
import { MailCsvModal } from '../../CreatorAnalyticsNew/MailCsvModal';
import images from '../../../Utils/images';
import AddNewAccountModal from './AddNewAccountModal';
import { Tooltip } from '@mui/material';
import UpdateBalanceModal from './UpdateBalanceModal';
import Helper from '../../../Utils/Helper';
import AddTopUpModal from '../../CreatorNew/Components/AddTopUpModal';

const DashboardItemCard = ({ header, body }) => {
    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-start'
                style={{
                    height: 112,
                    width: '32%',
                    paddingBlock: 20,
                    paddingInline: 20,
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 12,
                    backgroundColor: colors.white01,
                }}>
                <h4 style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: colors.black,
                }}>
                    {header}
                </h4>
                <p style={{
                    fontSize: 36,
                    fontWeight: 900,
                    color: colors.primary03,
                    whiteSpace: 'nowrap',
                    wordBreak: 'break-all',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}>
                    {body}
                </p>
            </div>
        </>
    )
}

const ResellerDashboard = ({ partnerData, partnerCheerioData }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [clientData, setClientData] = useState([]);
    const [showMailModal, setShowMailModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Dashboard')
    const [isAddNewAccountModal, setIsAddNewAccountModal] = useState(false);
    const [isUpdateBalanceModal, setIsUpdateBalanceModal] = useState(false);
    const [isAddTopUpModalOpen, setIsAddTopUpModalOpen] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    // const localdata = useSelector((state) => state.main.localdata);

    const getTierWorkspaceCount = () => {
        let lastChar = partnerCheerioData?.premium_details?.appsumo?.plan_id?.slice(-1);
        let count = 0;
        let addon = 0
        switch (lastChar) {
            case "1":
                count = 1;
                break;
            case "2":
                count = 2;
                break;
            case "3":
                count = 4;
                break;
            case "4":
                count = 6;
                break;
            case "5":
                count = 10;
                break;
            default:
                count = 0;
                break;
        }
        if (partnerCheerioData?.addOnSubscriptionActive) {
            addon = 5
        }
        return count + addon;
    }

    const workSpaceLimit = partnerData?.partnerUserDetails?._id === '66c12a6a2447ac755dbf923a' ? 3 : partnerCheerioData?.isAppsumoUser && getTierWorkspaceCount();

    const columns = [
        // { id: '_id', label: 'Id', align: 'left', minWidth: 150},
        { id: 'name', label: 'Username', align: 'left', minWidth: 300 },
        { id: 'mobile', label: 'Phone No.', align: 'left', minWidth: 200 },
        { id: 'emailId', label: 'Email Id', align: 'left', minWidth: 250 },
        { id: 'createdAt', label: 'Registered on', align: 'left', minWidth: 150 },
        { id: 'planEnd', label: 'Expiry date', align: 'left', minWidth: 150 },
        { id: 'walletBalance', label: 'Wallet Balance', align: 'center', minWidth: 250 },
        // { id: 'whatsapp', label: 'WhatsApp', align: 'center', minWidth: 150},
        { id: 'emailCredits', label: 'Email', align: 'center', minWidth: 150 },
        // { id: 'sms', label: 'SMS', align: 'center', minWidth: 150},
        // { id: 'webhookStatus', label: 'Status', align: 'center', minWidth: 150},
        { id: 'Action', label: 'Action', align: 'center', minWidth: 175 }
    ];

    const resDataUser = ['_id', 'name', 'mobile', 'createdAt']
    const resDataUserBalance = ['sms', 'whatsapp', 'email']

    const getCLientDate = (e) => {
        const date = new Date(e);
        const dd = date.getDate().toString().padStart(2, '0');
        const mm = (date.getMonth() + 1).toString().padStart(2, '0');
        const yy = date.getFullYear();
        if (dd && mm && yy) {
            return dd + '-' + mm + '-' + yy;
        } else {
            return ('No plan active')
        }
    };

    useEffect(() => {
        console.log("helper ---> ", Helper.PartnerToken)
        console.log("token ---> ", partnerData?.token)
        if (partnerData?.token || Helper.PartnerToken) {
            getPartnerUserAPICall();
        } else {
            navigate('/partner');
        }
    }, [])

    useEffect(() => {
        if (page && (partnerData?.token || Helper.PartnerToken)) {
            getPartnerUserAPICall();
        }
    }, [page])

    const getPartnerUserAPICall = () => {
        let token = partnerData?.token ?? Helper.PartnerToken;
        let data = {
            page: page,
            limit: limit
        };
        try{
            getPartnerUser(token, data).then((res) => {
                if (res?.flag) {
                    setClientData(res?.data)
                } else {
                    toast.error(res?.message, { position: 'top-right' })
                }
            })
        }catch(err){
            toast.error(err?.message, {position:'top-right'});
        }
    }

    const onclickLoginAction = (id) => {
        let token = partnerData?.token ?? Helper.PartnerToken;
        let data = {
            _id: id,
        };
        try{
            loginPartner(token, data).then((res) => {
                if (res?.flag) {
                    dispatch({ type: 'addAuthToken', payload: res?.data?.token });
                    navigate('/creator');
                } else {
                    toast.error(res?.message, { position: 'top-right' })
                }
            })
        }catch(err){
            toast.error(err?.message, {position:'top-right'});
        }
    }

    const downloadPartnerDetailsAPICall = (email) => {
        let token = partnerData?.token ?? Helper.PartnerToken;
        let data = {
            email: email,
        };
        try{
            downloadPartnerClientDetails(token, data).then((res) => {
                if (res?.flag) {
                    toast.success('Client details report sent to the entered email successfully', { position: 'top-right' })
                } else {
                    toast.error(res?.message, { position: 'top-right' })
                }
            })
        }catch(err){
            toast.error(err?.message, {position:'top-right'});
        }
        
    }

    const onclickUpdateAction = (data) => {
        setUpdateData(data);
        setIsUpdateBalanceModal(true);
    }

    return (
        <>
            <div className='partner_main_container'>
                <div className='partner_main_container__sidebar'>
                    <img src={images.CheerioAILogo} style={{
                        width: '100%',
                        height: 40,
                        objectFit: 'contain',
                        marginBlockEnd: 20,
                    }}></img>
                    <div className='psidebar_container' onClick={() => {
                        setSelectedTab('Dashboard')
                    }}
                        style={{ backgroundColor: selectedTab === 'Dashboard' ? colors.primary01 : '' }}
                    >
                        <img
                            src={selectedTab === 'Dashboard' ? images.DashboardSel : images.Dashboard}
                            className='psidebar_container__image'
                        ></img>
                        <p className='psidebar_container__text'
                            style={{ color: selectedTab === 'Dashboard' ? colors.themeDarkBlue : colors.black }}>
                            {'Dashboard'}
                        </p>
                    </div>
                    <div className='psidebar_container' onClick={() => {
                        setSelectedTab('Client Details')
                    }}
                        style={{ backgroundColor: selectedTab === 'Client Details' ? colors.primary01 : '' }}
                    >
                        <img
                            src={selectedTab === 'Client Details' ? images.SNContactsAllSel : images.SNContactsAll}
                            className='psidebar_container__image'
                        ></img>
                        <p className='psidebar_container__text'
                            style={{ color: selectedTab === 'Client Details' ? colors.themeDarkBlue : colors.black }}
                        >
                            {'Client Details'}
                        </p>
                    </div>
                </div>
                <div className='partner_main_container__body'>
                    {isAddNewAccountModal &&
                        <AddNewAccountModal
                            setIsModalOpen={setIsAddNewAccountModal}
                            token={partnerData?.token ?? Helper.PartnerToken}
                            closerefreshaction={() => {
                                setIsAddNewAccountModal(false);
                                getPartnerUserAPICall();
                            }}
                        />
                    }
                    {isUpdateBalanceModal &&
                        <UpdateBalanceModal
                            setIsModalOpen={setIsUpdateBalanceModal}
                            token={partnerData?.token ?? Helper.PartnerToken}
                            partnerData={partnerData}
                            clientData={updateData}
                            closerefreshaction={() => {
                                setIsUpdateBalanceModal(false);
                                getPartnerUserAPICall();
                            }}
                        />
                    }
                    {isAddTopUpModalOpen &&
                        <AddTopUpModal
                            isAddTopUpModalOpen={isAddTopUpModalOpen}
                            setIsAddTopUpModalOpen={setIsAddTopUpModalOpen}
                            partnerToken={partnerData?.token ?? Helper.PartnerToken}
                            isPartner={true}
                            updatePartnerDashboard={() => {
                                setIsAddTopUpModalOpen(false);
                                getPartnerUserAPICall();
                            }}
                        />
                    }
                    {selectedTab === 'Dashboard' ? (
                        <>
                            <div className='reseller_dashboard_container'>
                                <div className='reseller_dashboard_container__header_row'>
                                    <h3>
                                        {'Dashboard'}
                                    </h3>
                                    <div className='d-flex flex-row justify-content-center align-items-center'>
                                        <CheerioButton
                                            borderStyle={{
                                                borderColor: colors.primary03,
                                                width: 'auto',
                                                paddingBlock: 8,
                                                paddingInline: 28,
                                                marginInlineEnd: 20,
                                            }}
                                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
                                            btnText={'Add new account'}
                                            backColor={colors.primary03}
                                            disabled={clientData?.partner?.linkedAccounts >= workSpaceLimit ? true : false}
                                            onclick={() => {
                                                console.log("add new client");
                                                if (workSpaceLimit) {
                                                    setIsAddNewAccountModal(true);
                                                } else {
                                                    toast('Login to your cheerio account first to gain access to add new accounts', { position: 'top-right' })
                                                }
                                                // setShowMailModal(true);
                                            }}
                                        />
                                        <CheerioButton
                                            borderStyle={{
                                                borderColor: colors.black,
                                                width: 'auto',
                                                paddingBlock: 8,
                                                paddingInline: 28,
                                            }}
                                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
                                            btnText={'Recharge Now'}
                                            backColor={colors.white01}
                                            onclick={() => {
                                                console.log("Recharge now")
                                                setIsAddTopUpModalOpen(true);
                                                // setShowMailModal(true);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between align-items-center w-100 my-4'>
                                    <DashboardItemCard
                                        header={'Current balance'}
                                        body={`$${clientData?.partner?.walletBalance}`}
                                    />
                                    <DashboardItemCard
                                        header={'Email credits'}
                                        body={clientData?.partner?.emailCredits}
                                    />
                                    <DashboardItemCard
                                        header={'Active accounts'}
                                        body={workSpaceLimit ? `${clientData?.partner?.linkedAccounts}/${workSpaceLimit}` : `${clientData?.partner?.linkedAccounts}`}
                                    />
                                </div>
                            </div>
                        </>
                    ) : selectedTab === 'Client Details' && (
                        <>
                            <div className='reseller_dashboard_container'>
                                {showMailModal &&
                                    <MailCsvModal
                                        show={showMailModal}
                                        setShow={setShowMailModal}
                                        partnerDashboard={true}
                                        partnerDashboardReportAction={(email) => {
                                            downloadPartnerDetailsAPICall(email);
                                        }}
                                    />
                                }
                                <div className='reseller_dashboard_container__header_row'>
                                    <h3>
                                        {'Manage All Clients'}
                                    </h3>
                                    <div className='d-flex flex-row justify-content-center align-items-center'>
                                        <CheerioButton
                                            borderStyle={{
                                                borderColor: colors.primary03,
                                                width: 'auto',
                                                paddingBlock: 8,
                                                paddingInline: 28,
                                                marginInlineEnd: 20,
                                            }}
                                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
                                            btnText={'Add new account'}
                                            backColor={colors.primary03}
                                            onclick={() => {
                                                console.log("add new client");
                                                setIsAddNewAccountModal(true);
                                                // setShowMailModal(true);
                                            }}
                                        />
                                        <CheerioButton
                                            borderStyle={{
                                                borderColor: colors.black,
                                                width: 'auto',
                                                paddingBlock: 8,
                                                paddingInline: 28,
                                            }}
                                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.black }}
                                            btnText={'Download client details'}
                                            backColor={colors.white01}
                                            onclick={() => {
                                                setShowMailModal(true);
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className='reseller_dashboard_container__body_div'>
                                    <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                fontSize: 16,
                                                                fontWeight: 600,
                                                                color: colors.black,
                                                                backgroundColor: colors.white04,
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {clientData?.associatedUsers?.length > 0 && clientData?.associatedUsers?.map((row, index) => (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                        {columns.map((column) => {
                                                            const value = (column.id == 'emailId') ? row?.user['email'] :
                                                                (resDataUser.includes(column.id)) ? row?.user[column.id] :
                                                                    (resDataUserBalance.includes(column.id)) ? row?.user?.balance[column?.id] :
                                                                        row[column?.id];
                                                            return (
                                                                <>
                                                                    <TableCell key={column.id} align={column.align} style={{
                                                                        minWidth: column.minWidth,
                                                                    }}>
                                                                        {column.id === 'createdAt' || column.id === 'planEnd' ? (
                                                                            <p style={{ color: colors.black, fontSize: 14 }}>
                                                                                {getCLientDate(value)}
                                                                            </p>
                                                                        ) : column.id === 'webhookStatus' ? (
                                                                            <p style={{ color: colors.black, fontSize: 14, width: '100%', textAlign: 'center' }}>
                                                                                {value ? 'Active' : 'Inactive'}
                                                                            </p>
                                                                        ) : column.id === 'Action' ? (
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                gap: 15,
                                                                            }}>
                                                                                <Tooltip title={'Update balance'}>
                                                                                    <img src={images.EditBlue}
                                                                                        style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                                                                        onClick={() => { console.log("edit balance clicked"); onclickUpdateAction(row) }}
                                                                                    ></img>
                                                                                </Tooltip>
                                                                                <Tooltip title={'Login'}>
                                                                                    <img src={images.LoginBlue}
                                                                                        style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                                                                        onClick={() => { onclickLoginAction(row?.user?._id) }}
                                                                                    ></img>
                                                                                </Tooltip>

                                                                                {/* <p style={{fontSize:14,color:colors.linkblue,cursor:'pointer'}} 
                                                                        onClick={()=>{
                                                                            onclickLoginAction(row?.user?._id);
                                                                        }}
                                                                    >
                                                                        {'Login'}
                                                                    </p> */}
                                                                            </div>
                                                                        ) : (
                                                                            <p style={{ color: colors.black, fontSize: 14 }}>{value}</p>
                                                                        )
                                                                        }
                                                                    </TableCell>
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={clientData?.associatedUsers?.length}
                                        rowsPerPage={limit}
                                        page={page - 1}
                                        onPageChange={() => {
                                            if (clientData?.partner?.linkedUsers?.length > clientData?.associatedUsers?.length) {
                                                setPage(page + 1);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

        </>
    )
}

export default ResellerDashboard