import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { IoIosSearch } from 'react-icons/io';
import { RxCross1 } from 'react-icons/rx';

const UserAttributesModal = ({
  setUserAttributesOpen,
  userAttributesData,
  selectedUserAttributeAction,
  positionStyle,
  repliesSidebar = false,
  attributesObject = {},
  keyPressed = 'none',
  setKeyPressed,
  addNewAttributeAction,
}) => {
  // const defaultValues = repliesSidebar ? ['Add new attribute'] : [];
  const defaultValues = repliesSidebar ? [] : []; 

  const [filteredList, setFilteredList] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const filteredRef = useRef();
  const mobileWidth = window.innerWidth < 700 ? true : false;

  useEffect(() => {
    createFilteredList();
  }, [filterValue]);

  useEffect(() => {
    if (keyPressed === 'Up') {
      if (selectedIndex !== 0) {
        setSelectedIndex(selectedIndex - 1);
        setKeyPressed('none');
      }
    } else if (keyPressed === 'Down') {
      console.log('down detected');
      if (selectedIndex < filteredList?.length - 1) {
        setSelectedIndex(selectedIndex + 1);
        setKeyPressed('none');
      }
    } else if (keyPressed === 'Tab') {
      if (defaultValues.includes(filteredList[selectedIndex])) {
        // if (filteredList[selectedIndex] === 'Add new attribute') {
        //   addNewAttributeAction();
        // } else if (!repliesSidebar) {
          selectedUserAttributeAction(filteredList[selectedIndex]);
        // }
      } else {
        if (
          !(
            repliesSidebar &&
            Object.keys(attributesObject).includes(filteredList[selectedIndex]?.attribute)
          )
        ) {
          selectedUserAttributeAction(filteredList[selectedIndex]?.attribute);
        }
      }
      setKeyPressed('none');
    }
  }, [keyPressed]);

  useEffect(() => {
    if (selectedIndex >= 0 && filteredList?.length > 0) {
      const element = filteredRef.current.querySelector(`[data-index="${selectedIndex}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }
    }
  }, [selectedIndex]);

  const createFilteredList = () => {
    let defaultFilteredList = defaultValues?.filter((item, index) => {
      if (filterValue?.length > 0) {
        if (item?.includes(filterValue)) {
          return item;
        }
      } else {
        return item;
      }
    });
    let attributesFilteredList = userAttributesData?.filter((item, index) => {
      if (filterValue?.length > 0) {
        if (item?.attribute?.includes(filterValue)) {
          return item;
        }
      } else {
        return item;
      }
    });
    setFilteredList([...defaultFilteredList, ...attributesFilteredList]);
    setSelectedIndex(0);
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setUserAttributesOpen(false);
        }}
      >
        <div
          class="addNewChatDropDown"
          style={{
            position: 'absolute',
            left: !mobileWidth && '100%',
            right: mobileWidth && '0',
            zIndex: 2,
            height: 250,
            width: 200,
            padding: 16,
            marginInlineStart: 10,
            backgroundColor: colors.white01,
            border: `1px solid ${colors.borderwhite}`,
            borderRadius: 16,
            boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.1)',
            scrollbarWidth: 0,
            overflowY: 'auto',
            ...positionStyle,
          }}
          className="d-flex flex-column justify-content-start align-items-start px-3 py-3 verythinscrollnew"
        >
          <div style={{ width: '100%', display: 'flex' }}>
            <InputGroup>
              <InputGroup.Text
                style={{
                  borderRightWidth: 0,
                  backgroundColor: 'transparent',
                  borderColor: '#B3B3B3',
                  paddingInline: 8,
                }}
              >
                <IoIosSearch size={16} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search attribute"
                style={{
                  borderTopRightRadius: filterValue ? '0' : 8,
                  borderBottomRightRadius: filterValue ? '0' : 8,
                  borderColor: '#B3B3B3',
                  borderLeftWidth: 0,
                  paddingInlineStart: 0,
                  zIndex: 1,
                  outline: 'none',
                  boxShadow: 'none',
                  borderRightWidth: filterValue ? '0' : '',
                }}
                onFocus={(e) => {
                  e.target.style.outline = 'none'; // Remove outline when focused
                  e.target.style.borderColor = '#B3B3B3';
                }}
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.target.value);
                  console.log('filter value', e.target.value, filterValue);
                  console.log('filteredList', filteredList);
                }}
              />
              {filterValue && (
                <InputGroup.Text
                  onClick={() => {
                    setFilterValue('');
                  }}
                  style={{
                    borderLeftWidth: 0,
                    backgroundColor: 'transparent',
                    borderColor: '#B3B3B3',
                    paddingInline: 8,
                    cursor: 'pointer',
                  }}
                >
                  <RxCross1 size={16} />
                </InputGroup.Text>
              )}
            </InputGroup>
          </div>
          {filteredList && filteredList?.length > 0 && (
            <div className="w-100" ref={filteredRef}>
              <>
                {filteredList.map((item, index) => {
                  return (
                    <>
                      {!Object.keys(attributesObject).includes(item?.attribute) && (
                        <div
                          key={index}
                          data-index={index}
                          className={`w-100 ${index == selectedIndex && !repliesSidebar && 'selectedIndex'}`}
                        >
                          {defaultValues.includes(item) ? (
                            <>
                              <div
                                className="d-flex flex-row justify-content-start align-items-center w-100 my-1 py-2 px-2 onhoverattr"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (item === 'Add new attribute') {
                                    addNewAttributeAction();
                                    console.log('new attribute action');
                                  } else if (!repliesSidebar) {
                                    selectedUserAttributeAction(item);
                                  }
                                }}
                                key={item}
                              >
                                <p
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                    color: colors.darkPurple,
                                  }}
                                >
                                  {`${item}`}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="d-flex flex-row justify-content-start align-items-center w-100 my-1 py-2 px-2 onhoverattr"
                                style={{
                                  cursor:
                                    repliesSidebar &&
                                      Object.keys(attributesObject).includes(item?.attribute)
                                      ? ''
                                      : 'pointer',
                                }}
                                onClick={() => {
                                  if (
                                    repliesSidebar &&
                                    Object.keys(attributesObject).includes(item?.attribute)
                                  ) {
                                    // no onclick for attributes that already exist
                                  } else {
                                    selectedUserAttributeAction(item?.attribute);
                                  }
                                }}
                                key={item?._id}
                              >
                                <p
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    wordBreak: 'break-all',
                                    color:
                                      repliesSidebar &&
                                        Object.keys(attributesObject).includes(item?.attribute)
                                        ? colors.greys03
                                        : colors.black,
                                  }}
                                >
                                  {`${item?.attribute}`}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

export default UserAttributesModal;
