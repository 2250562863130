import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import { useDispatch, useSelector } from 'react-redux';
import { V2EmailBuilderAdd, V2EmailBuilderEdit } from '../../../Services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AnnNewModal from '../../AnnouncementsNew/Components/AnnNewModal';
import Button from 'react-bootstrap/Button';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';

const EmailBuild = ({}) => {
  const emailEditorRef = useRef();
  const dispatch = useDispatch();

  const [emailJSON, setEmailJSON] = useState('');
  const [openANModal, setOpenANModal] = useState(false);
  const [builderReady, setBuilderReady] = useState(false);
  const [emailHTML, setEmailHTML] = useState({});
  const [templateName, setTemplateName] = useState(false);
  const [makeAnnClicked, setMakeAnnClicked] = useState(false);
  const [preview, setPreview] = useState(false);

  const templateNameRef = useRef('');
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const selectedJSON = useSelector((state) => state.main.selectedEmailJson);
  const [edit, setEdit] = useState(false);
  const isPremiumUser = localdata?.premium;
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedJSON) {
      templateNameRef.current.value = selectedJSON.emailName;
      // setTemplateName(selectedJSON.emailName);
      // loadHTML();
    }
  }, [selectedJSON]);

  // const enableButton = () => {
  //   if(templateNameRef.current.value.length === 0){
  //     if (buttonRef.current) {
  //       buttonRef.current.disabled = true;
  //     }
  //   }else{
  //     if (buttonRef.current) {
  //       buttonRef.current.disabled = false;
  //     }
  //   }
  // }

  // const buttonDisabled = templateNameRef.current.value === selectedJSON.emailName || !builderReady ;

  const exportHtml = () => {
    setMakeAnnClicked(true);
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setEmailHTML(html);
      console.log('exportHtml', html);
      console.log('save design', design);
      setEmailJSON(design);
    });
    // setOpenANModal(true);
  };

  // const loadHTML = () => {
  //   const templateJson = JSON.parse(selectedJSON.emailJSON);
  //   emailEditorRef.current.editor.loadDesign(templateJson);
  // }

  const onReady = () => {
    // editor is ready
    // you can load your template here;
    // console.log('onReady called',selectedJSON);
    if (selectedJSON) {
      const templateJson = JSON.parse(selectedJSON?.emailJSON);
      emailEditorRef.current.editor.loadDesign(templateJson);
    }
    setBuilderReady(true);
  };

  const downloadQR = () => {
    html2canvas(document.getElementsByClassName('blockbuilder-layer-selector')).then((canvas) => {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'QRCode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const saveDesignAction = () => {
    // downloadQR();
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setEmailHTML(html);
      console.log('design saved', design);
      setEmailJSON(design);
    });
  };

  // useEffect(() => {
  //   if (builderReady) {
  //     if (!edit) {
  //       saveDesignAction();
  //     }
  //   }
  // }, [builderReady]);

  useEffect(() => {
    if (emailJSON && !makeAnnClicked) {
      if (edit) {
        editDesignAPIcall();
      } else {
        saveDesignAPIcall();
      }
    }
  }, [emailJSON]);

  useEffect(() => {
    if (emailHTML&& makeAnnClicked) {
    setOpenANModal(true);
    }
  }, [emailHTML]);
  
  const saveDesignAPIcall = () => {
    toast.loading('Saving', { position: 'top-center', toastId: 1 });
    setBuilderReady(false);
    let data = {
      emailJSON: JSON.stringify(emailJSON),
      emailHTML: JSON.stringify(emailHTML),
      // emailName: templateName
      emailName:
        templateNameRef.current.value === ''
          ? `Untitled template ${Math.floor(Date.now() / 1000)}`
          : templateNameRef.current.value,
    };
    let token = authtoken;
    if (templateNameRef.current.value === '') {
      templateNameRef.current.value = data?.emailName;
    }
    V2EmailBuilderAdd(data, token).then((res) => {
      if (res.flag) {
        setBuilderReady(true);
        setEdit(true);
        toast.dismiss(1);
        console.log('success response ===> ', res);
        dispatch({ type: 'addSelectedEmailJson', payload: res?.data });

        if (!makeAnnClicked) {
          toast.success(`Design saved successfully`, {
            position: 'top-center',
            
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
        // navigate('/creator/design');
        // !makeAnnClicked && setCurrentScreen('Home');
      } else {
        setBuilderReady(true);
        toast.dismiss(1);
        console.log('error response ===> ', res);
        toast(`Error occured while saving template. Please try again later`, {
          position: 'top-center',
        });
      }
    });
  };

  const editDesignAPIcall = () => {
    let data = {
      emailJSON: JSON.stringify(emailJSON),
      emailHTML: JSON.stringify(emailHTML),
      // emailName: templateName
      emailName: templateNameRef.current.value,
    };
    let token = authtoken;
    let templateID = selectedJSON?._id;
    V2EmailBuilderEdit(data, token, templateID).then((res) => {
      if (res) {
        if (!makeAnnClicked) {
          toast.success(`Design saved successfully`, {
            position: 'top-center',
            
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
        // setSelectedJSON(res.data);
        // navigate('/creator/design');
      } else {
        console.log('error response ===> ', res);
        toast(`Error occured while saving template. Please try again later`, {
          position: 'top-center',
        });
      }
    });
  };

  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview('desktop');
      setPreview(true);
    }
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 h-100"
        style={{ paddingTop: '10px' }}
      >
        {openANModal && (
          <AnnNewModal
            setOpenANModal={setOpenANModal}
            EmailBuilder={true}
            // EBTemplateName={templateName}
            EBTemplateName={templateNameRef.current.value}
            EBHTML={emailHTML}
          />
        )}

        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ paddingInline: 40, marginBlockEnd: 20 }}
        >
          <div
            className="d-flex flex-row justify-content-start align-items-center"
            style={{ flex: 1 }}
          >
            <img
              src={images.BackArrowBlack}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                cursor: 'pointer',
                marginInlineEnd: 16,
              }}
              onClick={() => {
                //setShow(false);
                // navigate('/creator/templates/emailTemplates');
                navigate('/creator/design');
              }}
            ></img>
            <div className="w-50" style={{}}>
              <InputGroup className="" style={{}}>
                {/* <Form.Label style={{fontSize:16,fontWeight:600,color:colors.black}}>{'Name your template'}</Form.Label> */}
                <Form.Control
                  type="text"
                  placeholder="Enter template name"
                  className="btncustom"
                  style={{
                    borderRadius: 8,
                    border: 0,
                    backgroundColor: colors.white02,
                    fontSize: 20,
                    fontWeight: 500,
                    zIndex: 1,
                    color: colors.black,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                  }}
                  ref={templateNameRef}
                />
                {/* <InputGroup.Text style={{border:0,backgroundColor:colors.white02}} onClick={()=>{
                  console.log("++++++++",templateNameRef.current.value === selectedJSON.emailName);}}>
                  <img src={images.SNDesign} style={{height:24,width:24,objectFit:'contain'}}></img>
                </InputGroup.Text> */}
                {/* <Button></Button> */}
              </InputGroup>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center">
            <CheerioButton
              borderStyle={{
                paddingBlock: 10,
                marginInline: 16,
                border: `1px solid ${colors.black}`,
              }}
              textStyle={{ fontSize: 12, fontWeight: 600, color: colors.black }}
              btnText={'Save Design'}
              backColor={colors.white02}
              onclick={() => {
                saveDesignAction();
              }}
              // ref={buttonRef}
              disabled={builderReady ? false : true}
            />
            <CheerioButton
              borderStyle={{ paddingBlock: 10 }}
              textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
              id="BuilderMakeAnnouncementButton"
              btnText={'Make Announcement'}
              backColor={colors.black}
              onclick={() => {
                if (!isPremiumUser) return;
                exportHtml();
              }}
              // ref={buttonRef}
              disabled={builderReady && isPremiumUser ? false : true}
            />
            {/* <CheerioButton
              borderStyle={{ paddingBlock: 10 }}
              textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white }}
              id="BuilderMakeAnnouncementButton"
              btnText={preview ? 'Hide Preview' : 'Show  Preview'}
              backColor={colors.black}
              onclick={() => {
                togglePreview();
              }}
              // ref={buttonRef}
              disabled={builderReady ? false : true}
            /> */}
          </div>
          {/* <img src={images.BackArrowBlack} style={{height:24,width:24,objectFit:'contain',cursor:'pointer'}} onClick={()=>{setCurrentScreen('Home')}}></img>
          <h2 style={{marginBlock:12,fontSize:24,fontWeight:700,color:colors.black,width:'100%',marginInline:20}}>
              {'Build Email Template'}
          </h2> */}
        </div>

        <div className="d-flex flex-column justify-content-start w-100" style={{ flex: 1 }}>
          <EmailEditor
            style={{
              width: '100%',
              flex: 1,
              // height: '100%',
            }}
            ref={emailEditorRef}
            onReady={onReady}
            appearance={{
              theme: 'light',
              panels: {
                tools: {
                  dock: 'left',
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EmailBuild;
