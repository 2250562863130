export default {
  CODE: 'code',
  SEND_OTP_V2: 'sendOTP',
  SEND_OTP_V3: 'sendOTPs',
  SEND_OTP: 'send-otp',
  SEND_EMAIL_OTP_V2: 'sendEmailOTP',
  CHECK_EMAIL_OTP_V2: 'checkEmailOTP',
  CHECK_GMAIL_USER_V2: 'checkGmailUser',
  CHECK_OTP_V2: 'checkOTP',
  SINGLE_OTP: 'singleOtp',
  CHECK_SINGLE_OTP: 'checkSingleOtp',
  CHECK_OTP: 'check-otp',
  GENERATE: 'generate',
  STATUS: 'status',
  LOGIN: 'login',
  USERLOGIN: 'userLogin',
  AGENTLOGIN: 'agentLogin',
  V2: 'v2',
  PRODUCTS: 'products',
  CATALOGS: 'catalogs',
  LIST: 'list/',
  GROUPCHANNEL: 'groupChannelV2',
  JOIN_CLUB: 'join-groupChannel',
  PERSONAL_CLUB: 'personal-groupChannel',
  PERSONAL_INVITES: 'request-groupChannel',
  GET_CLUB_DETAILS: 'get-groupChannel',
  MESSAGE: 'message/',
  ALL: 'all',
  SIGN_UP_V2: 'signupv2',
  SIGN_UP: 'signup',
  SIGN_UP_INTEREST: 'signup/step2',
  JOIN: 'join',
  USER: 'user/',
  CLICK: 'click',
  LIKE: 'like',
  DISLIKE: 'dislike',
  UPLOAD_IMAGE: 'uploadImage',
  UPLOAD_MEDIA: 'uploadMedia',
  GET_BY_ID: 'getById/',
  SUGGESTLISTV2: 'suggestListV2/',
  UPLOAD_CSV: 'upload-csv',
  CREATE: 'create',
  LABEL_LIST: 'label-list',
  LABEL_BALANCE: 'label-balance',
  ANNOUNCEMENT: 'announcement',
  SEND_ANNOUNCEMENT_MAIN: 'send-announcement-main',
  ANNOUNCEMENT_SCHEDULER: 'announcement/scheduler',
  SAVE: 'save',
  ATTACH_HTML: 'attach-html',
  FREE_TRIAL: 'free-trial',
  GET: 'get/',
  LIST_CAMPAIGN: 'list-campaign',
  CAMPAIGN_DETAILS: 'campaign-details',
  SEND_WA_TEST: 'send-whatsapp-testmessage',
  AVERAGE_STATS: 'average-stats/',
  AVERAGE_STATS_V2: 'avgstats',
  DELETE_LABEL: 'delete-label/',
  LABEL_CONTACT: 'labels/',
  DELETE_CONTACT: 'delete-contact/',
  SHOPIFY_STORE_INTEGRATION: 'shopify-store-integration',
  CUSTOMERS: 'customers',
  GET_SHOPIFY: 'get-shopify-store-integration/',
  REMOVE: 'remove',
  CREATE_CONTACT: 'create-contact',
  ANALYSIS: 'analysis',
  E_MAIL: '/email',
  DOWNLOAD_SINGLE_ANALYTICS: 'downloadSingleAnalytics/',
  MAIN_AUTO_TEMPLATE: 'main-autotemplates',
  UPDATE_CREDITS: 'updatecredits',
  PAYMENT: 'payment',
  UPDATE: 'update',
  SHOPIFY_TEMPLATE: 'shopify-templates',
  TEMPLATE_STATUS: 'template-status/',
  ENABLE_WEBHOOK: 'enablewebhook',
  ANALYTICS: 'analytics',
  DOWNLOAD_OVERALL_ANALYTICS: 'downloadAllAnalytics/',
  CHANNEL_ANALYSIS: 'channelSpecificAnalysis',
  SINGLE_ANALYTICS: 'downloadSingleAnalytics/',
  DOWNLOAD_USER_SPECIFIC_CSV: 'downloadUserSpecificAnalyticsReport',
  CREDIT_REPORT: 'getCreditReport',
  CREDIT_REPORT_VIA_AISENSY: 'CreditReportViaAiSensy',
  USER_SPECIFIC_ANALYTICS_REPORT: 'UserSpecificDownloadAnalytics/',
  TOGGLE_WEBHOOK: 'toggle-webhook',
  LIST_SCHEDULER_ANNOUNCEMENT: 'list/schedulerAnnoucement',
  CONFIG_SETTINGS: 'config-settings',
  RETARGETING: 'reTargeting/',
  UPGRADE: 'upgrade',
  UPDATE_AUTH_TOKEN: 'update-auth-token',
  AISENSY_SIGNUP: 'AiSensySignUp',
  GET_ALL_TEMPLATES: 'getAllTemplates',
  EXPLORE_TEMPLATES: 'exploreTemplates',
  TEMPLATES: 'templates',
  CONFIG: 'config',
  AUTO_RECHARGE_SETUP: 'setup-auto',
  AUTO_TOP_UP_PAYEMENT: 'payment',
  RETURN_POLICY_FILE: 'return-policy-file',
  CONFIG_WELCOME: 'config-welcomemsg',
  CONFIG_OFFHOURS: 'config-offhourmsg',
  TRAIN_BOT: 'train-bot',
  CHECK_WHATSAPP_STATUS: 'checkWhatsappStatus',
  PAGINATE_ANALYTICS: 'paginateAnalytics',
  CHANGE_STATE: "changeState",
  TOGGLEBOT_SETTINGS: 'toggle-botsetting',
  TAGS: 'tags',
  QualityStatus: 'wa-aisensy-project-details/',
  CREATE_SENDER_ID: 'createSenderId',
  UPDATE_SENDER_ID: 'updateSenderId/',
  GET_ALL_SENDER_ID: 'getAllSenderIds',
  GET_SENDER_ID: 'getSenderId/',
  DELETE_SENDER_ID: 'deleteSenderId/',
  RESEND_SENDER_ID: 'resendVerification/',
  DEFAULT_SENDER: 'setDefaultSender/',
  TAGS_BALANCE: 'tag-balance',
  CUSTOM_TEMPLATE: 'custom-template',
  TEMPLATE: 'template',
  WA_TEMPLATE: 'wa_template',
  MEDIA_ID: 'media-id',
  SEND_WHATSAPP: 'send/whatsapp',
  ADD: 'add',
  GET_ALL: 'get-all',
  SEND_EMAIL_HTML: 'send/email-html',
  MAIL_OVERALL_CSV: 'mailOverallAnalyticsReport',
  MAIL_USER_SPECIFIC_CSV: 'mailUserSpecificAnalyticsReport',
  LABEL_CSV: 'labelCSV',
  DELETE_TAG: 'delete-tag',
  FETCH_CONTAGS: 'tags/',
  LABELS: 'labels',
  INDIVIDUAL_LABLES: 'individualLables',
  DELETE_CONTACT_LABLE: 'delete-label',
  DELETE_LABLE_FROM_CONTACT: 'deletelable',
  CONTACT_LABELS: 'contacts-labels',
  CREATE_WHATSAPP_TEMPLATE: 'template/create',
  WA_CREATE: 'wa/create',
  MAIL_CREATE: 'mail/create',
  DOWNLOAD_CSV: 'download-csv',
  SEND_TEMPLATE: 'sendTemplate',
  LABELS_NO_COUNT: 'labels-no-count',
  GET_ALL_RESPONSES: 'savedReplies/getAll',
  GET_RESPONSE: 'getSavedReply/',
  SAVE_RESPONSE: 'saveReply',
  UPDATE_RESPONSE: 'updateSaveReply/',
  DELETE_RESPONSE: 'deleteSaveReply/',
  DELETE: 'delete',
  ACCEPT: 'accept',
  SINGLE: 'single',
  DELINK: 'delink',
  DOWNLOAD_CSV: 'download-csv',
  EXPORT_CONTACTS: 'export-contacts',
  WAONBOARD_NOTIFICATION_MAIL: "WabaOnboarding-notificationMail",
  PROFILE_IMAGE: 'profile-image',
  LINK: 'link',
  SAVE_TEMP: 'saveTemplate',
  SEND_BROADCAST: 'send/broadcast',
  CREATE_API_KEY: 'createAPIKey',
  UPDATE_API_KEY: 'updateAPIKey',
  GET_API_KEY: 'getAPIKey',
  SHOPIFY_CAMPAIGN_LIST: 'shopifyCampaignList',
  SHOPIFY_CAMPAIGN_ANALYTICS: 'shopifyCampaignAnalytics',
  WORKFLOW_SUPPORTED: 'workflow-supported',
  S3LINK: 's3link',
  DISTRIBUTE: 'distribute',
  TOGGLE_STATUS: 'toggle/status',
  TOGGLE_AGENT: 'toggle/agent',
  AUTO_REASSIGN: 'toggle/autoReassign',
  TOGGLE: '/toggle',
  DISABLE: '/disable',
  TEMPLATE_ANALYTICS: 'templateAnalytics',
  INDIVIDUAL_TEMPLATE_ANALYTICS: 'individual-templateAnalytics',
  MAIL_TEMPLATE_ANALYTICS: 'mailOverallTemplateBasedAnalytics',
  SAVE_FB_TOKEN: 'saveFacebookToken',
  MAIL_SINGLE_ANALYTICS: 'mailSingleAnalytics',
  DOWNLOAD_SINGLE_ANALYTICS: 'downloadSingleAnalytics',
  NEWPASSWORD: 'newPassword',
  CHECKEMAIL: 'checkEmail',
  CLICKS: 'clicks/',
  CHECKPHONE: 'checkPhone',
  FINDAGENT: 'findAgent',
  SAVE_LEADS: 'saveLeads',
  FB_LEADS: 'fbLeads',
  GET_ANALYTICS_SUMMARY: 'getAnalyticsSummary',
  GET_ALL_AD_ACCOUNTS: 'getAllAdAccounts/',
  RESYNC_FB_LEADS: 'reSyncFbLeads',
  CHANGE_TWO_FACTOR: 'changetwofactor',
  TWO_FACTOR_LOGIN: 'twofactorLogin',
  TOGGLE_FLOW_STATUS: 'toggleStatus',
  CAMPAIGN_COUNT: 'campaign-count/',
  CAMPAIGN_LIST: 'campaign-list/',
  COMMENT: 'comment',
  DELETE_COMMENT: 'delete-comment',
  UPDATE_COMMENT: 'update-comment',
  DELETE_COMMENT: 'delete-comment',
  MEDIA_LIST: 'mediaList/',
  EMAIL_CHATS: 'emailRecentChats',
  CHECK_DASHBOARD_EVENTS: 'checkDashboardEvents',
  GET_WOOCOMMERCE_STORE: 'getWooCommereceStoreIntegration',
  SETUP_SMS: 'setupSms',
  CONVERSATIONS: 'conversations',
  MESSAGES: 'messages',
  CONVERSATION: 'conversation/',
  SMS_TEMPLATES: 'smsTemplates',
  SEND_SMS_V2: 'sendSmsV2',
  AI_CONV: 'conversation',
  AI_IMG: 'image',
  UPLOAD_NDA: 'uploadNDA',
  HISTORY: 'history',
  SAVE_INSTAGRAM_TOKEN: 'saveInstagramToken',
  INSTAGRAM_WEBHOOK: 'instagramWebhook',
  WA_STATUS: 'waStatus',
  INSTAGRAM_CONVERSATIONS: 'instagramConversations/',
  MESSENGER_CONVERSATIONS: 'messengerConversations/',
  RECORDS: 'records',
  CREATE_SINGLE_CONTACT: 'createSingleContact',
  SETUP: 'setup',
  CHECK: 'check',
  DISCONNECT: 'disconnect',
  ASSIGN_INSTA_CONV: 'assignInstaConversation',
  PIPELINES: 'pipelines',
  CUSTOM_DATA: 'custom-data',
  INVIDUAL_CUSTOM_DATA: 'customAttributes',
  UPDATE_CUSTOM_DATA: 'updatecustom-data',
  ATTRIBUTES: 'attributes',
  LABELBALANCE: 'labelBalance',
  ADD_CONTACT: 'addContact',
  FILES: 'files/',
  SUBSCRIBE: 'subscribe',
  ORDER: 'order',
  CANCEL: 'cancelSubscription',
  CREATEINVOICE: 'createInvoice',
  INVOICE: 'invoice/',
  PAYMENTBYUSERID: 'paymentByUserId',
  PAYMENTCAPTURE: 'paymentCapture',
  ALL_AGENTS_ANALYTICS: 'allAgentAnalytics',
  AGENT_ANALYTICS: 'agentAnalytics/',
  GRAPH_ALL_AGENTS: 'graph/allAgents',
  GRAPH_AGENT: 'graph/agent/',
  TOKEN: 'token/',
  RESET_PASSWORD: 'resetPassword',
  DOWNLOAD: 'download',
  MODIFY_CREDITS: 'modifyCredits',
  CREATE_ORDER: 'createOrder',
  UPDATE_WELCOME: 'updateWelcome',
  SWITCH_TO_PARTNER: '/switchToPartner',
  STOP_CAMPAIGN: 'stopCampaign/',
  STOP_ALL_CAMPAIGN: 'stopAllCampaigns',
  GET_WEBHOOK_RELAY: 'getWebhookRelayURL',
  UPDATE_WEBHOOK_RELAY: 'updateWebhookRelayURL',
  DELETE_WEBHOOK_RELAY: 'deleteWebhookRelayURL',
  CREATE_ASSISTANT: 'createAssistant',
  ALL_ASSISTANTS: 'allAssistants',
  SYNC_ASSISTANTS: 'syncAssistants',
  EDIT_ASSISTANT: 'editAssistant',
  DELETE_ASSISTANT: 'deleteAssistant',
  GET_ASSISTANT: 'getAssistant',
  BREVO_TRACK_PAGES: 'trackPage',
  BREVO_TRACK_EVENTS: 'trackEvent',
  BREVO_TRACK_LINKS: 'trackLink',
  BRAVO_SAVE_CONTACT: 'saveContacts',
  SHOPIFYAUTH: 'shopiyOAuth',
  REDIRECTION: 'callback',
  UPDATESHOPIFY: 'updateShopifyConfig',
  UNISTALLSHOPIFY: 'uninstallShopifyApp',
  DELINKINSTAGRAM: 'delinkInstagramToken',
  MAIL_RECORDS: 'mail-records',
  CATALOG: 'catalog',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  CATALOG_URL: 'catalogUrl',
  CHANGE_CATALOG_STATUS: 'changeCatalogStatus',
  COMMERCE_DETAILS: 'commerceDetails',
  PAYMENTS: 'payments',
  SETUP: 'setup',
  WHATSAPP_PAYMENTS: 'whatsAppPayments',
  ECOMCOD: 'ecomFlow/codToPrepaid',
  ECOMNODE: 'ecomFlow/nodeData',
  MAIL_RECORDS: 'mail-records',
  UNREAD_NOTIFICATIONS: '/unread-notifications',
  MARK_READ_NOTIFICATIONS: '/markallasread',
  MAIL_RECORDS: 'mail-records',
  FETCHCSV: 'fetchCsv',
  SYNC_CATALOG: 'syncCatalog',
  PAGE_DETAILS: 'pageDetails',
  INSTAGRAM: 'instagram',
  ADDCUSTOMATTRIBUTE: 'addAttributeCustom',
  UPLOAD_CONTACT: 'mapCsv',
  SUBMIT_SES: 'submitSES',
  GET_SES_CONFIGURATION: 'getSESConfiguration',
  CREATE_FORM: 'createForm',
  EDIT_FORM: 'editForm/',
  DUPLICATE: 'duplicate/',
  CREATE_FORM_RESPONSE: 'createFormResponse/',
  PIXEL: 'pixel',
  TOGGLE_FORM: 'toggleForm/',
  SET_DEFAULT_CURRENCY: 'setDefaultCurrency',
  EXPORT_CAMPAIGN: 'export-campaigns',
  CREATE_SUBSCRIPTION: 'create/subscription',
  DIRECT_PAYMENT_SIGNUP: '/signup/razporay/',
  CONTACTS: 'contacts/',
  FETCH_XLSV: 'fetch-xlsv',
  MAP_XLSV: 'map-xlsv',
  CSV: 'csv',
  UPLOAD: 'upload',
  PAGE: 'page',
  PIXEL_PAGES: 'pixelPages',
  LEAD_PAGES: 'leadPages',
  LEAD_PAGE: 'leadPage',
  STORIES: 'stories',
  MEDIA: 'media',
  PRODUCTCATALOGS: 'productCatalogs',
  CATALOGPRODUCTS: 'catalogProducts',
  METRICS: 'metrics',
  CHART: 'chart',
  SMTP_SETTING: 'smtp-setting', 
  VERIFY_EMAIL: '/verify-email',
  GET_CUSTOM_ATTRIBUTES: 'getCustomAttributes',
  CREATE_CUSTOM_ATTRIBUTES: "createCustomAttr",
  DELETE_CUSTOM_ATTRIBUTES: "deleteAttribute",
  UPDATE_CUSTOM_ATTRIBUTES: "updateAttribute",
  UPDATE_ATTRIBUTE_POSITION: "updateAttributePosition",
  FILTER_CONTACTS: 'filterContacts',
  FILTER_LABEL_CONTACTS: "filterLabelContacts",
  CUSTOM_ATTR_BY_NAME: 'customAttrByName',
  WORKFLOW_FILE_IMPORT: '/import',
  WORKFLOW_FILE_EXPORT: '/export',
  WORKFLOW_FILE_SYNC: '/sync',
  KEYWORD_EXPORT: 'export',
  KEYWORD_IMPORT:'import',
  TEMPLATE_EXPORT: 'export-template',
  TEMPLATE_IMPORT: 'import-template'

};
