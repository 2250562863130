import React, { useEffect, useState } from "react";
import colors from "../../Utils/colors";
import ExploreTemplate from "./components/ExploreTemplate";
import LoginSignUp from "./components/LoginSignUp";
import OtpVerifyPage from "./components/OtpVerifyPage";
import FreeTrialModal from "../../Components/Modal/FreeTrialModal";
import FreeTrialPlans from "./components/FreeTrialPlans";
// import { isMobile } from "react-device-detect";
import images from "../../Utils/images";
import { eventsend } from "../../Config/analyticsFunctions";
const mobileWidth = window.innerWidth < 700 ? true:false;
//console.log("mobile",mobileHeight);

export default function Auth() {
  const [isFTMOpen, setIsFTMOpen] = useState(false);
  useEffect(()=>{
    eventsend("Dashboard_page_views");
  },[])

  // const onMobileClickAction = () => {
  //   window.open('https://cheerio.app.link/NgZ2AeZRiub', '_blank');
  // };

  return (
    <div className="auth">
      <div className="auth__explore">
        <ExploreTemplate />
      </div>
      <div className="auth__login">
        <LoginSignUp />
      </div>
      {isFTMOpen && (
        <FreeTrialPlans
          setIsOpen={setIsFTMOpen}
          centerstyle={{ transform: 'translate(-50%,-50%)' }}
        />
      )}
    </div>
    // <div style={styles.container}>
    //   <div style={styles.box1}>
    //     <ExploreTemplate />
    //   </div>
    //   <div style={styles.box2}>
    //     <LoginSignUp />
    //   </div>
    //   <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}></div>
    //   {isFTMOpen && (
    //     <FreeTrialPlans
    //       setIsOpen={setIsFTMOpen}
    //       centerstyle={{ transform: 'translate(-50%,-50%)' }}
    //     />
    //   )}
    // </div>
  );
}

const Styles = {
  buttonStyle: {
    height: 40,
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: colors.white,
    borderRadius: 8,
    backgroundColor: colors.primary03,
    // display: "inline-block",
  },
  Column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  MobileContainer: {
    // backgroundColor:colors.black02,
    backgroundColor: colors.black,
    height: "100ch",
    width: '100%',
    paddingBlock: '5vh',
    paddingInline: '10vw',
  },
  MobileTxt: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.white,
    paddingInline: 16,
  },
  Container: {
    backgroundColor: colors.black02,
    height: '100vh',
    width: '100%',
    paddingBlock: '5vh',
    paddingInline: '10vw',
  },
};

const styles = {
  container: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.themeBlue,
    height: '100dvh',
    width: '100%',
  },
  box1: {
    display: 'flex',
    backgroundColor: colors.black,
    height: '100%',
    width: '50%',
  },
  box2: {
    display: 'flex',
    backgroundColor: colors.white,
    height: '100%',
    width: '50%',
  },
};
