import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../CheerioButton';
import { Form } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import { getCreditReportAPI, getCreditReportViaAiSensy } from '../../Services';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ReplayIcon from '@mui/icons-material/Replay';
const DownloadCredit = ({ setIsDownloadCreditOpen }) => {
  const [dateChoosen, setDateChoosen] = useState('');
  const [isClicked, setIsClicked] = useState('false');
  const [downloadRes, setDownloadRes] = useState('false');
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [filters, setFilters] = useState({
    day: {
      startDate: dayjs().subtract(30, 'day'),
      endDate: dayjs(),
    },
  });


  const clearFilters = () => {
    setFilters({
      day: {
        startDate: null,
        endDate: null,
      },
    });
  };

  const getCreditReport = async (val) => {
    const { startDate, endDate } = filters.day;

    if (!startDate || !endDate) {
      alert('Please select both start and end dates.');
      return;
    }

    let data = {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
    };
    let token = authtoken;

    try {
      setDownloadRes('pending');
      setIsClicked('true');

      let response = await getCreditReportViaAiSensy(data, token);

      if (!response || !(response instanceof Blob)) {
        console.error('Invalid response received from API.');
        setDownloadRes('error');
        setIsClicked('false');
        return;
      }

      const url = window.URL.createObjectURL(response);

      if (!url) {
        throw new Error('Failed to create object URL for the CSV file.');
      }

      const link = document.createElement('a');
      if (!link) {
        throw new Error('Failed to create download link.');
      }
      link.href = url;
      link.setAttribute('download', 'Credit_usage.csv');

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);

      setDownloadRes('success');

    } catch (error) {
      console.error('Error downloading CSV file:', error.message || error);
      setDownloadRes('error');
      setIsClicked('false');
    } finally {
      setTimeout(() => {
        setDownloadRes('false');
        setIsClicked('false');
      }, 4000);
    }
  };
  return (
    <>
      <div className="DarkBg" onClick={() => { }}>
        <div className="Center">
          <div
            style={{
              width: 500,
              height: '32vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              padding: 16,
              overflowY: 'scroll',
            }}
          >
            <div
              className="d-flex flex-column justify-content-between align-items-center w-100 h-100"
            >
              <div className="d-flex flex-row justify-content-between align-items-center w-100" style={{ marginTop: '-6px' }}>
                <h2
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Download Credits Report'}
                </h2>
                <img
                  src={images.CMClose}
                  style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer', paddingRight: '4px', marginRight: '-16px' }}
                  onClick={() => setIsDownloadCreditOpen(false)}
                />
              </div>
              <hr style={{ width: '100%', marginTop: '-14px' }} />
              <p style={{ fontSize: 16, fontWeight: 400, color: '#666666', width: '100%', marginTop: '-22px' }}>
                Specify the date range for which you would like to download your reports.
              </p>
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  marginBottom: '12px',
                  marginTop: '0px',
                  width: '100%'
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ flex: 1, marginRight: 10, cursor: 'pointer' }}>
                    <DatePicker
                      value={filters.day.startDate}
                      onChange={(newValue) =>
                        setFilters((prev) => ({
                          ...prev,
                          day: { ...prev.day, startDate: newValue },
                        }))
                      }
                      disableFuture
                      label="Start Date"
                      renderInput={(params) => (
                        <Form.Control {...params} style={{ borderRadius: 8 }} />
                      )}
                    />
                  </div>

                  <div style={{ marginLeft: '1px', marginRight: '10px' }}>-</div>

                  <div style={{ flex: 1, position: 'relative', cursor: 'pointer' }}>
                    <DatePicker
                      value={filters.day.endDate}
                      minDate={filters.day.startDate || null}
                      onChange={(newValue) =>
                        setFilters((prev) => ({
                          ...prev,
                          day: { ...prev.day, endDate: newValue },
                        }))
                      }
                      disableFuture
                      label="End Date"
                      renderInput={(params) => (
                        <Form.Control {...params} style={{ borderRadius: 8 }} />
                      )}
                    />
                  </div>
                </LocalizationProvider>

                <Tooltip title="Reset Filter">
                  <ReplayIcon
                    style={{
                      fontSize: 28,
                      color: colors.black,
                      cursor: 'pointer',
                      marginLeft: 10,
                      marginTop: '-12px',
                      marginRight: '-16px'
                    }}
                    onClick={clearFilters}
                  />
                </Tooltip>
              </div>


              <CheerioButton
                borderStyle={{
                  borderWidth: 0,
                  borderRadius: 12,
                  paddingInline: 32,
                  width: '100%',
                }}
                backColor={'#8E53F7'}
                textStyle={{ fontSize: 14, color: colors.white01 }}
                btnText={'Download Report'}
                onclick={() => {
                  if (filters.day.startDate && filters.day.endDate) {
                    setIsClicked(true);
                    getCreditReport();
                  } else {
                    toast.error('Please select both start and end dates.', { position: 'top-center' });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadCredit;
const Styles = {
  Conatiner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    paddingInline: 20,
    paddingBlock: 6,
    border: '1px solid #000',
  },
  ButtonText: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black,
  },
};
