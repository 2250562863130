import React, { useEffect, useState } from 'react'
import CheerioButton from '../../../Components/CheerioButton'
import images from '../../../Utils/images'
import colors from '../../../Utils/colors'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getSequencesList } from '../../../Services'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getLocaltime } from '../../../Utils/localdate'

const Home = () => {

    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token); 
    const [tableData, setTableData] = useState([])

    useEffect(()=>{
        getSequencesListAPIcall();
    },[])

    const getSequencesListAPIcall = () => {
        let token = authToken;
        getSequencesList(token).then((res)=>{
            if(res?.flag){
                setTableData(res?.data.reverse())
                console.log("3333333333 success --> ",res)
            }else{
                console.log("3333333333 failure --> ",res)
            }
        })
    } 

    const columns = [
        {
            id: 'campaignName',
            label: 'Campaign Name',
            minWidth: 225,
            width: '40%',
            align: 'start',
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 125,
            width: '20%',
            align: 'center',
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 125, 
            width: '25%', 
            align: 'center',
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 125, 
            width: '15%', 
            align: 'center',
        }
    ]

  return (
    <>
    <div className='Column justify-content-start align-items-center w-100 h-100' style={{
        overflowY: 'auto',
        paddingBlock: 24,
        paddingInline: 30,
    }}>
        <div className='Row justify-content-between w-100' style={{marginBlockEnd:16}}>
            <h3 style={{fontSize:24,fontWeight:700}}>
                {'Sequences'}
            </h3>
            <CheerioButton 
                btnText={'New Sequence'}
                icon={images.AddIcon} 
                textStyle={{color:colors.white01}}
                backColor={colors.primary03}
                borderStyle={{borderColor:colors.primary03}} 
                onclick={()=>{navigate('/creator/sequences/create')}}
            />
        </div>
        <p style={{color:colors.greys04,width:'100%',marginBlock:16}}>
            {'Sequences allows you to design and execute campaigns over a specified period, setting intervals that suit your strategy. Engage your audience consistently and effectively by scheduling your messages to go out at just the right times.'}
        </p>

        {tableData && tableData?.length > 0 ? (
            <>
            <div className='Column justify-content-start w-100 my-2' style={{flex:1}}>
                <TableContainer sx={{flex:1}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell 
                                        key={column.id} 
                                        align={column.align} 
                                        style={{
                                            minWidth: column.minWidth,
                                            width: column.width, 
                                            fontSize: 16, 
                                            fontWeight: 500, 
                                            color: colors.black, 
                                            backgroundColor: colors.white04,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.filter((row)=> row.status !== 'Draft').map((row)=>{
                                return(
                                    <TableRow>
                                        {columns.map((column) => {
                                            const value = row[column.id] 
                                            return(
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === 'createdAt' ? (
                                                        <p style={{fontSize:14, color:colors.black}}>
                                                            {getLocaltime(row.createdAt)}
                                                        </p>
                                                    ): column.id === 'action' ? (
                                                        <img 
                                                            src={images.BarChart} 
                                                            height={18} 
                                                            style={{
                                                                cursor:'pointer',
                                                            }} 
                                                            onClick={()=>{
                                                                navigate(`/creator/sequences/analytics/${row?._id}`,{state: row})
                                                            }}
                                                        />
                                                    ):(
                                                        <p style={{fontSize:14, color:colors.black}}>
                                                            {value}
                                                        </p>
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination 
                    rowsPerPageOptions={[15, 25, 100]} 
                    component='div' 
                    count={totalCount} 
                    page={}
                /> */}
            </div>
            </>
        ):(
            <>
            <div className='Column justify-content-center align-items-center w-100' style={{flex:1}}>
                <img
                    src={images.SequencePlaceholder} 
                    style={{height:210,width:280,objectFit:'contain'}}
                />
                <p style={{color:colors.greys04,marginBlock:16}}>
                    {'All your sequences will be shown here'}
                </p>
            </div>
            </>
        )}
        
    </div>
    </>
  )
}

export default Home