import React, { useState, useEffect, useRef, useCallback } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import './attribute.css'
import AddAttributeSideBar from './AddAttributeSideBar';
import { Drawer } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FiEdit2 } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import 'primeicons/primeicons.css';
import { Tooltip } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PushPinIcon from '@mui/icons-material/PushPin'; // Material-UI icon
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SvgIcon } from '@mui/material';
import SpinnerLoader from '../../../Components/SpinnerLoader'
import { styled } from '@mui/system';

import { getCustomAttr, deleteCustomAttr, updateCustomAttr, updateAttrPosition } from '../../../Services';

const StyledIcon = styled(SvgIcon)(({ theme }) => ({
    fontSize: 'large',
    color: colors.black,
    opacity: 0.8,
}));

export const attributeTypeOptions = [
    { value: 'text', label: 'Text Field', icon: <StyledIcon component={TextFieldsIcon} /> },
    { value: 'paragraph', label: 'Paragraph', icon: <StyledIcon component={FormatAlignLeftIcon} /> },
    { value: 'number', label: 'Number', icon: <StyledIcon component={NumbersIcon} /> },
    { value: 'dropdown', label: 'Dropdown', icon: <StyledIcon component={ArrowDropDownIcon} /> },
    { value: 'date', label: 'Date', icon: <StyledIcon component={CalendarTodayIcon} /> },
    { value: 'time', label: 'Time', icon: <StyledIcon component={AccessTimeIcon} /> },
    { value: 'timestamp', label: 'Timestamp', icon: <StyledIcon component={CalendarTodayIcon} /> },
    { value: 'link', label: 'Link', icon: <StyledIcon component={LinkIcon} /> },
    { value: 'phone', label: 'Phone', icon: <StyledIcon component={PhoneIcon} /> },
    { value: 'website', label: 'Website', icon: <StyledIcon component={PublicIcon} /> },
    { value: 'email', label: 'Email', icon: <StyledIcon component={EmailIcon} /> },
    { value: 'currency', label: 'Currency', icon: <StyledIcon component={CurrencyRupeeIcon} /> },
];

const defaultIds = [
    "660e878b6d6015c4e49b0434"
]


const Attributes = () => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [showSideBar, setShowSideBar] = useState(false);
    const defaultAttributes = [
        { id: 1, type: 'Text Field', attribute: 'name', isDefault: true, _id: '1' },
        { id: 2, type: 'Email', attribute: 'email', isDefault: true, _id: '2' },
        { id: 3, type: 'Phone', attribute: 'phone', isDefault: true, _id: '3' },
        { id: 4, type: "timestamp", attribute: "createdAt", isDefault: true, _id: '4' },
        { id: 5, type: "timestamp", attribute: "updatedAt", isDefault: true, _id: '5' }
    ];
    const [allAttributes, setAllAttributes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [canvasA, setCanvasA] = useState(defaultAttributes);
    const [canvasB, setCanvasB] = useState([]);

    const [canvasC, setCanvasC] = useState([]);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [revertDeleteAttributesModel, SetrevertDeleteAttributeOpen] = useState(false);
    const [EditAttribueshowSideBar, setEditAttribueShowSideBar] = useState(false);
    const [editingAttribute, setEditingAttribute] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [hiddneattributedata, setHiddneAttributeData] = useState(null);


    const fetchAttr = async (updatePos = false) => {
        !updatePos && setIsLoading(true);
        const response = await getCustomAttr(authtoken, "management");
        if (response.flag) {
            const data = response.data;
            const filteredData = data?.filter(
                (attr) => !['name', 'email', 'phone', 'createdAt', 'updatedAt'].includes(attr.attribute)
            );
            setAllAttributes(filteredData);
            const newCanvasA = [...defaultAttributes];
            const newCanvasB = [];
            const newCanvasC = [];
            filteredData?.forEach((attribute) => {
                const { createdAt, updatedAt, ...cleanedData } = attribute;

                if (attribute.isDefault && !attribute.hidden) {
                    const isAlreadyPresent = newCanvasA?.some(attr => attr?.attribute === cleanedData?.attribute);
                    if (!isAlreadyPresent) {
                        newCanvasA.push(cleanedData);
                    }
                } else if (attribute?.hidden) {
                    newCanvasC.push(cleanedData);
                } else {
                    newCanvasB.push(cleanedData);
                }
            });

            setCanvasA(newCanvasA);
            setCanvasB(newCanvasB);
            setCanvasC(newCanvasC);
        }
        !updatePos && setIsLoading(false);
    };
    useEffect(() => {
        fetchAttr();
    }, [authtoken]);


    const handleToggleHidden = async (item) => {
        const firstHiddenIndex = allAttributes.findIndex(attr => attr.hidden);
        const updatedData = {
            ...item,
            hidden: !item.hidden,
            // position: item.hidden ? firstHiddenIndex : allAttributes.length
        };

        const payload = {
            attribute: updatedData.attribute,
            type: updatedData.type || updatedData.format,
            isDefault: updatedData.isDefault,
            defaultValue: updatedData.defaultValue,
            options: updatedData.options,
            hidden: updatedData.hidden,
            // position: updatedData.position
        };

        await updateCustomAttr(authtoken, item._id, payload)
            .then((response) => {
                if (response?.flag) {
                    fetchAttr();
                    console.log('Hidden attribute updated successfully', response.data);
                } else {
                    console.error('Failed to update hidden attribute', response.message);
                }
            })
            .catch((error) => {
                console.error('Error updating hidden attribute', error);
            });
    };

    const handleDeleteAttr = async () => {
        try {
            const response = await deleteCustomAttr(authtoken, selectedId);
            if (response.flag) {
                toast.success(response.message);
                fetchAttr();
            } else {
                toast.error(response.message);
            }
        } catch (err) {
            toast.error("Something went wrong");
        }
    };

    const handleSaveAttribute = (updatedValue) => {
        const updatedCanvasA = canvasA.map((item) =>
            item.type === editingAttribute.type ? { ...item, value: updatedValue } : item
        );
        setCanvasA(updatedCanvasA);
        setEditAttribueShowSideBar(false);
    };

    const handleSidebarOpen = () => {
        setShowSideBar(true);
    };
    const handleDeleteConfirmed = () => {
        handleDeleteAttr();
    };
    const handleDeleteClick = (id) => {
        setSelectedId(id);
    };

    const handleMoveItem = async (draggedItem, hoverIndex, sourceCanvasId, targetCanvasId) => {
        console.log('Moving item:', draggedItem, hoverIndex, sourceCanvasId, targetCanvasId);
        const oldPosition = allAttributes?.findIndex((attr) => attr._id === draggedItem.index);
        let newPosition = allAttributes?.findIndex((attr) => attr._id === hoverIndex);
        console.log('Old position:', oldPosition, 'New position:', newPosition);
        let newHoverIndex = hoverIndex;
        let orgininal = draggedItem.originalItem;
        if (targetCanvasId === 'Default Attributes' && newPosition < 0) {
            newHoverIndex = canvasA.length;
            newPosition = newHoverIndex - 5
        } else if (targetCanvasId === 'Secondary Attributes' && newPosition < 0) {
            newHoverIndex = allAttributes.length;
            newPosition = newHoverIndex
        }

        const sourceItems = getCanvasById(sourceCanvasId);
        const targetItems = getCanvasById(targetCanvasId);
        const itemToMove = orgininal;

        const isSameCanvas = sourceCanvasId === targetCanvasId;

        if (isSameCanvas && targetItems[newHoverIndex]?._id === itemToMove?._id) return;
        if (sourceCanvasId === "Hidden Attributes") return;

        const isDefault = targetCanvasId === 'Default Attributes';
        itemToMove.isDefault = isDefault;


        try {
            const res = await updateAttributePositionInBackend(itemToMove._id, oldPosition, newPosition, isDefault);
        } catch (err) {
            console.log('Error updating attribute position:', err);
        }

    };


    const updateAttributePositionInBackend = async (attributeId, oldPosition, newPosition, isDefault) => {
        try {
            const payload = {
                attributeId: attributeId,
                isDefault: isDefault,
                oldPosition: oldPosition,
                newPosition: newPosition
            };
            const res = await updateAttrPosition(authtoken, payload);
            if (res.flag) {
                console.log('Attribute position updated successfully:', res.data);
                fetchAttr(true)
            } else {
                console.error('Failed to update attribute position:', res.message);
            }
        } catch (error) {
            console.error('Error updating attribute position:', error);
        }
    };


    const getCanvasById = (canvasId) => {
        switch (canvasId) {
            case "Default Attributes": return canvasA;
            case "Secondary Attributes": return canvasB;
            case "Hidden Attributes": return canvasC;
            default: return [];
        }
    };

    const updateCanvasState = (canvasId, items) => {
        switch (canvasId) {
            case "Default Attributes": setCanvasA(items); break;
            case "Secondary Attributes": setCanvasB(items); break;
            case "Hidden Attributes": setCanvasC(items); break;
            default: break;
        }
    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: '1px solid #CCCCCC', width: '100%', paddingRight: '2rem' }}>
                <p style={{ fontSize: '22px', fontWeight: '700', marginTop: '10px', marginLeft: '25px', marginBottom: 16 }}>{'Contact Attributes'}</p>
                <CheerioButton
                    borderStyle={{ border: 'none' }}
                    textStyle={{ color: colors.white }}
                    btnText={'Create attribute'}
                    backColor={colors.darkPurple}
                    onclick={() => {
                        const isDefaultId = defaultIds.includes(localdata?._id);
                        if (!isDefaultId && allAttributes.length > 45) {
                            toast.error("You can't create more than 50 attributes")
                            return
                        }
                        setShowSideBar(true);
                        setEditingAttribute(null)
                    }}
                    icon={images.IPlusWhite}
                />
            </div >
            {isLoading ? (
                <div className="d-flex flex-column justify-content-center align-items-center w-100" style={{ flex: 1 }}>
                    <SpinnerLoader />
                </div>
            ) : (
                <DndProvider backend={HTML5Backend}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            width: '100%',
                            top: "600px",
                            backgroundColor: `rgb(245, 245, 245)`,
                            height: '95vh',
                            overflowY: 'auto',
                            paddingBottom: '1rem',
                            paddingTop: "1rem"
                        }}
                    >
                        <DroppableList items={canvasA} setItems={setCanvasA} canvasId="Default Attributes" onMoveItem={handleMoveItem} setDeleteModalOpen={setDeleteModalOpen} setEditAttribueShowSideBar={setEditAttribueShowSideBar} setEditingAttribute={setEditingAttribute} showActions={true} onDeleteClick={handleDeleteClick} setHiddneAttributeData={setHiddneAttributeData} handleToggleHidden={handleToggleHidden} />
                        <DroppableList items={canvasB} setItems={setCanvasB} canvasId="Secondary Attributes" onMoveItem={handleMoveItem} setDeleteModalOpen={setDeleteModalOpen} setEditAttribueShowSideBar={setEditAttribueShowSideBar} setEditingAttribute={setEditingAttribute} showActions={true} onDeleteClick={handleDeleteClick} setHiddneAttributeData={setHiddneAttributeData} handleToggleHidden={handleToggleHidden} />
                        <DroppableList items={canvasC} setItems={setCanvasC} canvasId="Hidden Attributes" onMoveItem={handleMoveItem} showActions={false} onDeleteClick={handleDeleteClick} setHiddneAttributeData={setHiddneAttributeData} handleToggleHidden={handleToggleHidden} />

                    </div>
                </DndProvider>
            )}
            {deleteModalOpen && <DeleteAttributeModal setDeleteModalOpen={setDeleteModalOpen} SetrevertDeleteAttributeOpen={SetrevertDeleteAttributeOpen} />}
            {
                revertDeleteAttributesModel && (
                    <RevertdeleteAttributeModal
                        setRevertDeleteAttributeOpen={SetrevertDeleteAttributeOpen}
                        onDeleteConfirmed={handleDeleteConfirmed}
                        selectedId={selectedId}
                    />
                )
            }
            <Drawer
                anchor="right"
                open={showSideBar || EditAttribueshowSideBar}
                onClose={() => setShowSideBar(false)}
                PaperProps={{
                    style: {
                        width: '450px',
                        height: '100vh',
                        gap: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                    },
                }}
            >
                <AddAttributeSideBar
                    setShowSideBar={setShowSideBar}
                    setEditAttribueShowSideBar={setEditAttribueShowSideBar}
                    fetchAttr={fetchAttr}
                    editingAttribute={editingAttribute}
                />
            </Drawer>
        </>
    );
};

export default Attributes;

const ItemType = 'ITEM';

const DraggableItem = ({
    item, index, canvasId, moveItem, showActions, onView, setDeleteModalOpen, onEdit, onDeleteClick, setHiddneAttributeData, handleToggleHidden
}) => {
    const ref = React.useRef(null);
    // const [isDragging, setIsDragging] = useState(false);
    const DefaultAttribute = ["name", "email", "phone", "createdAt", "updatedAt"].includes(item.attribute);
    const HiddenAttributes = canvasId === "Hidden Attributes";

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: {
            index,
            canvasId,
            originalItem: item,
            isDefault: DefaultAttribute
        },
        canDrag: !DefaultAttribute || !HiddenAttributes,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem, monitor) => {
            if (draggedItem.isDefault) return;

            const dragIndex = draggedItem.index;
            const hoverIndex = index;
            const sourceCanvasId = draggedItem.canvasId;
            const targetCanvasId = canvasId;

            if (dragIndex === hoverIndex && sourceCanvasId === targetCanvasId) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        },

        drop: (draggedItem, monitor) => {
            if (!monitor.didDrop()) {
                if (draggedItem.isDefault) {
                    return;
                }

                const dragIndex = draggedItem.index;
                const hoverIndex = index;
                const sourceCanvasId = draggedItem.canvasId;
                const targetCanvasId = canvasId;
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                if (!hoverBoundingRect) {
                    return;
                }

                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                if (!clientOffset) {
                    console.log("Client offset not found.");
                    return;
                }

                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                // Refined conditions to improve usability
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 10) {
                    console.log("Drag skipped: Moving down but not close enough to target.");
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 10) {
                    console.log("Drag skipped: Moving up but not close enough to target.");
                    return;
                }

                console.log("Dropped item:", draggedItem, "Target Canvas ID:", canvasId, "Hover Index:", hoverIndex);
                moveItem(draggedItem, hoverIndex, sourceCanvasId, targetCanvasId);
            }
        }
    });

    drag(drop(ref));

    const getIconForType = (type) => {
        const match = attributeTypeOptions.find(option =>
            type.toLowerCase().includes(option.value.toLowerCase())
        );
        return match ? match.icon : null;
    };

    const getLabelFromType = (type) => {
        const matchingOption = attributeTypeOptions.find(option => option.value === type);
        return matchingOption ? matchingOption.label : type;
    };

    const isDefaultAttribute = ["Name", "Email", "Phone", "Created on", "Updated on"].includes(item.attribute);

    return (
        <div
            ref={ref}
            className={`draggable-item ${isDragging ? 'dragging' : 'not-dragging'} ${DefaultAttribute || HiddenAttributes ? 'default hidden' : ''}`}
        >
            {/* Drag Handle */}
            <button
                className="drag-handle"
            >
                {isDefaultAttribute ? (
                    <PushPinIcon fontSize="small" sx={{ color: "#B0B0B0", opacity: 0.3 }} />
                ) : (
                    <div style={{ cursor: "move" }}>
                        <i className="pi pi-ellipsis-v" style={{ fontSize: '15px', marginRight: '-8px' }}></i>
                        <i className="pi pi-ellipsis-v" style={{ fontSize: '15px' }}></i>
                    </div>
                )}
            </button>
            <div>
                <hr
                    style={{
                        width: '25px',
                        height: '0px',
                        transform: 'rotate(90deg)',
                        flex: 'none',
                        order: 1,
                        alignSelf: 'stretch',
                        background: "red",
                        flexGrow: 0,
                        // color: "#000000",
                    }}
                />
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: "center" }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: "15rem", color: "#000000" }}>
                    {getIconForType(item.type)}
                    <span style={{ color: "#000000" }}>{getLabelFromType(item.type)}</span>
                </div>
                <div>
                    <hr
                        style={{
                            width: '25px',
                            height: '0px',
                            border: '1px solid #66666',
                            transform: 'rotate(90deg)',
                            flex: 'none',
                            order: 1,
                            flexGrow: 0,
                        }}
                    />
                </div>
                <div style={{ flex: 1, marginLeft: '0.5rem' }}>
                    <span style={{ color: '#000000' }}>{item.attribute}</span>
                </div>
            </div>
            {/* Action Buttons */}
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {/* Edit Icon */}
                {showActions && !DefaultAttribute && (
                    <Tooltip title="Edit Attribute" arrow>
                        <div
                            style={{ cursor: 'pointer', color: colors.darkPurple }}
                            onClick={() => onEdit(item)}
                        >
                            <FiEdit2 size={20} />
                        </div>
                    </Tooltip>
                )}
                {DefaultAttribute && (
                    <div style={{ cursor: 'not-allowed', color: colors.grey2 }}>
                        <FiEdit2 size={20} />
                    </div>
                )}
                {/* View/Hide Icon with Tooltip */}
                {!DefaultAttribute && (
                    <Tooltip title={showActions ? "Hide Attribute" : "Show Attribute"} arrow>
                        <div
                            style={{ cursor: 'pointer', color: colors.darkPurple }}
                            onClick={() => handleToggleHidden(item)}
                        >
                            {showActions ? (
                                <AiOutlineEye size={20} />
                            ) : (
                                <VisibilityOffIcon fontSize="small" />
                            )}
                        </div>
                    </Tooltip>
                )}
                {showActions && !DefaultAttribute && (
                    <Tooltip title="Delete Attribute" arrow>
                        <div
                            style={{ cursor: 'pointer', color: colors.darkPurple }}
                            onClick={() => {
                                onDeleteClick(item?._id);
                                setDeleteModalOpen(true);
                            }}
                        >
                            <FiTrash2 size={20} />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};


const DroppableList = ({ items, setItems, canvasId, onMoveItem, setDeleteModalOpen, setEditAttribueShowSideBar, setEditingAttribute, showActions, onDeleteClick, setHiddneAttributeData, handleToggleHidden }) => {
    const [, drop] = useDrop({
        accept: ItemType,
        drop: (draggedItem, monitor) => {
            console.log('Here q:', monitor.didDrop(), draggedItem.isDefault, !monitor.didDrop() && !draggedItem.isDefault);
            if (canvasId !== "Hidden Attributes") {
                const sourceCanvasId = draggedItem.canvasId;
                if (sourceCanvasId !== canvasId) {
                    console.log('Dropped item:', draggedItem, canvasId);
                    onMoveItem(draggedItem, items.length, sourceCanvasId, canvasId);
                }
            }
        },
    });


    const moveItem = (draggedItem, toIndex, sourceCanvasId, targetCanvasId) => {
        onMoveItem(draggedItem, toIndex, sourceCanvasId, targetCanvasId);
    };

    const handleView = (index) => {
        alert(`View clicked for ${items[index]}`);
    };
    const handleEdit = (item) => {
        setEditAttribueShowSideBar(true);
        setEditingAttribute(item);
    };



    return (
        <div>
            <div
                ref={drop}
                style={{
                    padding: '1rem 2rem',
                    // backgroundColor: `rgba(0,0,0,0.02)`,
                    margintop: "600px",
                }}
            >
                <div
                    style={{
                        marginBottom: "1rem",
                    }}
                >
                    <p style={{
                        color: "#000000",
                        fontSize: "1.2rem",
                        fontWeight: 600,
                    }}>{canvasId}</p>
                </div>
                {items.map((item, index) => (
                    <DraggableItem
                        key={item?._id}
                        index={item?._id}
                        item={item}
                        canvasId={canvasId}
                        moveItem={moveItem}
                        setEditAttribueShowSideBar={setEditAttribueShowSideBar}
                        setHiddneAttributeData={setHiddneAttributeData}
                        handleToggleHidden={handleToggleHidden}
                        onEdit={handleEdit}
                        setDeleteModalOpen={setDeleteModalOpen}
                        showActions={showActions}
                        onDeleteClick={onDeleteClick}
                    />
                ))}
            </div>
        </div>
    );
};

const DeleteAttributeModal = ({ setDeleteModalOpen, SetrevertDeleteAttributeOpen, selectedId }) => {
    return (
        <>
            <div style={{
                width: '100%',
                height: "100%",
                zIndex: 1000,
                top: 0,
                left: 0,
                position: 'fixed',
                background: 'rgba(0,0,0,0.5)',
            }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                            width: 450,
                            backgroundColor: colors.white01,
                            borderRadius: 16,
                            zIndex: 0,
                            border: `1px solid ${colors.borderwhite}`,
                            paddingBlock: 20,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div
                            className="d-flex flex-row justify-content-between align-items-center w-100"
                            style={{
                                paddingBlockEnd: 8,
                                borderBottom: `1px solid ${colors.borderwhite}`,
                                paddingInline: 16,
                            }}
                        >

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <img src={images.CDelete} style={{ height: 24, width: 'auto' }} />
                                <p
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: 18,
                                        fontWeight: 700,
                                        color: colors.black,
                                    }}
                                >
                                    {'Delete'}
                                </p>
                            </div>
                            <img
                                src={images.CMClose}
                                style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setDeleteModalOpen(false);
                                }}
                            ></img>
                        </div>
                        <p
                            style={{
                                margin: 12,
                                fontSize: 16,
                                fontWeight: 500,
                                color: colors.black,
                                marginInline: 16,
                            }}
                        >
                            {'Deleting the attribute will delete all the mapped data linked with it. Are you sure you want to continue?'}
                        </p>
                        <div
                            className="d-flex flex-row justify-content-center align-items-center w-100"
                            style={{ marginTop: 12, gap: '10px' }}
                        >
                            <CheerioButton
                                borderStyle={{
                                    width: '40%',
                                    marginInlineStart: 8,
                                    border: `1px solid ${colors.primary03}`,
                                }}
                                textStyle={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: colors.primary03,
                                }}
                                btnText={'Cancel'}
                                backColor={colors.white}
                                onclick={() => {
                                    console.log('cancel clicked . . .');
                                    setDeleteModalOpen(false);
                                }}
                            />
                            <CheerioButton
                                borderStyle={{
                                    borderColor: "#EA2727",
                                    width: '40%',
                                    marginInlineEnd: 8,
                                }}
                                textStyle={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: colors.white,
                                }}
                                btnText={'Delete'}
                                backColor={colors.red49}
                                onclick={() => {
                                    setDeleteModalOpen(false);
                                    SetrevertDeleteAttributeOpen(true)
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

const RevertdeleteAttributeModal = ({
    setRevertDeleteAttributeOpen,
    onDeleteConfirmed,
    selectedId,
}) => {
    const [countdown, setCountdown] = useState(10);
    const timerRef = useRef(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (!selectedId) return;
        let currentCount = 9;
        intervalRef.current = setInterval(() => {
            currentCount -= 1;
            setCountdown(currentCount);

            if (currentCount <= 0) {
                clearInterval(intervalRef.current);
            }
        }, 1000);

        // Set up deletion timeout
        timerRef.current = setTimeout(() => {
            setRevertDeleteAttributeOpen(false);
            onDeleteConfirmed();
        }, 10000);

        // Cleanup function
        return () => {
            clearTimeout(timerRef.current);
            clearInterval(intervalRef.current);
        };
    }, [selectedId]);

    const handleUndo = useCallback(() => {
        clearTimeout(timerRef.current);
        clearInterval(intervalRef.current);
        setRevertDeleteAttributeOpen(false);
        toast.error('Delete operation cancelled');
    }, [setRevertDeleteAttributeOpen]);

    const handleClose = useCallback(() => {
        clearTimeout(timerRef.current);
        clearInterval(intervalRef.current);
        setRevertDeleteAttributeOpen(false);
        onDeleteConfirmed();
    }, [setRevertDeleteAttributeOpen, onDeleteConfirmed]);


    return (
        <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{
                position: "absolute",
                top: "60px",
                left: "77%",
                width: "auto",
                backgroundColor: colors.white,
                borderRadius: 8,
                padding: "12px 20px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                zIndex: 1100,
            }}
        >
            <p
                style={{
                    marginRight: 8,
                    fontSize: 16,
                    fontWeight: 500,
                    color: colors.black,
                    width: "100%",
                }}
            >
                Do you want to revert?
            </p>
            <CheerioButton
                borderStyle={{ border: "none", width: "80%" }}
                textStyle={{ color: colors.white }}
                btnText={`Undo (${countdown} sec)`}
                backColor={colors.darkPurple}
                onclick={handleUndo}
            />
            <i
                className="pi pi-times"
                style={{
                    fontSize: "18px",
                    marginLeft: "12px",
                    cursor: "pointer",
                    color: colors.black,
                }}
                onClick={handleClose}
            ></i>
        </div>
    );
};
