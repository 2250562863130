import React from 'react';
import { eventsend } from '../../../Config/analyticsFunctions';

const AnnTemplates = ({ TemplateV2Type, setTemplateV2Type, sSelected, wSelected }) => {
  const AnnSelectItem = ({ text }) => {
    return (
      <>
        <div
          className="ann_templates_select_item"
          style={{
            borderBottom: TemplateV2Type === text ? `2px solid #8E53F7` : '',
          }}
          onClick={() => {
            setTemplateV2Type(text);
            if(text==="WhatsApp templates"){
              eventsend("WhatsApp_template_bar_campaign_pop_up_clicked");
            }else if(text==="SMS Templates"){
              eventsend("sms_template_bar_campaign_pop_up_clicked");
            }else{
              eventsend("use_template_clicked_campaign_pop_up_clicked");
            }
          }}
        >
          <p
            style={{
              fontWeight: TemplateV2Type === text ? 600 : 400,
              color: TemplateV2Type === text ? '#0D0D0D' : '#666666',
            }}
          >
            {text}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="ann_templates_select">
        <AnnSelectItem text={'Recommended templates'} />
        {wSelected && <AnnSelectItem text={'WhatsApp templates'} />}{' '}
        {sSelected && <AnnSelectItem text={'SMS Templates'} />}{' '}
      </div>
    </>
  );
};

export default AnnTemplates;
