import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import { useSelector } from 'react-redux';
import { getlabelbalanceAPI } from '../../../Services';
import CheerioButton from '../../../Components/CheerioButton';

const CampaignReviewSend = ({ selectedLabel, validNodesCount }) => {

    const authToken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);

    const [balance, setBalance] = useState()

    useEffect(()=>{
        if(selectedLabel?.label_name){
            getlabelbalanceapicall();
        }
    },[])

    const getlabelbalanceapicall = () => {
        let data = {
            user_id: localdata?._id,
            label: selectedLabel?.label_name,
        };
        let token = authToken;
        getlabelbalanceAPI(data, token).then((res) => {
            if (res.flag) {
                console.log('balance res success ===> ', res.data.templateList);
                setBalance(res.data.templateList);
            } else {
                console.log('balance res error ===> ', res);
            }
        });
    };

  return (
    <>
    {/* <div className='Column justify-content-start align-items-start' style={{
        width:620,
        backgroundColor: colors.white01,
        border:`1px solid ${colors.borderwhite}`,
        borderRadius:16,
        paddingBlock:20,
        paddingInline:24,
    }}> */}
        <h4 className='mt-3 mb-2 w-100'>
            {'Sending to'}
        </h4>
        <p className='mt-2 mb-3 w-100'>
            {selectedLabel?.label_name}
        </p>
        {/* <div className='Row w-100 my-4'> */}
            <div className='Column justify-content-start align-items-start w-100 my-3'>
                <h4 className='mb-2'>
                    {'Required email credits'} 
                </h4>
                <p className='mt-2'>
                    {`${Number(balance?.emailArr?.length)*validNodesCount}`}
                </p>
            </div>
            <div className='Column justify-content-start align-items-start w-100 my-3'>
                <h4 className='mb-2'>
                    {'Available email credits'} 
                </h4>
                <p className='mt-2'>
                    {balance?.balance?.email}
                </p>
            </div>
        {/* </div> */}
        {/* <div className='Row my-2 w-100 mt-auto mb-0'>
            <CheerioButton 
                btnText={'Run a test'}
                textStyle={{color:colors.black}}
                backColor={colors.white01}
                borderStyle={{borderColor:colors.black,width:'48%'}} 
                onclick={()=>{console.log("run test clicked")}}
            />
            <CheerioButton 
                btnText={'Start sequence'}
                textStyle={{color:colors.white01}}
                backColor={colors.primary03}
                borderStyle={{borderColor:colors.primary03,width:'48%'}} 
                onclick={()=>{console.log("run test clicked")}}
            />
        </div> */}
    {/* </div> */}
    </>
  )
}

export default CampaignReviewSend