import React, { useState } from 'react'
import colors from '../../../Utils/colors'
import CustomHandle from '../../WorkFlow/Nodes/CustomHandle'
import { Position } from 'reactflow'
import images from '../../../Utils/images'

const handleStyle = {backgroundColor: colors.primary03,height:8,width:8,borderRadius:4};

const AddaStepNode = ({ data, isConnectable }) => {

    // const [showModal, setShowModal] = useState(false);

    // const handleOpenModal = () => setShowModal(true);
    // const handleCloseModal = () => setShowModal(false);

    // const handleAddNode = (nodeType) => {
    //     data.onAddNode(nodeType);
    //     setShowModal(false);
    // }

  return (
    <>
    <div className='Row justify-content-center' style={{
        border:`1px solid ${colors.borderwhite}`,
        borderRadius:16,
        backgroundColor: colors.primary03,
        // width:250,
        paddingBlock:16,
        paddingInline:20,
    }}>
        <CustomHandle type='target' position={Position.Top} isConnectable={1} />
        <img 
            src={images.AddIcon} 
            style={{height:20,width:20,objectFit:'contain',marginInlineEnd:16}}
        />
        <p style={{color:colors.white01,fontSize:16,fontWeight:600}}>
            {'Add a step'}
        </p>
        {/* {showModal && 
            <>
            <div className='DarkBg' onClick={()=>{handleCloseModal()}}>
                <div className='Center'>
                    <div className='Column'>
                        <div className='Row my-1' onClick={()=>{
                            handleAddNode("emailFollowUp")
                        }}>
                            <p>
                                {'Write an email'}
                            </p>
                        </div>
                        <div className='Row my-1' onClick={()=>{
                            handleAddNode("sendEmailTemplate")
                        }}>
                            <p>
                                {'Send email template'}
                            </p>
                        </div>
                        <div className='Row my-1' onClick={()=>{
                            handleAddNode("timeDelay")
                        }}>
                            <p>
                                {'Time delay'}
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
            </>
        } */}
    </div>
    </>
  )
}

export default AddaStepNode