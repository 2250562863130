import React from 'react'
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CustomHandle from '../../WorkFlow/Nodes/CustomHandle';
import { Position } from 'reactflow';

const handleStyle = {backgroundColor: colors.primary03,height:8,width:8,borderRadius:4};

const TimeDelayNode = ({ data, isConnectable, selected }) => {

    const timeToText = ( data ) => {
        const timeParts = [];
        if (data?.days > 0) timeParts.push(`${data?.days} day${data?.days > 1 ? "s" : ""}`);
        if (data?.hours > 0) timeParts.push(`${data?.hours} hr${data?.hours > 1 ? "s" : ""}`);
        if (data?.minutes > 0) timeParts.push(`${data?.minutes} min${data?.minutes > 1 ? "s" : ""}`);
      
        return timeParts.join(", ");
    };

  return (
    <>
    <div className='Row justify-content-center' style={{
        border: selected ? `2px solid ${colors.primary03}` : `1px solid ${colors.borderwhite}`,
        borderRadius:16,
        backgroundColor: colors.white01,
        paddingBlock:16,
        paddingInline:20, 
        width: 250,
    }}>
        <CustomHandle type='target' position={Position.Top} isConnectable={1} />
        <CustomHandle type='source' position={Position.Bottom} isConnectable={1} />
        <img 
            src={images.SeqTDNode} 
            style={{
                height:32,width:32,objectFit:'contain'
            }}
        />
        <p style={{fontSize:16,fontWeight:600,marginInline:16}}>
            {data?.days || data?.hours || data?.minutes ? `${timeToText(data)}` : `Add time delay`}
        </p>
        {/* <img 
            src={images.SeqThreeDots} 
            style={{
                height:24,width:24,objectFit:'contain',cursor:'pointer'
            }}
        /> */}
        </div>
    </>
  )
}

export default TimeDelayNode