import React, { useEffect, useState, useCallback } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import '../../../Components/Modal/Modal.css';
import { eventsend } from '../../../Config/analyticsFunctions';

const CheckOtp = ({
    setSaveChangesStarted,
    resendOtp,
    checkOtp,
    otp,
    setOtp
}) => {

    useEffect(() => {
        eventsend("Verify_save_page_clicked")
    }, [])
    
    const [emailTimer, setEmailTimer] = useState(10);

    const emailTimeOutCallback = useCallback(() => setEmailTimer(currTimer => currTimer - 1), []);

    useEffect(() => {
        emailTimer > 0 && setTimeout(emailTimeOutCallback, 1000);
    }, [emailTimer, emailTimeOutCallback]);

    const resetEmailTimer = function () {
        if (!emailTimer) {
          setEmailTimer(30);
        }
    };

    const handleResendEmailOTP = () => {
        resendOtp();
        eventsend("Resend_EmailOTP_OTP_popup_clicked");
        resetEmailTimer();
      };

    return (
        <>
            <div className='ModalDarkBg'>
                <div className="Center">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        checkOtp(otp);
                    }}
                    >
                        <div className='otp_verify_modal'>
                            <div className='otp_verify_modal__header'>
                                <img src={images.CMClose} onClick={()=> {setSaveChangesStarted(false)}}></img>
                            </div>
                            <div className="Row">
                                <p
                                    style={Styles.freeTrial}
                                >
                                    Enter Email OTP
                                </p>
                            </div>

                            <div className='otp_verify_modal__body'>
                                <h6 className='enter_otp_text'>
                                    Enter the OTP received on your email
                                </h6>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter you password"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </div>

                            <button
                                onClick={handleResendEmailOTP}
                                type="button"
                                className={`btn btn-outline-dark mt-3`}
                                disabled={emailTimer != 0}
                                style={{ marginBottom: '2%', marginTop: '1%' }}
                                >
                                Resend Email OTP
                            </button>

                            {emailTimer > 0 && (
                            <p style={{ fontSize: 12, color: colors.greys04 }} className="form-label">
                                {`Retry in ${emailTimer} seconds`}
                            </p>
                            )}

                            <div style={{ width: '100%', marginTop: 'auto', marginBottom: '5%' }}>
                                <button
                                    style={{ width: '100%' }}
                                    type="submit"
                                    className="btn btn-dark"
                                    disabled={otp.length !== 6}                                >
                                    Verify and Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </>
    );
};

export default CheckOtp;

const Styles = {
    DarkBg: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        width: '100vw',
        height: '100vh',
        // flex:1,
        zIndex: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        position: 'fixed',
        zIndex: '1231423',
    },
    Center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
    TxtHeading: {
        fontSize: 42,
        fontWeight: 900,
        color: colors.primary03,
    },
    TxtBody: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.black03,
    },
    TxtBody2: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.black03,
    },
    itemborder: {
        backgroundColor: colors.white,
        borderRadius: 16,
        boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    freeTrial: {
        margin: 0,
        fontSize: 24,
        fontWeight: 700,
        color: colors.black,
        fontWeight: 'bold',
    },
};
