import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SpinnerLoader from '../../../Components/SpinnerLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const GraphComponent = ({ graphData, graphIsLoading=false}) => {

    const [labels, setLabels] = useState(['Day 1','Day 2','Day 3','Day 4','Day 5','Day 6','Day 7']);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Sent',
                backgroundColor: '#9357FF',
                data: graphData.map((item,index)=>item.sent).reverse(),
            },
            {
                label: 'Opened',
                backgroundColor: '#BF51C1',
                data: graphData.map((item,index)=>item.opened).reverse(),
            },
            {
                label: 'Clicked',
                backgroundColor: '#EEB728',
                data: graphData.map((item,index)=>item.clicked).reverse(),
            },
            {
                label: 'Replied',
                backgroundColor: '#51C1C1',
                data: graphData.map((item,index)=>item.replied).reverse(),
            },
            {
                label: 'Bounced',
                backgroundColor: '#E9758A',
                data: graphData.map((item,index)=>item.bounced).reverse(),
            },
        ],
      };

  return (
    <>
    <div className='graph_component_container' style={{width:'100%',paddingInline:0}}>
        <div className='graph_component_div shadow_small'>
            <div className='graph_component_div__row'>
                <h3>
                    {'Email Reach Breakup'}
                </h3>
                {/* {isShopify ? (
                    <>
                    <p style={{fontSize: 14, fontWeight: 400, color: colors.greys01}}>
                        {'Last 7 days'}
                    </p>
                    </>
                ):(
                    <Form.Select
                        type="text"
                        placeholder="time"
                        className="btncustom dropdown"
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01, width:'auto' }}
                        value={graphTimeframe}
                        onChange={(e) => {
                            setGraphTimeframe(e.target.value);
                        }}
                    >
                        {graphTimeframeArr.map((item,index)=>{
                            return(
                                <option 
                                    style={{marginTop: 4, marginBottom: 4, borderRadius: 8}}
                                    value={item.id}
                                    key={item.id}
                                >
                                    {item.label}
                                </option>
                            )
                        })}
                    </Form.Select> 
                )}     */}
            </div>
            <div className='graph_component_div__graph'>
                {graphIsLoading ? (
                    <>
                    <SpinnerLoader />
                    </>
                ):(
                    <>
                    <Bar options={options} data={data} height={'300px'}/>
                    </>
                )}
            </div>
        </div>
    </div>
    </>
  )
}

export default GraphComponent