import './index.scss';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Stack } from '@mui/material';
import { getRandomColor } from './variableDependencies';
import { FiEdit2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateContactSingle,
  getIndAttributes,
  getClientAttributes,
  AddEditAttributes,
  getCustomAttr
} from '../../../Services';
import { toast } from 'react-toastify';
import TagInput from './TagInput';
import { useState, useEffect } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import LabelInput from './LabelInput';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import data from '@emoji-mart/data';
import TextField from '@mui/material/TextField';
import { MenuItem } from 'react-bootstrap-typeahead';
import { triggerReRender } from '../Labels/redux';
import UserAttributesModal from '../../WhatsAppReplies/components/UserAttributesModal';
import { eventsend } from '../../../Config/analyticsFunctions';
import {
  formatTimeForDisplay,
  formatTimestampForDisplay,
  formatDateForDisplay,
  formatTimeForInput,
  formatTimestampForInput,
  formatDateForInput,
} from '../../../Utils/formatConvertor';

const Details = ({
  data,
  attributesObject, // all attributes
  updateattributes, // updated attributes
  userAttributes,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const currentFormState = useSelector((state) => state.contactsRedux.editState);
  const authToken = useSelector((state) => state.main.auth_token);
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [attr, setAttr] = useState([]);

  const fetchAttr = async () => {
    const response = await getCustomAttr(authtoken);
    if (response.flag) {
      const data = response.data;
      setAttr(data);
    }
  };

  useEffect(() => {
    fetchAttr();
  }, []);

  const addAttributesCheck = (attr, value, user = data) => {
    console.log('attr', attr);
    let token = authToken;
    let change = {};
    const attrType = attr?.find((a) => a.attribute === attribute)?.type;
    if (attrType === 'date') {
      value = formatDateForInput(value);
    }
    change[attr] = value;
    let data = {
      clientPhone: currentFormState.mobile,
      newChange: change,
    };
    AddEditAttributes(token, data).then((res) => {
      if (res) {
        updateattributes();
        toast.success('Attribute successfully updated!', {

          position: 'top-right',
        });
        // console.log("resp succ ===> ",res);
      } else {
        toast.error('Attribute could not be updated, please try again later', {

          position: 'top-right',
        });
        // console.log("res fail ===> ",res);
      }
    });
  };

  const selectedAttributeAction = (keyValue) => {
    if (keyValue in attributesObject) {
      toast('Attribute already exists', { position: 'top-right' });
    } else {
      addAttributesCheck(keyValue, '');
    }
    setUAModalOpen(false);
  };

  const Content = ({ field, value, editable = false, setAttributeValueAPICall, attribute }) => {
    const [inputField, setInputField] = useState(false);
    const [fieldValue, setFieldValue] = useState('');
    console.log('attribute', attribute);

    const handleChange = (e) => {
      if (attribute?.type === "date") {
        const dateValue = formatDateForDisplay(e.target.value);
        setFieldValue(dateValue);
      } else if (attribute?.type === "time") {
        const timeValue = e.target.value;
        setFieldValue(timeValue);
      } else {
        setFieldValue(e.target.value);
      }
    };

    const renderInputField = () => {
      switch (attribute?.type) {
        case 'number':
          return (
            <TextField
              label="Enter a number"
              variant="standard"
              type="number"
              value={fieldValue}
              size="small"
              fullWidth
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAttributeValueAPICall(field, fieldValue);
                }
              }}
            />
          );
        case 'dropdown':
          return (
            <TextField
              label="Select an option"
              variant="standard"
              select
              value={fieldValue}
              size="small"
              fullWidth
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAttributeValueAPICall(field, fieldValue);
                }
              }}
            >
              {attribute?.options?.map((option) => (
                <MenuItem key={option} value={option?.optionName} style={{
                  backgroundColor: option.color,
                  color: "#000",
                }}>
                  {option?.optionName}
                </MenuItem>
              ))}
            </TextField>
          );
        case 'date':
          return (
            <TextField
              label="Select a date"
              variant="standard"
              type="date"
              value={formatDateForInput(fieldValue)}
              size="small"
              fullWidth
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAttributeValueAPICall(field, fieldValue);
                }
              }}
            />
          );
        case 'time':
          return (
            <TextField
              label="Select time"
              variant="standard"
              type="time"
              value={(fieldValue.includes('AM') || fieldValue.includes('PM')) ? formatTimeForInput(fieldValue) : fieldValue}
              size="small"
              fullWidth
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAttributeValueAPICall(field, fieldValue);
                }
              }}
            />
          );
        case 'timestamp':
          return (
            <TextField
              label="Select timestamp"
              variant="standard"
              type="datetime-local"
              value={fieldValue}
              size="small"
              fullWidth
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAttributeValueAPICall(field, fieldValue);
                }
              }}
            />
          );
        default:
          return (
            <TextField
              label="Enter here"
              variant="standard"
              value={fieldValue}
              size="small"
              fullWidth
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setAttributeValueAPICall(field, fieldValue);
                }
              }}
            />
          );
      }
    }

    return (
      <div className="d-flex justify-content-between flex-row w-100 align-items-center my-2">
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: '35%' }}
        >
          <p
            style={{
              fontSize: '14px',
              fontWeight: '400',
              color: '#666666',
              border: '',
            }}
          >
            {field}
          </p>
          <p style={{}}>:</p>
        </div>
        <div
          className="d-flex flex-row justify-content-between align-items-center px-2"
          style={{ width: '65%' }}
        >
          {inputField ? (
            <>
              {/* <TextField
                label="Enter here"
                variant="standard"
                value={fieldValue}
                size="small"
                fullWidth
                onChange={(e) => setFieldValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setAttributeValueAPICall(field, fieldValue);
                  }
                }}
                style={{}}
              /> */}

              {renderInputField()}
              <img
                src={images.tickSent}
                style={{
                  height: 20,
                  width: 20,
                  objectFit: 'contain',
                  cursor: 'pointer',
                  marginInlineStart: 8,
                }}
                onClick={() => {
                  setAttributeValueAPICall(field, fieldValue);
                }}
              ></img>
            </>
          ) : (
            <>
              <p style={{ fontSize: '16px', wordBreak: 'break-all' }}>{
                attribute?.type === "time" ? formatTimeForDisplay(value) : attribute?.type === "timestamp" ? formatTimestampForDisplay(value) : value}</p>
              {editable && (
                <img
                  src={images.EditBlack}
                  style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                  onClick={() => {
                    setFieldValue(value);
                    setInputField(true);
                  }}
                ></img>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ padding: '12px', position: 'relative' }}
      className="d-flex flex-column justify-content-between align-items-center h-100 w-100"
    >
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100"
        style={{ height: '60%' }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
          style={{ marginBlock: 8, flex: 1, overflowY: 'auto' }}
        >
          {attributesObject &&
            Object.keys(attributesObject).length !== 0 &&
            Object.entries(attributesObject).map(([key, value]) => {
              return (
                <Content
                  field={key}
                  value={value ? value : '--'}
                  editable={true}
                  setAttributeValueAPICall={(key, value) => {
                    addAttributesCheck(key, value);
                  }}
                  attribute={
                    attr?.find((item) => item?.attribute === key)
                  }
                />
              );
            })}
        </div>
        <div
          className="d-flex flex-row justify-content-start align-items-center "
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            className="d-flex flex-row justify-content-center align-items-center ms-0 me-auto my-1"
            style={{
              borderRadius: 6,
              border: `1px solid ${colors.black}`,
              cursor: 'pointer',
              paddingBlock: 6,
              paddingInline: 16,
            }}
            onClick={() => {
              if (userAttributes?.length > 0) {
                setUAModalOpen(true);
              }
            }}
          >
            <img src={images.HNCampaign} style={{ height: 16, width: 16, objectFit: 'contain', marginInlineEnd: 6 }}></img>
            <p style={{ fontSize: 14, fontWeight: 400 }}>{'Add Attribute'}</p>
          </div>

          {UAModalOpen && (
            <UserAttributesModal
              setUserAttributesOpen={setUAModalOpen}
              userAttributesData={userAttributes}
              selectedUserAttributeAction={selectedAttributeAction}
              positionStyle={{
                // top: userAttributes?.length > 0 ? '80%' : '20%', left: userAttributes?.length > 0 ? '40%' : '25%', width: 200,
                top: '0',
                left: '35%',
                width: 200,
                position: 'absolute',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const EditSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentFormState = useSelector((state) => state.contactsRedux.editState);
  const authToken = useSelector((state) => state.main.auth_token);
  //state control for editing
  const [labelEditState, setLabelEditState] = useState(false);
  const [userAttributes, setUserAttributes] = useState();
  const [tagEditState, setTagEditState] = useState(false);
  //setting the various states
  const [tagsList, setTagsList] = useState(
    new Set(currentFormState.tags.map((vlss) => vlss.tag_name))
  );
  const [labelsList, setLabelsList] = useState(
    new Set(currentFormState.labels.map((vlss) => vlss.label_name))
  );
  const [individualAttributes, setIndividualAttributes] = useState({});

  useEffect(() => {
    getUserAttributesAPICall();
    eventsend("Edit_contact_Contact_page_clicked");
  }, [])

  const getUserAttributesAPICall = () => {
    let token = authToken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  useEffect(() => {
    indAttributesAPIcall();
  }, [currentFormState]);

  const indAttributesAPIcall = async () => {
    try {
      const token = authToken;
      const data = {
        id: currentFormState._id,
      };
      const res = await getIndAttributes(token, data);
      if (res?.flag) {
        setIndividualAttributes(res?.data);
      } else {
        // Handle error
        console.log('Failed to fetch user attributes:', res?.message);
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching user attributes:', error);
    }
  };

  return (
    <form
      className={'editSideBarDiv'}
      onSubmit={(e) => {
        e.preventDefault();
        let updatedData = {
          name: e.target.name.value,
          mobile: e.target.mobile.value,
          email: e.target.email.value,
          labels: [...labelsList],
          tags: [...tagsList],
        };
        updateContactSingle(currentFormState._id, updatedData, authToken)
          .then((res) => {
            dispatch(triggerReRender());
            navigate('..', { relative: 'path' });
          })
          .catch((err) => {
            toast.error('Failed to updated contact!');
          });
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div
        className="roundedBack"
        onClick={() => {
          navigate('..', { relative: true });
        }}
      ></div>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack gap={'8px'} direction={'row'} alignItems={'center'}>
          <IoIosCloseCircleOutline
            onClick={() => {
              navigate('..', { relative: true });
            }}
          />
          <span className={'mainHeader'}>Edit Contact</span>
        </Stack>
        <button className={'saveButton'} type={'submit'}>
          Save
        </button>
      </Stack>

      {/*associated inputs*/}
      <Stack gap={'20px'} marginTop={'30px'}>
        <Stack>
          <span className={'inputHeader'}>Name</span>
          <input
            type={'text'}
            className={'inputBar'}
            defaultValue={currentFormState.name}
            name={'name'}
          />
        </Stack>
        <Stack>
          <span className={'inputHeader'}>Phone No</span>
          <input
            type={'text'}
            className={'inputBar'}
            defaultValue={currentFormState.mobile}
            name={'mobile'}
          />
        </Stack>
        <Stack>
          <span className={'inputHeader'}>Email</span>
          <input
            type={'text'}
            className={'inputBar'}
            defaultValue={currentFormState.email}
            name={'email'}
          />
        </Stack>
        <div>
          <Details
            data={data}
            updateattributes={() => indAttributesAPIcall()}
            attributesObject={individualAttributes}
            userAttributes={userAttributes}
          />
        </div>
        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <span className={'inputHeader'}>Associated labels</span>
            <div
              onClick={() => {
                setLabelEditState((state) => !state);
              }}
            >
              {!labelEditState ? <FiEdit2 /> : <AiOutlineCheck />}
            </div>
          </Stack>
          <Stack
            marginTop={'14px '}
            display={'flex'}
            flexDirection={'row'}
            gap={'10px'}
            flexWrap={'wrap'}
          >
            {!labelEditState &&
              [...labelsList].length !== 0 &&
              [...labelsList].map((vlss) => {
                const getColor = getRandomColor();
                return (
                  <div
                    style={{
                      color: getColor,
                      borderColor: getColor,
                    }}
                    className={'colorizedLabels'}
                  >
                    {vlss}
                  </div>
                );
              })}
            {labelEditState && (
              <LabelInput
                value={[...labelsList]}
                onChecked={(state) => {
                  setLabelsList(state);
                }}
              />
            )}
          </Stack>
        </Stack>
        {/* <Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <span className={"inputHeader"}>Associated Tags</span>
              <div
                onClick={() => {
                  setTagEditState((state) => !state);
                }}
              >
                {!tagEditState ? <FiEdit2 /> : <AiOutlineCheck />}
              </div>
            </Stack>
            <Stack
              marginTop={"14px"}
              display={"flex"}
              flexDirection={"row"}
              gap={"10px"}
              flexWrap={"wrap"}
            >
              {!tagEditState &&
                [...tagsList].length !== 0 &&
                [...tagsList].map((vlss) => {
                  return <div className={"radialButton"}>{vlss}</div>;
                })}
              {tagEditState && (
                <TagInput
                  value={[...tagsList]}
                  onChecked={(state) => {
                    setTagsList(state);
                  }}
                />
              )}
            </Stack>
          </Stack> */}
      </Stack>
    </form>
  );
};
export default EditSidebar;
