import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import { GetTemplatesV2API } from '../../../Services';
import { useSelector } from 'react-redux';
import { SyncFBWorkflowModal } from './SyncFBWorkflowModal';

const FacebookLeadNodeModal = ({ data, setData, btnDisabled, setBtnDisabled, showFBWorkflowModal, setShowFBWorkflowModal, before, after, setBefore, setAfter, setSyncData, syncData, onSubmit }) => {
    console.log('showFBWorkflowModal in FacebookLeadNodeModal', showFBWorkflowModal);
    const authtoken = useSelector((state) => state.main.auth_token);

    useEffect(() => {
        setBtnDisabled(false);
    }, []);
    /* useEffect(() => {
        console.log("SYNC DATA 2", syncData)
        if (syncData.adName != "" && syncData.adId != "") {
            console.log("SYNC DATA 3", syncData)
            console.log("Data 2", data)
            setData({ ...data, adName: syncData.adName, adId: syncData.adId });
        }
    }, [syncData]); */



    return (
        <>
            <div className="w-100 h-100 flex-column justify-content-start align-items-start">
                <p style={{ fontSize: 16, fontWeight: 400, color: colors.black, marginTop: '10px', marginBottom: '10px' }}>
                    To continue, login to your Facebook account
                </p>

            </div>
            <SyncFBWorkflowModal
                show={showFBWorkflowModal}
                setShow={setShowFBWorkflowModal}
                data={data}
                setData={setData}
                before={before}
                after={after}
                setBefore={setBefore}
                setAfter={setAfter}
                syncData={syncData}
                setSyncData={setSyncData}
                onSubmit={onSubmit}
            ></SyncFBWorkflowModal>
        </>
    );
};

export default FacebookLeadNodeModal;
