import React from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { useNavigate } from 'react-router-dom';
import { getImagePath } from '../../../Utils';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import images from '../../../Utils/images';
import styles from './../styles.module.css';
import HEImages from './HEImages';
import { Tooltip } from '@mui/material';
import { handleTrackEvent } from '../../../Utils/brevo';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { eventsend } from '../../../Config/analyticsFunctions';

const ProfilePopup = ({ setProfilePopup }) => {
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const dispatch = useDispatch();

  const mobileWidth = window.innerWidth < 700 ? true : false;


  return (
    <>
      <ClickAwayListener onClickAway={() => { setProfilePopup(false) }}>
        <div
          className={`${styles['Column']} ${styles['ProfilePopupContainer']}`}
          style={{
            width: mobileWidth ? 300 : 360,
          }}
        >
          <div
            className={`${styles['Column']} ${styles['ProfilePopupInnerContainerTop']}`}
            style={{
              backgroundColor: colors.white03,
            }}
          >
            <div className={`${styles['Row']}`} style={{ width: '100%' }}>
              <HEImages
                userGeneratedSrc={
                  agentdata?.profileImg ? agentdata?.profileImg : getImagePath(localdata?.picture)
                }
                classname={`${styles['ProfilePopupImage']}`}
              />
              {/* <img
              src={agentdata?.profileImg ? agentdata?.profileImg : getImagePath(localdata?.picture)}
              className={`${styles['ProfilePopupImage']}`}
            ></img> */}
              <div
                className={`${styles['Column']}`}
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  overflow: 'hidden',
                }}
              >
                <Tooltip
                  title={
                    agentdata?.name
                      ? agentdata?.name
                      : localdata?.name
                        ? localdata?.name
                        : localdata?.nickname
                  }
                  placement="top"
                >
                  <p
                    className={`${styles['ProfilePopupHeadText']}`}
                    style={{
                      color: colors.black,
                      width: '100%',
                    }}
                  >
                    {agentdata?.name
                      ? agentdata?.name
                      : localdata?.name
                        ? localdata?.name
                        : localdata?.nickname}
                  </p>
                </Tooltip>
                <p
                  className={`${styles['ProfilePopupBodyText']}`}
                  style={{
                    color: colors.black04,
                    width: '90%',
                  }}
                >
                  {localdata?.company_name?.length > 20
                    ? localdata?.company_name?.substring(0, 20) + '...'
                    : localdata?.company_name}
                </p>
              </div>
            </div>
            {!mobileWidth && (
              <CheerioButton
                borderStyle={{ width: '100%', marginBlockStart: 16 }}
                textStyle={{ fontSize: 16, fontWeight: 600 }}
                btnText={'Manage Profile'}
                backColor={colors.white03}
                onclick={() => {
                  handleTrackEvent('sidebar_manage_profile_button_clicked', localdata);
                  setProfilePopup(false);
                  eventsend("Manage Profile_navigation_bar_clicked");
                  // navigate('/creatorprofile');
                  navigate('/manageprofile');
                }}
                icon={images.ProfilePopup}
              />
            )}
          </div>
          <div
            className={`${styles['Row']} ${styles['ProfilePopupInnerContainerBottom']}`}
            style={{
              backgroundColor: colors.black04,
            }}
            onClick={() => {
              handleTrackEvent('logout_button_clicked', localdata);
              eventsend("Logout_navbar_clicked");
              googleLogout();
              dispatch({ type: 'redirectToLogin', payload: true });
              dispatch({ type: 'logout' });
              localStorage.removeItem('token');
              navigate('/login');
            }}
          >
            <img
              src={images.LogoutWhite}
              className={`${styles['Image']}`}
              style={{
                marginInlineEnd: 12,
              }}
            ></img>
            <p
              className={`${styles['ProfilePopupLogoutText']}`}
              style={{
                color: colors.white01,
              }}
            >
              {'Logout'}
            </p>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ProfilePopup;
