import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getSequencesAnalytics } from '../../../Services';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import { getLocaltime } from '../../../Utils/localdate';
import { LinearProgress } from '@mui/material';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import GraphComponent from '../Components/GraphComponent';

const RowItem = ({ itemText, itemNumber }) => {
    return(
        <>
        <div className='Row justify-content-between w-100 my-2'>
            <p style={{fontSize:16,fontWeight:400}}>
                {itemText}
            </p>
            <p style={{fontSize:16,fontWeight:400}}>
                {itemNumber}
            </p>
        </div>
        </>
    )
}

const ColumnItem = ({ itemNumber, itemText, itemColor }) => {
    return(
        <>
        <div className='Column justify-content-start align-items-start' style={{flex:1}}>
            <p style={{fontSize:20,fontWeight:600}}>
                {itemNumber}
            </p>
            <div className='Row justify-content-start'>
                <div style={{
                    height:12, 
                    width:12, 
                    backgroundColor:itemColor, 
                    borderRadius:'50%', 
                    marginInlineEnd:8,
                }}></div>
                <p style={{fontSize:16,fontWeight:400}}>
                    {itemText}
                </p>
            </div>
        </div>
        </>
    )
}

const SequenceAnalytics = () => {

    const { id } = useParams(); 
    const location = useLocation(); 
    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token); 
    const sequenceData = location.state;
    const [analyticsData, setAnalyticsData] = useState([])

    useEffect(()=>{
        getSequenceAnalyticsAPIcall();
        console.log("22222 ",id)
        console.log("22222 ",sequenceData)
    },[])

    const getSequenceAnalyticsAPIcall = () => {
        let token = authToken;
        let data = {
            _id: id,
        };
        getSequencesAnalytics(data,token).then((res)=>{
            if(res?.flag){
                console.log("success ---> ",res)
                setAnalyticsData(res?.data)
            }else{
                console.log("failure ---> ",res)
            }
        })
    }

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ["Leads Reached", "Leads Unsubscribed"],
        datasets: [
          {
            data: [(Number(analyticsData?.leadQuality?.reach)/(Number(analyticsData?.leadQuality?.reach)+Number(analyticsData?.leadQuality?.unsubs)))*100, (Number(analyticsData?.leadQuality?.unsubs)/(Number(analyticsData?.leadQuality?.reach)+Number(analyticsData?.leadQuality?.unsubs)))*100], // 87% completed, 13% remaining
            backgroundColor: ["#4653F3", "#9BA3FF"], // Colors for sections
            hoverBackgroundColor: ["#4653F3", "#9BA3FF"], // Hover effect
            borderWidth: 0, // Remove border
          },
        ],
      };
    
      const options = {
        cutout: "70%", // Adjust the inner radius for the donut effect
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true, // Disable tooltips if not needed
          },
          legend: {
            display: false, // Hide the legend
          },
        },
      };

  return (
    <>
    <div className='Column justify-content-start align-items-center w-100 h-100' style={{
        overflow: 'hidden',
        paddingBlock: 24, 
        paddingInline: 30,
    }}>
        <div className='Row w-100 justify-content-between align-items-start' style={{
            marginBlockEnd:20,
        }}>
            <img 
                src={images.BackArrowBlack} 
                style={{height:24,width:24,objectFit:'contain',cursor:'pointer'}} 
                onClick={()=>{navigate('/creator/sequences')}}
            />
            <div className='Column justify-content-start align-items-start' style={{
                paddingInline:16,
                flex: 1,
            }}>
                <h3 style={{fontSize:24,fontWeight:700}}>
                    {sequenceData?.campaignName}
                </h3>
                <p className='my-1' style={{
                    fontSize:14, fontWeight:400, color:colors.greys04
                }}>
                    {`Created at ${getLocaltime(sequenceData?.createdAt)} | ${sequenceData?.sequenceSteps?.length} Sequences`}
                </p>
            </div>
        </div>
        
        <div className='Column w-100 justify-content-start' style={{flex:1,overflowY:'auto'}}>
            <div className='Row w-100' style={{
                marginBlock:16, 
                alignItems: 'stretch',
                height: 'auto',
            }}>
                <div className='w-50 pe-2' style={{}}>
                    <div className='Column justify-content-between align-items-start py-2 px-3' style={{
                        border:`1px solid ${colors.borderwhite}`, 
                        borderRadius:16, 
                        backgroundColor:colors.white01, 
                        height:'100%'
                    }}>
                        <p style={{fontSize:20,fontWeight:700}} className='mb-2'>
                            {`${[Number(analyticsData?.summary?.leadsCompleted)/Number(analyticsData?.summary?.numberOfRecipients).toFixed(2)]*100}`}
                            <span style={{fontSize:16,fontWeight:400}}>
                                {'% of campaign completed'}
                            </span>
                        </p>
                        <div className='w-100 my-2'>
                        <LinearProgress variant="determinate" value={Number(analyticsData?.summary?.leadsCompleted)/Number(analyticsData?.summary?.numberOfRecipients).toFixed(2)*100} />
                        </div>
                        <RowItem 
                            itemText={'Total no. of recipient'} 
                            itemNumber={analyticsData?.summary?.numberOfRecipients}
                        />
                        <RowItem 
                            itemText={'Leads yet to be started'} 
                            itemNumber={analyticsData?.summary?.leadsToStart}
                        />
                        <RowItem 
                            itemText={'Leads in progress'} 
                            itemNumber={analyticsData?.summary?.leadsInProgress}
                        />
                        <RowItem 
                            itemText={'Leads completed'} 
                            itemNumber={analyticsData?.summary?.leadsCompleted}
                        />
                        <RowItem 
                            itemText={'Blocked Leads'} 
                            itemNumber={analyticsData?.summary?.blocked}
                        />
                    </div>
                </div>
                <div className='w-50 ps-2' style={{}}>
                    <div className='Column justify-content-between align-items-start py-2 px-3' style={{
                        border:`1px solid ${colors.borderwhite}`, 
                        borderRadius:16, 
                        backgroundColor:colors.white01, 
                        height:'100%',
                    }}>
                        <p style={{fontSize:20,fontWeight:700}} className='mb-2'>
                            {`Lead Quality`}
                        </p>
                        <div className='w-100 my-2 Row justify-content-center' style={{height:200,position:'relative'}}>
                            <Doughnut data={data} options={options}/> 
                            <p style={{fontSize:30,fontWeight:600,position:'absolute'}}>
                                {`${(Number(analyticsData?.leadQuality?.reach)/(Number(analyticsData?.leadQuality?.reach)+Number(analyticsData?.leadQuality?.unsubs))).toFixed(2)*100}%`}
                            </p>
                        </div>
                        <div className='Row w-100 align-items-start'>
                            <ColumnItem 
                                itemNumber={analyticsData?.leadQuality?.reach} 
                                itemText={'Leads Reached'} 
                                itemColor={'#4653F3'}
                            />
                            <ColumnItem 
                                itemNumber={analyticsData?.leadQuality?.unsubs} 
                                itemText={'Leads Unsubscribed'} 
                                itemColor={'#9BA3FF'}
                            />
                            <ColumnItem 
                                itemNumber={analyticsData?.leadQuality?.bounced} 
                                itemText={'Leads Bounced'} 
                                itemColor={'#4653F3'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {analyticsData?.emailReachBreakup?.length>0 && 
            <GraphComponent 
                graphData={analyticsData?.emailReachBreakup}
            />
            }
            <div className='Column w-100 py-2 px-3' style={{
                border:`1px solid ${colors.borderwhite}`,
                marginBlock:16,
                borderRadius:16,
                backgroundColor:colors.white01,
            }}>
                <p style={{fontSize:20,fontWeight:700}} className='mb-2 w-100'>
                    {`Sequence Breakup`}
                </p>
                {analyticsData?.seqBreakup?.length > 0 && analyticsData?.seqBreakup.map((item,index)=>{
                    return(
                        <>
                        <div className='Row w-100 py-2 justify-content-around' style={{
                            borderBottom:`1px solid ${colors.borderwhite}`
                        }}>
                            <p style={{fontSize:18,fontWeight:600}}>
                                {`Step ${index + 1} `}
                            </p>
                            <div className='Column justify-content-start align-items-start'>
                                <p style={{fontSize:16,fontWeight:400}}>
                                    {item?.sent}<br/>
                                    <span style={{color:colors.black04}}>
                                        {'Sent'}
                                    </span>
                                </p>
                            </div>
                            <div className='Column justify-content-start align-items-start'>
                                <p style={{fontSize:16,fontWeight:400}}>
                                    {item?.opened}<br/>
                                    <span style={{color:colors.black04}}>
                                        {'Opened'}
                                    </span>
                                </p>
                            </div>
                            <div className='Column justify-content-start align-items-start'>
                                <p style={{fontSize:16,fontWeight:400}}>
                                    {item?.clicked}<br/>
                                    <span style={{color:colors.black04}}>
                                        {'Clicked'}
                                    </span>
                                </p>
                            </div>
                            <div className='Column justify-content-start align-items-start'>
                                <p style={{fontSize:16,fontWeight:400}}>
                                    {item?.replied}<br/>
                                    <span style={{color:colors.black04}}>
                                        {'Replied'}
                                    </span>
                                </p>
                            </div>
                            <div className='Column justify-content-start align-items-start'>
                                <p style={{fontSize:16,fontWeight:400}}>
                                    {item?.bounced}<br/>
                                    <span style={{color:colors.black04}}>
                                        {'Bounced'}
                                    </span>
                                </p>
                            </div>
                            <div className='Column justify-content-start align-items-start'>
                                <p style={{fontSize:16,fontWeight:400}}>
                                    {item?.spam}<br/>
                                    <span style={{color:colors.black04}}>
                                        {'Spam'}
                                    </span>
                                </p>
                            </div>
                        </div>
                        </>
                    )
                })}
            </div>
        </div>

    </div>
    </>
  )
}

export default SequenceAnalytics