import { Tooltip } from '@mui/material';
import React from 'react';
import images from '../../../Utils/images';

const CreditsTile = ({ onclickAction, balanceData, freetrial, excepted, hideAnalytics }) => {
  return (
    <>
      <div className="credits_container tile">
        <div className="credits_container__row">
          <h3>{'Available credits:'}</h3>
          {/* {(!excepted && !hideAnalytics)&& (
            <Tooltip title={'Download Report'}>
              <img
                src={images.Download_CSV}
                alt="download credit report"
                onClick={onclickAction}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
              />
            </Tooltip>
          )} */}
        </div>
        <div className="credits_container__divider"></div>
        <div className="credits_container__row">
          <p>{'Email'}</p>
          <p className="bold">{freetrial ? '0' : balanceData?.email ? balanceData?.email : '0'}</p>
        </div>
        <div className="credits_container__divider"></div>
        <div className="credits_container__row">
          <p>{'SMS'}</p>
          <p className="bold">{freetrial ? '0' : balanceData?.sms ? balanceData?.sms : '0'}</p>
        </div>
        <div className="credits_container__divider"></div>
        <div className="credits_container__row">
          <p>{'WhatsApp'}</p>
          <p className="bold">
            {freetrial ? '0' : balanceData?.whatsapp ? balanceData?.whatsapp : '0'}
          </p>
        </div>
      </div>
    </>
  );
};

export default CreditsTile;
