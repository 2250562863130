import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors';
import { TextField } from '@mui/material';
import ToggleButtons from './ToggleButton';
import images from '../../../Utils/images';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch';
import { InputGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';


const CFField = ({ index, field, updateField, duplicateField, deleteField, attributes }) => {

    const fieldType = [
        'text',
        'email',
        'phone'
    ];
    const attributeMap = {
        Name: 'name',
        Email: 'email',
        Phone: 'mobile'
    };

    const handleFieldNameChange = (event) => {
        const updatedField = { ...field, fieldName: event.target.value };
        updateField(index, updatedField);
    };

    const handleFieldTypeChange = (event) => {
        const updatedField = { ...field, fieldType: event.target.value };
        updateField(index, updatedField);
    };

    const handleMandatoryChange = (event) => {
        const updatedField = { ...field, mandatory: event.target.checked };
        updateField(index, updatedField);
    };

    const handleAttributeNameChange = (event) => {
        const updatedField = { ...field, attributeName: event.target.value };
        updateField(index, updatedField);
    };

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
            }}>
                {/* <div className='d-flex flex-row justify-content-between align-items-center w-100'>
            <h4 className='mb-2' style={{
                fontSize: 18,
                fontWeight:700,
            }}>
                {'Description'}
            </h4>
            <ToggleButtons 
                alignment={descriptionProps.alignment}
                handleChange={(value)=>{
                    const name = 'alignment'
                    console.log("dsfsdfsdf ",name,value)
                    updateDescriptionProps({ [name]: value });
                }}
            />
        </div> */}
                <div className='d-flex flex-column justify-content-stretch align-items-center w-100 h-auto'
                    style={{
                        paddingBlockEnd: 20,
                        marginBlockEnd: 20,
                        borderBottom: `1px solid ${colors.borderwhite}`
                    }}
                >
                    <TextField
                        name="fieldName"
                        value={field.fieldName}
                        onChange={handleFieldNameChange}
                        type='text'
                        size='small'
                        style={{
                            marginBlockStart: 8,
                            width: '100%',
                        }}
                        className='btncustom'
                    />
                    <InputGroup className="mb-1 mt-2" style={{ width: '100%', marginBlock: 2, }}>
                        <Form.Select
                            aria-label="Select Attribute"
                            defaultValue=""
                            value={field.attributeName}
                            style={{ width: '100%', marginBlock: 2, boxShadow: 'none' }}
                            onFocus={(e) => { e.target.style.border = `2px solid #0d6efd` }}
                            onBlur={(e) => e.target.style.border = `1px solid #ced4da`}
                            onChange={handleAttributeNameChange}
                        >
                            <option value="" disabled hidden>
                                Map field to attribute
                            </option>
                            {attributes?.map((attribute) => (
                                <option key={attribute} value={attribute}>
                                    {attribute}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
                        <img src={images.InfoCircle} style={{
                            height: 20,
                            width: 20,
                            objectFit: 'contain',
                            marginInlineEnd: 8,
                        }}></img>
                        <p>Responses for these field will be saved to mapped attribute</p>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <Select
                        name='fieldType'
                        value={field.fieldType}
                        onChange={handleFieldTypeChange}
                        style={{ marginBlock: 8, width: '30%' }}
                        size='small'
                    >
                        {fieldType.map((fieldType) => (
                            <MenuItem key={fieldType} value={fieldType}>
                                <div className='d-flex flex-row justify-content-start align-items-center w-100 gap-3'>
                                    {fieldType == 'text' ? (
                                        <img src={images.FFText} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                    ) : fieldType == 'email' ? (
                                        <img src={images.FFEmail} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                    ) : fieldType == 'phone' && (
                                        <img src={images.FFPhone} style={{ height: 18, width: 18, objectFit: 'contain', marginInlineEnd: 8 }}></img>
                                    )}
                                    {fieldType}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>

                    <div className='d-flex flex-row justify-content-end align-items-center'>
                        <div className='d-flex flex-row justify-content-center align-items-center' style={{ marginInline: 8 }}>
                            <p>
                                {'Mandatory field'}
                            </p>
                            <Switch
                                checked={field.mandatory}
                                onChange={handleMandatoryChange}
                                sx={{ color: colors.primary03 }}
                                size='small'
                            />
                        </div>
                        <img src={images.DesignDuplicate} style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            cursor: 'pointer',
                            marginInline: 6,
                        }}
                            onClick={duplicateField}
                        />
                        <img src={images.DeleteBlack} style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            cursor: 'pointer',
                            marginInline: 6,
                        }}
                            onClick={deleteField}
                        />

                    </div>
                </div>

                {/* <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font'}
                </p>
                <Select
                    name='font'
                    value={descriptionProps.font}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fonts.map((font) => (
                        <MenuItem key={font} value={font}>
                            {font}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Size'}
                </p>
                <TextField 
                    name="fontSize"
                    value={descriptionProps.fontSize}
                    onChange={handleChange}
                    type='number'
                    size='small'
                    style={{marginBlock:8,width:'100%'}}
                    className='btncustom'
                />
            </div>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Weight'}
                </p>
                <Select
                    name='fontWeigth'
                    value={descriptionProps.fontWeigth}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fontWeight.map((fontweight) => (
                        <MenuItem key={fontweight} value={fontweight}>
                            {fontweight}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div> */}
            </div>
        </>
    )
}

export default CFField