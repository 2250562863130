import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { V2EmailBuilderWorkflowList } from '../../../Services';
import CampaignReviewSend from './CampaignReviewSend';

const SequenceSidebar = ({ editNodeData, addNewNodeFunction, deleteNodeAction, setEditNodeData, saveDataAction, review, selectedLabel, nodes }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [templateList, setTemplateList] = useState([]);

    useEffect(() => {
        if(editNodeData?.type === 'sendEmailNode'){
            getDesignAPIcall();
        }
    }, [editNodeData]);

    const getDesignAPIcall = () => {
        let token = authtoken;
        V2EmailBuilderWorkflowList(token).then((res) => {
            if (res) {
                console.log('success response ===> ', res);
                setTemplateList(res?.data);
            } else {
                console.log('error response ===> ', res);
            }
        });
    };

    const ValidNodesCount = Number(nodes.filter((item,index)=> item?.type === 'sendEmailNode' || item?.type === 'writeEmailNode').length)

    

    return (
        <>
            <div
                className="Column justify-content-start"
                style={{
                    width: '35%',
                    height: '100%',
                    border: `1px solid ${colors.borderwhite}`,
                    backgroundColor: colors.white01,
                    paddingBlock: 20,
                    paddingInline: 24,
                }}
            >
                {review ? (
                    <>
                    <CampaignReviewSend 
                        selectedLabel={selectedLabel} 
                        validNodesCount={ValidNodesCount}
                    />
                    </>
                ):(
                    <>
                    <div
                        className="Row w-100 justify-content-between"
                        style={{ paddingBlockEnd: 20, borderBottom: `2px solid ${colors.borderwhite}` }}
                    >
                        {(editNodeData?.type === 'writeEmailNode' || editNodeData?.type === 'sendEmailNode' || editNodeData?.type === 'timeDelayNode') ? (
                            <>
                            <div className='Row justify-content-start'>
                                <img 
                                    src={editNodeData?.type !== 'timeDelayNode' ? images.SeqWENode : images.SeqTDNode} 
                                    style={{
                                        height:32,
                                        width:32,
                                        objectFit:'contain',
                                    }}
                                />
                                <h4 className='ms-2'>
                                    {editNodeData?.type === 'writeEmailNode' ? `Email follow up` : editNodeData?.type === 'sendEmailNode' ? `Send email template` : editNodeData?.type === 'timeDelayNode' && `Add time delay`}
                                </h4>
                            </div>
                            </>    
                        ) : (
                            <>
                            <h4>
                                {'Add a step'}
                            </h4>
                            </>
                        )}
                        
                        {editNodeData?.type !== 'addStepNode' && (
                            <>
                                <img
                                    src={images.DeleteBlack}
                                    style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }} 
                                    onClick={()=>{
                                        deleteNodeAction(editNodeData);
                                    }}
                                />
                            </>
                        )}
                    </div>
                    
                    <div className='Column justify-content-start w-100' style={{
                        flex:1,
                        overflowY: 'auto',
                    }}>
                        <div
                            className="Column justify-content-start w-100 px-1"
                            style={{
                                paddingBlock: 8,
                                // flex:1,
                                // overflowY:'auto',
                            }}
                        >
                            {editNodeData?.type === 'addStepNode' ? (
                                <>
                                    <div
                                        className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                        onClick={() => {
                                            addNewNodeFunction('writeEmailNode');
                                        }}
                                    >
                                        <img
                                            src={images.SeqWriteEmail}
                                            style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                        ></img>
                                        <p>{'Write an email'}</p>
                                    </div>
                                    <div
                                        className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                        onClick={() => {
                                            addNewNodeFunction('sendEmailNode');
                                        }}
                                    >
                                        <img
                                            src={images.SeqSendEmail}
                                            style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                        ></img>
                                        <p>{'Send email template'}</p>
                                    </div>
                                    <div
                                        className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                        onClick={() => {
                                            addNewNodeFunction('timeDelayNode');
                                        }}
                                    >
                                        <img
                                            src={images.SeqTimeDelay}
                                            style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                        ></img>
                                        <p>{'Time delay'}</p>
                                    </div>
                                </>
                            ) : editNodeData?.type === 'writeEmailNode' ? (
                                <>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Subject'}
                                    </Form.Label>
                                    <Form.Control
                                        type="text" 
                                        placeholder='Subject line'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={editNodeData?.data?.subject ?? ''}
                                        onChange={(e) => {
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    subject: e.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Body'}
                                    </Form.Label>
                                    <Form.Control 
                                        as={'textarea'}
                                        rows={10}
                                        type="text" 
                                        placeholder='Message'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={editNodeData?.data?.content ?? ''}
                                        onChange={(e) => {
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    content: e.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </InputGroup>
                                </>
                            ) : editNodeData?.type === 'sendEmailNode' ? (
                                <>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Subject'}
                                    </Form.Label>
                                    <Form.Control
                                        type="text" 
                                        placeholder='Subject line'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={editNodeData?.data?.subject ?? ''}
                                        onChange={(e) => {
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    subject: e.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Template'}
                                    </Form.Label>
                                    <Form.Select 
                                        type="text" 
                                        placeholder='Select template'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8,fontSize:14,fontWeight:400 }}
                                        value={editNodeData?.data?.emailBuilderId ?? ''}
                                        onChange={(e) => {
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    emailBuilderId: e.target.value,
                                                }
                                            })
                                        }}
                                    >
                                        <option
                                            style={{ width: '100%', marginBlock: 4, borderRadius: 8}}
                                            key="blankchoice"
                                            hidden
                                            value
                                        >
                                            {'Select email template'}
                                        </option>
                                        {templateList && templateList.map((item,index)=>{
                                            return(
                                                <option
                                                    style={{width:'100%',marginBlock:4,borderRadius:8}} 
                                                    value={item?._id} 
                                                    key={`emailtemplate${index}`}
                                                >
                                                    {item?.emailName}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </InputGroup>
                                </>
                            ) : editNodeData?.type === 'timeDelayNode' && (
                                <>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Days'}
                                    </Form.Label>
                                    <Form.Control
                                        type="number" 
                                        placeholder='days'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={editNodeData?.data?.days ?? ''}
                                        onChange={(e) => {
                                            const clampedValue = Math.max(0, Math.min(15, Number(e.target.value)))
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    days: clampedValue,
                                                }
                                            })
                                        }}
                                        min={0} 
                                        max={15} 
                                    />
                                </InputGroup>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Hours'}
                                    </Form.Label>
                                    <Form.Control
                                        type="number" 
                                        placeholder='hours'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={editNodeData?.data?.hours ?? ''}
                                        onChange={(e) => {
                                            const clampedValue = Math.max(0, Math.min(24, Number(e.target.value)))
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    hours: clampedValue,
                                                }
                                            })
                                        }}
                                        min={0} 
                                        max={24}
                                    />
                                </InputGroup>
                                <InputGroup className="my-2" style={{width:'100%'}}>
                                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                        {'Minutes'}
                                    </Form.Label>
                                    <Form.Control
                                        type="number" 
                                        placeholder='minutes'
                                        className="w-100 btncustom"
                                        style={{ borderRadius: 8 }}
                                        value={editNodeData?.data?.minutes ?? ''}
                                        onChange={(e) => {
                                            const intvalue = parseInt(e.target.value)
                                            const clampedValue = Math.max(0, Math.min(60, Number(intvalue)))
                                            setEditNodeData({
                                                ...editNodeData,
                                                data : {
                                                    ...editNodeData.data,
                                                    minutes: clampedValue,
                                                }
                                            })
                                        }}
                                        min={0}
                                        max={60}
                                    />
                                </InputGroup>
                                </>
                            )}
                        </div>
                    
                        {editNodeData?.type !== 'addStepNode' && 
                        <>
                        <div className='Row justify-content-end w-100 pe-1'>
                        <CheerioButton 
                            className="btncustom"
                            borderStyle={{
                                paddingInline:24,
                                borderColor: colors.primary03,
                            }}
                            textStyle={{ color: colors.white01, fontSize: 16, fontWeight: 600 }}
                            btnText={'Save'}
                            backColor={colors.primary03}
                            onclick={()=>{saveDataAction()}}
                        />
                        </div>
                        </>
                        }
                    </div>
                    
                    
                    </>
                )}
                   
            </div>
        </>
    );
};

export default SequenceSidebar;
