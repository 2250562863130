import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactFlow, { addEdge, Background, Controls, getConnectedEdges, getIncomers, getOutgoers, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from 'reactflow'
import 'reactflow/dist/style.css';
import AddaStepNode from '../Nodes/AddaStepNode';
import { v4 as uuidv4 } from 'uuid';
import WriteEmailNode from '../Nodes/WriteEmailNode';
import TimeDelayNode from '../Nodes/TimeDelayNode';
import SendEmailNode from '../Nodes/SendEmailNode';
import SequenceSidebar from './SequenceSidebar';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { toast } from 'react-toastify';

const getId = () => uuidv4();

const SequenceFlow = ({ 
  setActiveStep, 
  nodes, 
  setNodes, 
  onNodesChange, 
  edges, 
  setEdges, 
  onEdgesChange,
  review=false,
  selectedLabel, 
  onclickruntestaction, 
  onclickstartsequenceaction,
}) => {

    // const [nodes, setNodes, onNodesChange] = useNodesState([
    //     {
    //         id: getId(),
    //         type: 'addStepNode',
    //         position: { x:0, y:0}
    //     }
    // ])
    // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    // const { fitView } = useReactFlow();
    // const { fitView } = useReactFlow();
    const [editNodeData, setEditNodeData] = useState();
    const [showSidebar, setShowSidebar] = useState(false);


    const nodeTypes = useMemo(()=>({
        addStepNode: AddaStepNode, 
        writeEmailNode: WriteEmailNode,
        sendEmailNode: SendEmailNode,
        timeDelayNode: TimeDelayNode,
    }),[])

    const onloadNodeData = (node) => {
        setEditNodeData(node);
    }

    const onNodeClick = useCallback((event, node) => {
        console.log("yesyesyes ",node);
        setEditNodeData(node);
        setShowSidebar(true);
    })

    const onNodesDelete = useCallback(
        (deleted) => {
          setEdges(
            deleted.reduce((acc, node) => {
              const incomers = getIncomers(node, nodes, edges);
              const outgoers = getOutgoers(node, nodes, edges);
              const connectedEdges = getConnectedEdges([node], edges);
     
              const remainingEdges = acc.filter(
                (edge) => !connectedEdges.includes(edge),
              );
     
              const createdEdges = incomers.flatMap(({ id: source }) =>
                outgoers.map(({ id: target }) => ({
                  id: `${source}->${target}`,
                  source,
                  target,
                })),
              );
     
              return [...remainingEdges, ...createdEdges];
            }, edges),
          );
        },
        [nodes, edges],
      );

      const onNodeDeleteClick = useCallback(
        (nodeToDelete) => {
            console.log("123465 ",nodeToDelete)
          setEdges((currentEdges) => {
            const incomers = getIncomers(nodeToDelete, nodes, currentEdges);
            const outgoers = getOutgoers(nodeToDelete, nodes, currentEdges);
            const connectedEdges = getConnectedEdges([nodeToDelete], currentEdges);
      
            const remainingEdges = currentEdges.filter(
              (edge) => !connectedEdges.includes(edge)
            );
      
            const createdEdges = incomers.flatMap(({ id: source }) =>
              outgoers.map(({ id: target }) => ({
                id: `${source}->${target}`,
                source,
                target,
              }))
            );
      
            return [...remainingEdges, ...createdEdges];
          });
          setNodes((nds)=>[...nds.filter((node)=>node.id !== nodeToDelete?.id)])
          setEditNodeData(nodes[nodes.length-1])
        },
        [nodes]
      );
      

    const AddNewNodeAbove = (type) => { 
        
        console.log("1111111111 ",type)
        const currentAddNode = nodes.find((node)=> node?.type === 'addStepNode')
        const previousNode = nodes?.length >= 2 ? nodes[nodes?.length - 2] : null;
        if(!currentAddNode) return;
        const offset = 100;
        const nodeWidths = {
          "timeDelayNode": 250,
          "sendEmailNode": 280,
          "writeEmailNode": 250,
          // Add more node types as needed
        };

        const newNodeWidth = nodeWidths[type] || 150;

        const newNode = {
            id: getId(),
            type: type,
            position: {
              x: currentAddNode.position.x + (currentAddNode.width/2) - (newNodeWidth / 2), // Same x-coordinate
              y: currentAddNode.position.y, // Above the "Add a New Node"
            },
        };

        const updatedAddNode = {
            ...currentAddNode,
            position: {
              x: currentAddNode.position.x,
              y: currentAddNode.position.y + offset,
            },
        };

        setEditNodeData(newNode);

        setNodes((nds)=>[...nds.filter((node)=>node.type !== 'addStepNode'), newNode, updatedAddNode])

        setEdges((eds)=>{
            let prevEdge = {id:`${previousNode?.id}->${newNode?.id}`, source:previousNode?.id, target:newNode?.id, deletable:false}
            let newEdge = {id:`${newNode?.id}->${updatedAddNode?.id}`, source:newNode?.id, target:updatedAddNode?.id, deletable:false}
            if(previousNode?.id){
                return [...eds.filter((edge) => edge.target !== updatedAddNode?.id),prevEdge,newEdge]
            }else{
                return [...eds,newEdge]
            }
        })        
    }

    const saveDataAction = () => {
        setNodes((nds)=> nds.map(item => item?.id === editNodeData?.id ? {...item, data: editNodeData?.data} : item))
        toast.success('Node data saved successfully',{position:'top-right',autoClose:1000});
        setTimeout(() => {
          setShowSidebar(false);
        }, 1000);
    }

  return (
    <>
    {(showSidebar || review) && 
      <SequenceSidebar 
          editNodeData={editNodeData} 
          addNewNodeFunction={AddNewNodeAbove} 
          setEditNodeData={setEditNodeData}
          deleteNodeAction={onNodeDeleteClick} 
          saveDataAction={saveDataAction} 
          selectedLabel={selectedLabel} 
          review={review} 
          nodes={nodes}
      />
    }
    <div id='SequenceFlowContainer' 
        className='Column justify-content-between' 
        style={{
            height:'100%',
            flex: 1,
        }}
    >
      <ReactFlowProvider>
        <ReactFlow 
            nodes={nodes} 
            onNodesChange={onNodesChange}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            onNodeClick={onNodeClick}
            onNodesDelete={onNodesDelete}
            edges={edges} 
            onEdgesChange={onEdgesChange}
            fitView 
            maxZoom={1}
            minZoom={1}
            onInit={()=>{onloadNodeData(nodes[0])}}
            onEdgesDelete={()=>{}}
        >
            <Background variant='dots' gap={12} size={1} />
            <Controls />
        </ReactFlow>
      </ReactFlowProvider>
        
        
        <div className='Row justify-content-end w-100' style={{
            paddingBlock:20,
            paddingInline:32,
            borderTop:`1px solid ${colors.borderwhite}`, 
            backgroundColor:colors.white01,
        }}>
          {review ? (
            <>
            <CheerioButton 
                btnText={'Run a test'}
                textStyle={{color:colors.black}}
                backColor={colors.white01}
                borderStyle={{borderColor:colors.black,marginInline:24}} 
                onclick={()=>{onclickruntestaction()}}
            />
            <CheerioButton 
                btnText={'Start sequence'}
                textStyle={{color:colors.white01}}
                backColor={colors.primary03}
                borderStyle={{borderColor:colors.primary03}} 
                onclick={()=>{onclickstartsequenceaction()}}
            />
            </>
          ):(
            <>
            <CheerioButton 
                btnText={'Save & Next'}
                textStyle={{color:colors.white01}}
                backColor={colors.primary03}
                borderStyle={{borderColor:colors.primary03}} 
                onclick={()=>{setActiveStep(2)}}
            />
            </>
          )}
            
        </div>
        
        
    </div>
         
    </>
  )
}

export default SequenceFlow