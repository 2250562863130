import { identify, Identify, init, track, setUserId } from '@amplitude/analytics-browser';

const API_KEY = process.env?.REACT_APP_API_KEY;

if (API_KEY) {
  init(API_KEY, { logLevel: 'WARN' }); 
} else {
  console.warn('Amplitude API Key is missing. Initialization skipped.');
}

export const setuserid = (id) => {
  if (id) {
    setUserId(id);
  } else {
    console.warn('Invalid User ID');
  }
};

export const eventsend = (eventTxt, eventProps = {}) => {
  try {
    const event = eventTxt;
    API_KEY && track(event, eventProps);
  } catch (error) {
    console.error(`Amplitude Event Tracking for event ${eventTxt} - Error:`, error);
  }
};

export const userproperties = (key, value) => {
  if (key && value !== undefined && value !== null) {
    const identifyObj = new Identify();
    identifyObj.set(key, value);
    identify(identifyObj);
  } else {
    console.warn('Invalid user property key or value');
  }
};