import { Modal } from 'react-bootstrap';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import colors from '../../Utils/colors';
import { workflowSync } from '../../Services/';
import {ValidateEmail} from '../../../src/Pages/Chatbot'
import SpinnerLoader from '../../Components/SpinnerLoader';

const SyncWorkflow = ({ show, setShow }) => {
    const authtoken = useSelector((state) => state.main.auth_token);

    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false); 

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSyncWorkflow = async () => {
        setLoading(true);
        if (!isValidEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        try {
            let data = {
                email: email,
            };
            const response = await workflowSync(authtoken, data )
            setShow(false);
            if (response.flag) {
                toast.success('Workflows synced successfully!');
                setShow(false)
            } else {
                toast.error(response.message || 'Failed to sync workflows');
                
            }
        } catch (error) {
            toast.error('An error occurred while syncing workflows');
        }
        finally {
            setLoading(false); 
            setShow(false);
        }
    };

    return (
        <Modal
            show={show}
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="my-modal"
            style={{
                width: '30vw',
                left: '37%',
                top: '24%',
            }}
        >
            {loading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                    <SpinnerLoader />
                </div>
            ) : (
            <Modal.Body className="email_modal_body">
                <div className="d-flex flex-column" style={{ padding: '4px' }}>
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginTop: '-4px' }}
                    >
                        <p
                            style={{
                                fontWeight: '700',
                                fontSize: '24px',
                                margin: 0,
                            }}
                        >
                            Sync Workflows
                        </p>
                        <img
                            src={images.CMClose}
                            onClick={() => setShow(false)}
                            style={{
                                cursor: 'pointer',
                                height: 22,
                                width: 22,
                            }}
                            alt="Close"
                        />
                    </div>
                    <hr />

                    <p
                        style={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#666666',
                            paddingRight: '3%',
                            paddingBottom: '4%',
                            marginTop: '2px',
                        }}
                    >
                        Syncing the workflows will create duplicate workflows in the selected Cheerio account
                    </p>

                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Email"
                        className="form-control shadow-none"
                        style={{
                            width: '96%',
                            padding: '10px',
                            marginBottom: '10px',
                            borderRadius: '8px',
                            borderColor: '#ddd',
                        }}
                    />

                    <div style={{ display: 'flex', marginBottom: '1.7%' }}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleChange}
                            style={{ width: '18px', height: '18px' }}
                        />
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#666666',
                                paddingRight: '1%',
                                paddingBottom: '4%',
                                marginTop: '-3px',
                                marginLeft: '4px',
                            }}
                        >
                            I understand that this is an irreversible process and Yes I want to continue
                        </p>
                    </div>
                    <CheerioButton
                        className="button"
                        borderStyle={{
                            height: '44px',
                            borderRadius: '8px',
                            border: 'none',
                            width: '100%',
                        }}
                        textStyle={{
                            color: 'white',
                            fontSize: 16,
                            fontWeight: 600,
                        }}
                        btnText={'Sync Workflows'}
                        backColor={'#8E53F7'}
                        disabled={!isChecked || !email}
                        onclick={handleSyncWorkflow} 
                    />
                </div>
            </Modal.Body>
             )}
        </Modal>
    );
};


export default SyncWorkflow;