import React, { useEffect, useState } from 'react'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import { partnerCheckOTP, partnerResetPassword, partnerSendOTP } from '../../../Services';
import { toast } from 'react-toastify';

const ResellerForgotPassword = ({ setIsModalOpen }) => {

    const [facebookId, setFacebookId] = useState('');
    const [OTPValue, setOTPValue] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [OTPsent, setOTPsent] = useState(false);
    const [OTPcheck, setOTPcheck] = useState(false);
    const [partnerData, setPartnerData] = useState([]);
    const [resetSuccess, setResetSuccess] = useState(false);

    const sendOTPAction = () => {
        let data = {
            email: facebookId,
        };
        partnerSendOTP(data).then((res) => {
            if (res?.flag) {
                setOTPsent(true)
            } else {
                toast.error('Partner user with this email does not exist', { position: 'top-right', })
            }
        })
    }

    const checkOTPAction = () => {
        let data = {
            email: facebookId,
            otp: OTPValue,
        };
        partnerCheckOTP(data).then((res) => {
            if (res?.flag) {
                setPartnerData(res?.data);
                setOTPcheck(true);
            } else {
                toast.error('Incorrect OTP entered', { position: 'top-right', })
            }
        })
    }

    const resetPasswordAction = () => {
        let token = partnerData?.token;
        let data = {
            passwordOne: passwordOne,
            passwordTwo: passwordTwo,
        }
        try{
            partnerResetPassword(token, data).then((res) => {
                if (res?.flag) {
                    setResetSuccess(true)
                } else {
                    toast.error(res?.message, { position: 'top-right' })
                }
            })
        }catch(err){
            toast.error(err?.message, {position:'top-right'});
        }
    }

    function ValidatePassword(passwordOne, passwordTwo) {
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}/.test(passwordOne) && (passwordOne === passwordTwo)) {
            return true;
        } else if (passwordOne !== passwordTwo) {
            toast.error("The passwords entered do not match", { position: 'top-right', });
            return false;
        } else {
            toast(
                'You need to enter a password of minimum 8 characters with one uppercase and lowercase letter, one number, and one special character',
                { postion: 'top-center' }
            );
            return false;
        }

    }

    useEffect(() => {
        if (resetSuccess) {
            setTimeout(() => {
                setIsModalOpen(false);
            }, 2000);
        }
    }, [resetSuccess])

    return (
        <>
            <div className='DarkBg' onClick={() => { setIsModalOpen(false) }}>
                <div className='Center'>
                    <div className='reseller_password_container'
                        onClick={(e) => { e.stopPropagation(); }}
                    >
                        <div className='reseller_password_container__header'>
                            <h3>
                                {'Forgot Password'}
                            </h3>
                            <img src={images.CMClose}
                                style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                onClick={() => { setIsModalOpen(false) }}
                            ></img>
                        </div>
                        <div className='reseller_password_container__body'>
                            {resetSuccess ? (
                                <>
                                    <div className='reset_success_screen'>
                                        <img src={images.CATSuccess}
                                            style={{ height: 150, width: 150, objectFit: 'contain' }}></img>
                                        <p>
                                            {'Password successfully reset, redirecting you to login screen'}
                                        </p>
                                    </div>
                                </>
                            ) : OTPcheck ? (
                                <>
                                    <InputGroup className="my-3">
                                        <Form.Label className='form_heading_style'>
                                            {'Enter New Password'}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter new password here"
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8 }}
                                            value={passwordOne}
                                            onChange={(e) => {
                                                setPasswordOne(e.target.value);
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="my-3">
                                        <Form.Label className='form_heading_style'>
                                            {'Re-enter New Password'}
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Re-enter new password here"
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8 }}
                                            value={passwordTwo}
                                            onChange={(e) => {
                                                setPasswordTwo(e.target.value);
                                            }}
                                        />
                                    </InputGroup>
                                </>
                            ) : (
                                <>
                                    <InputGroup className="my-3">
                                        <Form.Label className='form_heading_style'>
                                            {'Email'}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter here"
                                            className="w-100 btncustom"
                                            style={{ borderRadius: 8 }}
                                            value={facebookId}
                                            onChange={(e) => {
                                                setFacebookId(e.target.value);
                                            }}
                                        />
                                    </InputGroup>
                                    {OTPsent && (
                                        <>
                                            <InputGroup className="my-3">
                                                <Form.Label className='form_heading_style'>
                                                    {'Enter OTP'}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter OTP here"
                                                    className="w-100 btncustom"
                                                    style={{ borderRadius: 8 }}
                                                    value={OTPValue}
                                                    onChange={(e) => {
                                                        setOTPValue(e.target.value);
                                                    }}
                                                />
                                            </InputGroup>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <CheerioButton
                            borderStyle={{
                                borderColor: colors.black,
                                width: '100%',
                                paddingBlock: 10,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
                            btnText={OTPcheck ? 'Reset Password' : OTPsent ? 'Verify OTP' : 'Send OTP'}
                            backColor={colors.black}
                            disabled={((facebookId && !OTPsent) || (facebookId && OTPValue && OTPsent && !OTPcheck) || (passwordOne && passwordTwo && OTPcheck)) ? false : true}
                            onclick={() => {
                                if (OTPcheck) {
                                    if (ValidatePassword(passwordOne, passwordTwo)) {
                                        resetPasswordAction()
                                    }
                                } else if (OTPsent) {
                                    checkOTPAction();
                                } else {
                                    sendOTPAction();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResellerForgotPassword