import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';

const RunTestModal = ({
    setIsModalOpen, 
    testEmail,
    setTestEmail, 
    onclickruntestaction
}) => {
  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center' style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
            <div className='Column' style={{
                justifyContent:'flex-start',
                width: 600,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:40,
                paddingBlock:24,
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:20,
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'Run a test'}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}} 
                        onClick={()=>{setIsModalOpen(false)}}
                    >
                    </img>
                </div>
                <InputGroup className="my-2" style={{width:'100%'}}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                        {'Email ID'}
                    </Form.Label>
                    <Form.Control
                        type="text" 
                        placeholder='Enter test email id'
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={testEmail}
                        onChange={(e) => {
                            setTestEmail(e.target.value)
                        }}
                    />
                </InputGroup>
                <CheerioButton 
                    btnText={'Send a test message'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,marginBlockStart:24,width:'100%'}} 
                    onclick={()=>{onclickruntestaction()}} 
                    disabled={testEmail ? false : true}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default RunTestModal