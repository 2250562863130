import React, { useEffect, useState, useRef } from 'react';
import './styles.css';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import { useDispatch, useSelector } from 'react-redux';
import { getImagePath } from '../../Utils';
import SidemenuNew from './Components/SidemenuNew';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ImportContactsNew from '../ImportContactsNew';
import ProfilePopup from './Components/ProfilePopup';
import ICNewModal from '../ImportContactsNew/Components/ICNewModal';
import AutotopupSuccesModel from './Components/AutotopUpSuccesModel';
import RechargeAfterAutoTopUpsetModal from './Components/RechargeAfterAutoTopUpSet';
import {
  AutoSubmitTemplates,
  EmbeddedCheckWAStatus,
  contactlabellistAPI,
  getUserApi,
  loginADMIN,
  userSignupV2Api,
  getProjectQualityStatus,
  getAllSenderId,
  V2SingleGetAgent,
  contactlabellistWithoutCountAPI,
  checkDashboardEvents,
  waStatus,
  switchToPartner,
  trackPage,
  trackLink,
  trackEvent,
  getAllNotifications,
  getAllUnreadNotifications,
  getsetupautorechargedetails,
} from '../../Services';
import FreeTrialModal from '../../Components/Modal/FreeTrialModal';
import SetupWA from '../../Components/Modal/SetupWA';
import SetupSMS from '../../Components/Modal/SetupSMS';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AnnouncementsNew from '../AnnouncementsNew';
import CreatorAnalyticsNew from '../CreatorAnalyticsNew';
import AnnNewModal from '../AnnouncementsNew/Components/AnnNewModal';
import WhatsAppReplies from '../WhatsAppReplies';
import Banner from './Components/Banner';
import WATemplate from '../../Components/Modal/WATemplate';
import ShopifyStore from '../ShopifyStore';
import SetupWebhook from '../../Components/Modal/SetupWebhook';
import Header from './Components/Header';
import Helper from '../../Utils/Helper';
// import SocketConnect from '../../Components/Socket/SocketConnection';
import TimeTriggers from '../TimeTriggers';
import TimeTriggerSetup from '../TimeTriggerSetup';

import styles from './styles.module.css';
import AnnNewComp from '../AnnouncementsNew/Components/AnnNewComp';
import TemplateModal from '../../Components/Modal/TemplateModal';
import ChatBot from '../Chatbot';
import DownloadCredit from '../../Components/Modal/DownloadCredit';
import Templates from '../Templates';
import EventEmitter from '../../Utils/EventEmitter';
import FreeTrialPlans from '../Auth/components/FreeTrialPlans';
import DeploymentBanner from './Components/DeploymentBanner';
import WARefreshModal from '../../Components/Modal/WARefreshModal';
import EmbeddedSignUp from './Components/EmbeddedSignUp';
import EmbeddedModal from './Components/EmbeddedModal';
import CreateTemplate from '../CreateTemplate';
import EmbeddedStatusModal from './Components/EmbeddedStatusModal';
import { SenderIdModal } from './Components/SenderIdModal';
import { ManageSender } from '../ManageSender';

import EmailBuilder from '../EmailBuilder';
import { toast } from 'react-toastify';
import CustomTemplateStart from '../CustomTemplate/CustomTemplate';
import MyTemplates from '../Templates/Components/MyTemplates';
import ExploreTemplates2 from '../Templates/Components/ExploreTemplates2';
import ContactModule from '../ContactModule';
import { googleLogout } from '@react-oauth/google';
import AddPeopleModal from '../ManageProfile/Components/AddPeopleModal';
import { Broadcast } from './Components/Broadcast';
import WooCommerce from '../WooCommerce';
import METAEmbeddedSignUp from './Components/METAEmbeddedSignUp';
import { Tooltip } from '@mui/material';
import { NewLoader } from '../../Components/SpinnerLoader';
import QRTile from './Components/QRTile';
import QRModal from './Components/QRModal';
import WorkFlow from '../WorkFlow';
import WorkflowTabels from '../WorkflowTabels';
import { eventsend } from '../../Config/analyticsFunctions';
import CreditsTile from './Components/CreditsTile';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordianItems from './Components/AccordianItems';
import ReactPlayer from 'react-player';
import BroadcastNew from './Components/BroadcastNew';
import PremiumTile from './Components/PremiumTile';
import AgentPermModal from './Components/AgentPermModal';
import { BiBarChartAlt2 } from 'react-icons/bi';
import WAPreSetupModal from './Components/WAPreSetupModal';
import SMSDLTModal from './Components/SMSDLTModal';
import { GptComponent } from './Components/GptComponent';
import { MessengerReplies } from '../MessengerReplies/MessengerReplies';
import EmailBuild from '../Templates/Components/EmailBuild';
import InstagramReplies from '../InstagramReplies';
import GlobalAnalytics from '../GlobalAnalytics';
import { DiscoverPlatforms } from '../Integrations/DiscoverPlatforms';
import ManageIntegrations from '../Integrations/ManageIntegrations';
import { Beacon } from '@beacon.li/bar';
import { IoIosSearch } from 'react-icons/io';
import { RxCrossCircled } from 'react-icons/rx';
import Keywords from '../Keywords';
import AgentAnalytics from '../AgentAnalytics';
import { MdOutlineOpenInNew } from 'react-icons/md';
import AddTopUpModal from './Components/AddTopUpModal';
import HistoryModal from './Components/HistoryModal';
import CheerioWidget from './Components/CheerioWidget';
import ReferralModal from './Components/ReferralModal';
import ReferralBanner from './Components/ReferralBanner';
import AppsumoWelcomeModal from './Components/AppsumoWelcomeModal';
import PreReqWASetup from './Components/PreReqWASetup';
import { trackPages, handleTrackEvent, handleTrackLinks } from '../../Utils/brevo';
import WarningModal from './Components/WarningModal';
import AppSumoReviewModal from './Components/AppsumoReviewModal';
import { CodPrepaidFlow } from '../ShopifyStore/Components/CodPrepaidFlow';
import BounceWarningModal from './Components/BounceWarningModal';
import { Catalog } from '../WhatsappCommerce/Catalog';
import NotificationModal from './Components/NotificationModal';
import { ContactMapping } from '../ContactModule/ContactMapping/ContactMapping';
import EmbeddableForms from '../EmbeddableForms';
import { DefaultCurrencyModal } from './Components/DefaultCurrencyModal';
import AddINRTopUpModal from './Components/AddINRTopupModal';
import PremiumModal from './Components/PremiumModal';
import AutomaticRechargeModal from './Components/AutomaticRecharge';
import OneTimeRechargeSuccessmodel from './Components/OneTimeRechargeSuccessmodel'
import ExcelUpload from '../ExcelUpload';
import VendorList from '../VendorList';
import Sequences from '../Sequences';
import ManageIntegrationsPage from '../Integrations/ManageIntegrationsPage'
const CreatorNew = () => {
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profilePopup, setProfilePopup] = useState(false);
  const [openICModal, setOpenICModal] = useState(false);
  const [openWATemplate, setOpenWATemplate] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [isFTMOpen, setIsFTMOpen] = useState(false);
  const [isFTPOpen, setIsFTPOpen] = useState(false);
  const [isWAOpen, setIsWAOpen] = useState(false);
  const [isWebhookOpen, setIsWebhookOpen] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isSMSOpen, setIsSMSOpen] = useState(false);
  const [isWARefreshOpen, setIsWARefreshOpen] = useState(false);
  const [isEmbeddedModalOpen, setIsEmbeddedModalOpen] = useState(false);
  const [isAddTopUpModalOpen, setIsAddTopUpModalOpen] = useState(false);
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] = useState(false);
  const [isonetimerechargeSucceesmodelopen, setIsonetimerechargeSucceesmodelopen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [openANModal, setOpenANModal] = useState(false);
  const [ANMEMailBuilder, setANMEmailBuilder] = useState(false);
  const [uploadHTMLHome, setUploadHTMLHome] = useState(false);
  const user_pic = useSelector((state) => state.main.user_pic);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const tempFBToken = useSelector((state) => state.main.tempFBToken);
  const username = useSelector((state) => state.main.username);
  const [labelList, setLabelList] = useState([]);
  const [sidemenuCollapse, setSidemenuCollapse] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [proceedtoTemplate, setProceedtoTemplate] = useState(false);
  const [isDownloadCreditOpen, setIsDownloadCreditOpen] = useState(false);
  const [FTPSuccess, setFTPSuccess] = useState(false);
  const [EmbeddedLink, setEmbeddedLink] = useState('');
  const [EmbeddedOpen, setEmbeddedOpen] = useState(false);
  const [WAStatus, setWAStatus] = useState(false);
  const [waQualityStatus, setWaQualityStatus] = useState(null);
  const [openSenderIdModal, setOpenSenderIdModal] = useState(false);
  const [senderId, setSenderId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isAPModalOpen, setIsAPModalOpen] = useState(false);
  const [isAgentPermModalOpen, setIsAgentPermModalOpen] = useState(false);
  const [isWAPresetupModalOpen, setIsWAPresetupModalOpen] = useState(false);
  const [count, setCount] = useState(0);

  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [DashboardEvent, setDashboardEvent] = useState([]);

  const [isSMSDLTModalOpen, setIsSMSDLTModalOpen] = useState(false);
  const [showGpt, setShowGpt] = useState(false);
  const [waStatusData, setWaStatusData] = useState();
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
  const [showReferralBanner, setShowReferralBanner] = useState(Helper.ReferralBanner);
  const [sumowelcome, setSumoWelcome] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [PRWAModalOpen, setPRWAModalOpen] = useState(false);
  let walletBalance = localdata?.walletBalance ? Number(localdata.walletBalance.toFixed(3)) : 0;
  const walletCurrency = localdata?.walletCurrency ?? null;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isBounceModalOpen, setIsBounceModalOpen] = useState(true);
  const [showCurrencyDefaultModal, setShowDefaultCurrencyModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [rechargeafterAutotopupsetModel, setrechargeafterAutotopupsetModel] = useState(false);
  const [autorechargdata, setautorechargdata] = useState([]);

  const hideAnalytics = 
  localdata?._id === "671ce3adf954d69eaef89be1" || 
  localdata?._id === "671cdedcf954d69eaef494cf" || 
  localdata?._id === "671ccb67f954d69eaeeba49f" || 
  localdata?._id === "6719309f3beb1c2c7b154326" ||
  localdata?._id === "66d94723e94b89869e2c46af" ||
  localdata?._id === "67319ae99fb52a43f93b45cb" ||
  localdata?._id === "672f45779fb52a43f998cfa7"; 

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleBounceModalClose = () => {
    setIsBounceModalOpen(false);
  };

  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const notificationIconRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all');
  const [readStatus, setReadStatus] = useState(false);

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      if (selectedTab === 'all') {
        const res = await getAllNotifications(authtoken);
        if (res?.flag) {
          setAllNotifications(res?.data);
          const read = res?.data?.some((notification) => notification?.isRead === false);
          setReadStatus(read);
        }
      } else {
        const res = await getAllUnreadNotifications(authtoken);
        if (res?.flag) {
          setUnreadNotifications(res?.data);
        }
      }
    } catch (err) {
      console.log('Error fetching notifications', err);
    } finally {
      setLoading(false);
    }
  };
  const fetchautorechargedetails = async () => {
    try {
      const res = await getsetupautorechargedetails(authtoken);
      if (res?.flag && res?.data) {
        setautorechargdata(res.data);
      }
    } catch (err) {
      console.log('Error fetching notifications', err);
    }
  };
  useEffect(() => {
    fetchautorechargedetails();
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [selectedTab]);

  const [showreviewModal, setShowreviewModal] = useState(false);
  // useEffect(() => {
  //   //adminLogin();
  //   !Helper.isSocketConnect && SocketConnect.socketDataEvent('addPerson', localdata?._id);
  //   console.log('Helper is socket connected ', Helper.isSocketConnect);
  // }, []);
  const MobileWidth = window.innerWidth < 700 ? true : false;
  useEffect(() => {
    eventsend('creator_home_pageview_web');
    checkDashboardAPICall();

    if (MobileWidth) {
      setSidemenuCollapse(true);
    }
  }, [isSMSDLTModalOpen]);

  const exceptUserList = ['angel777.kanan@gmail.com','app.angelpro2@gmail.com'];
  // const exceptUserList = [''];

  const excepted = exceptUserList.includes(localdata?.email);

  const MetaEmbeddedUser = localdata?._id === '651d174fc961206e54c77ed3';
  // const MetaEmbeddedUser = localdata?._id === '651d174fc961206e54c77ed3' || localdata?._id === '6489675b4f598e055ed74e34';

  const checkDashboardAPICall = () => {
    let token = authtoken;
    checkDashboardEvents(token).then((res) => {
      if (res?.flag) {
        setDashboardEvent(res?.data);
      } else {
        console.log('error ', res);
      }
    });
  };

  const waStatusApiCall = () => {
    let token = authtoken;
    waStatus(token).then((res) => {
      if (res?.flag) {
        setWaStatusData(res?.data);
      } else {
        console.log('error ', res);
      }
    });
  };

  useEffect(() => {
    if (DashboardEvent) {
      console.log('dddddd ', DashboardEvent);
      console.log('dddddd ', DashboardEvent?.WhatsApp);
    }
  }, [DashboardEvent]);

  const signupV2status = () => {
    console.log('signupV2status');
    let data = {
      facebook_id: localdata?.facebook_id,
      is_v2_user: 'true',
    };
    let token = authtoken;
    userSignupV2Api(data, token).then((res) => {
      if (res?.flag) {
        console.log('v2 status updated successfully', res);
      } else {
        console.log('v2 status update failed', res);
      }
    });
    Helper.EmbeddedAI = false;
  };

  useEffect(() => {
    const login_track = localStorage.getItem('login_event');

    if (login_track === 'true' && localdata) {
      handleTrackEvent('dashboard_login_event', localdata);
      localStorage.setItem('login_event', 'false');
    }
  }, [localdata]);

  useEffect(() => {
    const shopName = localStorage.getItem('shopifyURL');
    setTimeout(() => {
      if (shopName) {
        navigate(`/creator/discoverPlatforms/setupShopify`);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const onGetFreeTrial = (eventData) => {
      console.log('emitter event data ===> ', eventData);
      setIsFTPOpen(eventData.isFree);
    };
    const listener = EventEmitter.addListener('get_free_trial', onGetFreeTrial);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const appSumoWelcomeModal = () => {
      setShowWelcomeModal(true);
    };
    const listener = EventEmitter.addListener('appSumoWelcome', appSumoWelcomeModal);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const onFreeTrialSuccess = (eventData) => {
      console.log('emitter event data ===> ', eventData);
      setFTPSuccess(eventData.isFree);
      setIsFTPOpen(eventData.isFree);
    };
    const listener = EventEmitter.addListener('show_free_trial_success', onFreeTrialSuccess);
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    const reviewed = localStorage.getItem('reviewed');
    const sessionReview = sessionStorage.getItem('reviewClose');
    if (reviewed || sessionReview) {
      setShowreviewModal(false);
    } else {
      setShowreviewModal(true);
    }
  }, []);

  useEffect(() => {
    waStatusApiCall();

    if (agentdata?._id) {
      console.log('getagent fnc calll');
      console.log('getagent fnc calll');
      console.log('getagent fnc calll');
      console.log('getagent fnc calll');
      V2GetAgentAPICall();
    }
  }, []);

  const V2GetAgentAPICall = () => {
    let token = authtoken;
    V2SingleGetAgent(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addAgentdata', payload: res?.data });
      } else {
        toast.error('Unable to fetch agent details at this moment. Login again later.', {
          position: 'top-center',
        });
        googleLogout();
        dispatch({ type: 'redirectToLogin', payload: true });
        dispatch({ type: 'logout' });

        localStorage.removeItem('token');
        navigate('/login');
      }
    });
  };

  useEffect(() => {
    if (localdata) {
      // if(!localdata?.free_trail?.status && !localdata?.free_trail?.used_status){
      //   setIsFTPOpen(false);
      // }

      if (Helper.EmbeddedAI == true) {
        signupV2status();
      }
    }
  }, [localdata]);

  // const adminLogin = () => {
  //   console.log("admin login called");
  //   let data = {
  //     email: process.env.REACT_APP_ADMIN_ID,
  //     password: process.env.REACT_APP_ADMIN_PASSWORD,
  //   };
  //   loginADMIN(data).then((res) => {
  //     if (res?.flag) {
  //       setAuthToken(res.token);
  //       console.log("admin login successful");
  //     } else {
  //       console.log(res);
  //     }
  //   });
  // };

  useEffect(() => {
    if(!isWAOpen){
      getuserapicall();
    }
  }, [isWAOpen]);

  useEffect(() => {
    if (authtoken?.length > 0) {
      dispatch({ type: 'addAuthToken', payload: authtoken });
      console.log('redux authtoken added');
      // callSignupV2api();
      getuserapicall();
      getlabellistapicall();
    }
  }, []);

  const getlabellistapicall = () => {
    let token = authtoken;
    // contactlabellistAPI(token).then((res) => {
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res?.flag) {
        setLabelList(res.data.labelList);
        console.log('6666666666', res);
      } else {
        console.log('err===>', res);
      }
    });

    // contactlabellistWithoutCountAPI(token).then((res) => {
    //   if (res.flag) {
    //     setLabelList(res.data.labelList);
    //     console.log('6666666666', res);
    //   } else {
    //     console.log('err===>', res);
    //   }
    // });
  };

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
        setIsLoading(false);
      } else {
        dispatch({ type: 'redirectToLogin', payload: true });
        dispatch({ type: 'logout' });
        localStorage.removeItem('token');
        console.log('triggered');

        navigate('/');
        toast.error(res?.message);
      }
    });
  };

  const isPremiumUser = localdata?.premium || localdata?.free_trail?.status;
  const onlyPremium = localdata?.premium;
  const getTodaysDate = () => {
    const date = new Date();
    const mm = date.toLocaleString('en-EN', { month: 'short' });
    const dd = date.getDate();
    const yy = date.getFullYear();
    const DD = date.toLocaleDateString('en-EN', { weekday: 'long' });
    return dd + '’ ' + mm + ' ' + yy;
  };

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);

  const AutoSubmitTemplatesAPIcall = () => {
    let data = {
      user_id: localdata._id,
    };
    let token = authtoken;
    AutoSubmitTemplates(data, token).then((res) => {
      if (res.flag) {
        console.log('auto submit success res ===>', res);
      } else {
        console.log('auto submit fail res ===>', res);
      }
    });
  };

  useEffect(() => {
    if (EmbeddedOpen) {
      console.log('embeddedOpen set to true');
      setIsEmbeddedModalOpen(true);
    }
  }, [EmbeddedOpen]);

  useEffect(() => {
    if (!isEmbeddedModalOpen) {
      setEmbeddedOpen(false);
    }
  }, [isEmbeddedModalOpen]);

  const checkWAStatusAPIcall = () => {
    let data = {
      user_id: localdata?._id,
    };
    let token = authtoken;
    EmbeddedCheckWAStatus(data, token).then((res) => {
      if (res?.flag) {
        // console.log('success response: ', res);
        setWAStatus(res?.data?.result?.verificationStatus === 'verified' ? true : false);
      } else {
        console.log('error response: ', res);
      }
    });
  };

  const getAllSenderIdApiCall = () => {
    const token = authtoken;
    getAllSenderId(token).then((res) => {
      if (res?.flag) {
        console.log('getAll sender id', res);
        if (res?.data && res?.data[0]?.verified?.status) {
          if (Object.keys(res?.data).length > 0) {
            setSenderId(true);
          }
        }
      } else {
        toast(res?.message, { position: 'top-center' });
      }
    });
  };
  useEffect(() => {
    projectQualityStatus();
    getAllSenderIdApiCall();
    fetchNotifications();
  }, []);

  const projectQualityStatus = () => {
    let data = {
      user_id: localdata?._id,
    };
    let token = authtoken;
    getProjectQualityStatus(data, token).then((res) => {
      if (res?.flag) {
        // console.log('success response: ', res);

        setWaQualityStatus(res?.data?.result?.data);
      } else {
        console.log('error response: ', res);
      }
    });
  };

  const switchToPartnerAction = () => {
    let token = authtoken;
    switchToPartner(token).then((res) => {
      if (res?.flag) {
        let partnerWindow = window.open(`${window.location.origin}/partner`, '_blank');
        partnerWindow.partnerCheerioToken = res?.data;
      } else {
        toast.error('There is no partner account linked to this account', {
          position: 'top-right',
        });
      }
    });
  };

  useEffect(() => {
    let timer = setInterval(() => {
      if (!isLoading) {
        clearInterval(timer);
      }

      if (isLoading && count < 90) {
        setCount((prev) => prev + 1);
      } else {
        clearInterval(timer);
      }
    }, 200);

    return () => clearInterval(timer);
  }, [isLoading, count]);

  useEffect(() => {
    if (localdata?._id == '6489675b4f598e055ed74e34') {
      Beacon.setRouter((path) => {
        navigate(path);
      });
    }
  }, [navigate]);

  // useEffect(() => {
  //   if (isLoading) {
  //     const timer = setInterval(() => {
  //       setCount((oldCount) => {
  //         if (oldCount == 90 || !isLoading) clearInterval(timer);
  //         return Math.min(oldCount + 1, 90);
  //       });
  //     }, 200);
  //   }
  // }, [isLoading]);

  const getDate = (e) => {
    const d = new Date(e);
    let dd = d.getDate();
    let mm = (d.getMonth() + 1).toString().padStart(2, '0');
    let yy = d.getFullYear().toString().slice(-2);
    return dd + '/' + mm + '/' + yy;
  };

  useEffect(() => {
    if (localdata) {
      trackPages('dashboard_page', localdata);
    }
  }, []);

  // const handleTrackLinks = (link, linkName) => {
  //   if (localdata?.email !== "priam@cheerio.in") {
  //     try {
  //       const data = {
  //         email: localdata?.email,
  //         link: link,
  //         properties: {
  //           userId: localdata?._id,
  //           userName: localdata?.name,
  //           userPhone: localdata?.mobile,
  //           companyName: localdata?.company_name,
  //           name: linkName
  //         }
  //       }
  //       const response = trackLink(data)
  //       if (response?.flag) {
  //         console.log('track link success')
  //       } else {
  //         console.log('track link failed')
  //       }
  //     } catch (error) {
  //       console.log('track link error', error)
  //     }
  //   }
  // }

  // const handleTrackEvent = async (event) => {
  //   if (localdata?.email !== "priam@cheerio.in") {
  //     try {
  //       const data = {
  //         email: localdata?.email,
  //         event: event,
  //         properties: {
  //           userId: localdata?._id,
  //           userName: localdata?.name,
  //           userPhone: localdata?.mobile,
  //           companyName: localdata?.company_name,
  //           isAppsumoUser: localdata?.isAppsumoUser,
  //         }
  //       }
  //       const response = await trackEvent(data)
  //       if (response?.flag) {
  //         console.log('event tracked successfully')
  //       }
  //       else {
  //         console.log('error in track event')
  //       }
  //     } catch (error) {
  //       console.log('error in track event', error);
  //     }
  //   }
  // }

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ marginTop: '40vh' }}
      >
        <NewLoader></NewLoader>
        <p style={{ fontSize: '16px', fontWeight: '600' }}>{count + '%'}</p>
      </div>
    );
  }

  return (
    <div className="creator">
      {showWelcomeModal && (
        <AppsumoWelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />
      )}
      {isQRModalOpen && <QRModal setIsQRModalOpen={setIsQRModalOpen} />}

      {isAPModalOpen && <AddPeopleModal setIsAPModalOpen={setIsAPModalOpen} />}

      {isAgentPermModalOpen && <AgentPermModal setIsAPModalOpen={setIsAgentPermModalOpen} />}

      {localdata?.isAppsumoUser && showreviewModal && (
        <AppSumoReviewModal
          showreviewModal={showreviewModal}
          setShowreviewModal={setShowreviewModal}
        />
      )}

      {PRWAModalOpen && (
        <PreReqWASetup
          setIsModalOpen={setPRWAModalOpen}
          proceedToSetup={() => {
            setIsWAPresetupModalOpen(true);
            setPRWAModalOpen(false);
          }}
        />
      )}

      {isAddTopUpModalOpen && walletCurrency === 'INR' ? (
        <AutomaticRechargeModal
          setAutomaticrechargemodalopen={setIsAddTopUpModalOpen}
          isAutomaticrechargemodalopen={isAddTopUpModalOpen}
          setIsPaymentSuccessModalOpen={setIsPaymentSuccessModalOpen}
          fetchautorechargedetails={()=>fetchautorechargedetails()}
          setIsonetimerechargeSucceesmodelopen={setIsonetimerechargeSucceesmodelopen}
        />
      ) : (
        <AddTopUpModal
          setIsAddTopUpModalOpen={setIsAddTopUpModalOpen}
          isAddTopUpModalOpen={isAddTopUpModalOpen}
        />
      )}
      {isPaymentSuccessModalOpen && (
        <AutotopupSuccesModel
          setPSModalOpen={setIsPaymentSuccessModalOpen}
          fetchautorechargedetails={() => fetchautorechargedetails()}
        />
      )}
       {isonetimerechargeSucceesmodelopen && (
        <OneTimeRechargeSuccessmodel 
        onetimerechargeSuccessModal={setIsonetimerechargeSucceesmodelopen}
        fetchautorechargedetails={()=>fetchautorechargedetails()}
        />
      )}
      {rechargeafterAutotopupsetModel&&(
          <RechargeAfterAutoTopUpsetModal 
          setAutomaticrechargemodalopen={setrechargeafterAutotopupsetModel}
          isAutomaticrechargemodalopen={rechargeafterAutotopupsetModel}
          setIsonetimerechargeSucceesmodelopen={setIsonetimerechargeSucceesmodelopen}
          />
        )}
      {showCurrencyDefaultModal && (
        <DefaultCurrencyModal
          setShowDefaultCurrencyModal={setShowDefaultCurrencyModal}
          showCurrencyDefaultModal={showCurrencyDefaultModal}
        />
      )}

      {showPremiumModal && (
        <PremiumModal
          setShowPremiumModal={setShowPremiumModal}
          showPremiumModal={showPremiumModal}
          planName="Basic"
          planDuration="Quarterly"
          currency="INR"
        />
      )}

      {isHistoryModalOpen && (
        <HistoryModal
          setIsHistoryModalOpen={setIsHistoryModalOpen}
          isHistoryModalOpen={isHistoryModalOpen}
        />
      )}

      {isWAPresetupModalOpen && (
        <WAPreSetupModal
          MetaEmbedded={false}
          // MetaEmbedded={MetaEmbeddedUser}
          AISensyEmbedded={true}
          // AISensyEmbedded={
          //   (Helper.EmbeddedAI == true ||
          //     localdata?.is_v2_user == true ||
          //     localdata?.ai_sensy_whatsapp_config == true) &&
          //   !MetaEmbeddedUser
          // }
          setIsWAPreSetupOpen={setIsWAPresetupModalOpen}
          onclickAction={() => {
            window.FB.login(
              function (response) {
                console.log({ response });
                if (response.authResponse) {
                  const code = response.authResponse.accessToken;
                  // console.log("CODE CODE CODE => ", code);
                  dispatch({ type: 'tempFBToken', payload: code });
                  eventsend('Proceed_to_setup_WhatsApp_setup_pop_up_Clicked');
                  // window.launchWhatsAppSignup();
                  setIsEmbeddedModalOpen(true);
                  // The returned code must be transmitted to your backend,
                  // which will perform a server-to-server call from there to our servers for an access token
                } else {
                  console.log('User cancelled login or did not fully authorize.');
                }
              },
              {
                scope: 'whatsapp_business_management',
                // response_type: 'code', // must be set to 'code' for System User access token
                // override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                // sessionInfoVersion: 2, //  Receive Session Logging Info
                extras: {
                  feature: 'whatsapp_embedded_signup',
                  setup: {
                    solutionID: '883160333502568',
                  },
                },
              }
            );
          }}
          onClickApply={() => {
            setIsEmbeddedModalOpen(true);
          }}
          openEmbedded={() => {
            console.log('setEmbeddedOpen to true');
            setEmbeddedOpen(true);
          }}
          WAStatus={WAStatus}
        />
      )}

      {openWATemplate && (
        <WATemplate
          setOpenWATemplate={setOpenWATemplate}
          proceedtoTemplate={proceedtoTemplate}
          goToTemplate={() => {
            setOpenWATemplate(false);
            setAutoSubmit(true);
            setIsTemplateOpen(true);
          }}
        />
      )}
      {isWARefreshOpen && <WARefreshModal setIsWARefreshOpen={setIsWARefreshOpen} />}
      {isEmbeddedModalOpen && (
        <EmbeddedModal
          setIsEmbeddedModalOpen={setIsEmbeddedModalOpen}
          openEmbeddedAction={EmbeddedOpen}
          checkWAStatusAction={() => {
            checkWAStatusAPIcall();
          }}
        />
      )}

      {profilePopup && (
        <ProfilePopup
          setProfilePopup={(e) => {
            setProfilePopup(e);
          }}
        />
      )}
      {isFTPOpen && (
        <FreeTrialPlans
          setIsOpen={setIsFTPOpen}
          centerstyle={{ transform: 'translate(-50%,-50%)' }}
          isSuccess={FTPSuccess}
          onsuccess={() => {
            getuserapicall();
          }}
        />
      )}
      {openICModal && <ICNewModal setOpenICModal={setOpenICModal} />}
      {openANModal && (
        <AnnNewModal setOpenANModal={setOpenANModal} EmailBuilder={ANMEMailBuilder} fromhomepage = {true} UploadFromHome={uploadHTMLHome} />
      )}
      {isFTMOpen && (
        <FreeTrialModal
          setIsOpen={setIsFTMOpen}
          onsuccess={() => {
            getuserapicall();
          }}
        />
      )}
      {isWAOpen && (
        <SetupWA
          setIsOpen={setIsWAOpen}
          onclick={() => {
            console.log('setup wa click');
            eventsend('Setup_WhatsApp_home_page_clicked');
          }}
          gotowebhooksetup={() => {
            setIsWAOpen(false);
            setIsWebhookOpen(true);
          }}
          proceedtoTemplate={() => {
            AutoSubmitTemplatesAPIcall();
            setIsWAOpen(false);
            setProceedtoTemplate(true);
            setOpenWATemplate(true);
          }}
        />
      )}
      {isWebhookOpen && <SetupWebhook setIsWebhookOpen={setIsWebhookOpen} />}
      {isDownloadCreditOpen && <DownloadCredit setIsDownloadCreditOpen={setIsDownloadCreditOpen} />}
      {isTemplateOpen && (
        <TemplateModal
          setIsTemplateOpen={setIsTemplateOpen}
          backAction={() => {
            setIsTemplateOpen(false);
            setOpenWATemplate(true);
          }}
          gotowebhook={() => {
            setIsTemplateOpen(false);
            setIsWebhookOpen(true);
          }}
        />
      )}
      {isSMSOpen && (
        <SetupSMS
          setIsOpen={setIsSMSOpen}
          onclick={() => {
            console.log('setup sms click');
            eventsend('Setup_SMS_home_page_clicked');
          }}
        />
      )}

      {openSenderIdModal && (
        <SenderIdModal
          openSenderIdModal={openSenderIdModal}
          setOpenSenderIdModal={setOpenSenderIdModal}
        ></SenderIdModal>
      )}

      {isSMSDLTModalOpen && <SMSDLTModal setIsOpenSMSDLTModal={setIsSMSDLTModalOpen} />}

      {/* {location.pathname === '/creator' &&
        <CheerioWidget
          openReferralModalAction={() => {
            setIsReferralModalOpen(true);
          }}
        />
      } */}

      {isReferralModalOpen && (
        <ReferralModal setIsReferralModalOpen={setIsReferralModalOpen} token={authtoken} />
      )}

      {/* Creator Header */}
      {window.innerWidth < 900 && (
        <Header
          setSidemenuCollapse={() => setSidemenuCollapse(!sidemenuCollapse)}
          setIsFTMOpen={setIsFTMOpen}
          setProfilePopup={() => {
            setProfilePopup(!profilePopup);
          }}
        />
      )}

      {/* Creator Body */}
      <div className="creator__body">
        {/* Sidemenu */}
        <SidemenuNew
          notPremiumUser={() => {
            setIsFTMOpen(true);
          }}
          sidemenuCollapse={sidemenuCollapse}
          setSidemenuCollapse={setSidemenuCollapse}
          openInviteMembersAction={() => {
            setIsAPModalOpen(true);
          }}
          setProfilePopup={() => {
            setProfilePopup(!profilePopup);
          }}
          openProfileAction={() => {
            console.log('opennnnnn');
          }}
          openReferralModalAction={() => {
            setIsReferralModalOpen(true);
          }}
          uploadHTMLAction={() => {
            handleTrackEvent('dashboard_upload_html_button_clicked', localdata);
            if (!agentdata?.permission?.campaign && agentdata && isPremiumUser) {
              setIsAgentPermModalOpen(true);
            } else if (isPremiumUser) {
              setANMEmailBuilder(true);
              setUploadHTMLHome(true);
              setOpenANModal(true);
            } else {
              setIsFTMOpen(true);
            }
          }}
        />

        {/* BODY Container */}
        <div
          className="creator__body__container"
          onClick={() => {
            if (MobileWidth) {
              setSidemenuCollapse(true);
            }
          }}
          style={{
            width: MobileWidth ? '100%' : 'calc( 100% - 75px )',
            // : sidemenuCollapse
            // ? 'calc( 100% - 60px)'
            // : 'calc( 100% - 240px )',
          }}
        >
          {/* Home Page Creator Route */}
          {location.pathname === '/creator' && (
            <>
              {/* Deployment Banner */}
              {localdata?.deployment && <DeploymentBanner />}
              {/* {localdata?.isAppsumoUser && <>
                <div
                  className={`${styles['Row']} ${styles['BannerContainer']}`}
                  style={{ backgroundColor: colors.yellow03, width: 'calc( 100% - 32px )' }}
                >
                  <img src={images.InfoCircle} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
                  <p style={{ flex: 1, margin: 0, paddingBlock: 0, fontSize: 16, fontWeight: 400, color: colors.black, paddingInline: 16, textAlign: 'justify' }}>
                    {"We are experiencing temporarily email delivery failure. It’s expected to restore within 24 hours. We apologize for the inconvenience caused."}
                  </p>
                </div>
              </>} */}

              {/* Free Trial */}
              {localdata?.free_trail?.status && !onlyPremium && (
                <div
                  className="d-flex text-white flex-row justify-content-center align-items-center w-full p-2"
                  style={{ flex: 1, width: '100%', backgroundColor: '#3E3E3E', gap: '10px' }}
                >
                  <p>
                    {`This is a ${remainingDays}/7 days trial account, To start running campaigns and reaching out to customers`}
                  </p>
                  <span>
                    <CheerioButton
                      borderStyle={{
                        border: `1px solid ${colors.primary03}`,
                        paddingBlock: 6,
                      }}
                      textStyle={{ fontSize: 12, fontWeight: 600, color: colors.white01 }}
                      btnText={'Contact Sales'}
                      onclick={() => {
                        window.open('mailto:support@cheerio.freshdesk.com?subject=Contact Us');
                      }}
                      backColor={colors.primary03}
                    />
                  </span>
                </div>
              )}

              {/* Welcome Header */}
              <div className="welcome_row">
                {localdata?.isAppsumoUser ? (
                  <>
                    {!MobileWidth && (
                      <CheerioButton
                        borderStyle={{
                          border: `1px solid ${colors.primary03}`,
                          paddingBlock: 10,
                        }}
                        textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white01 }}
                        btnText={'AppSumo user? Click here!'}
                        icon={images.ExternalWhite}
                        onclick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/appsumo-user-here-s-everything-you-need-to-know-about-using-cheerio-ai',
                            'dashboard_appsumo_doc_clicked',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/appsumo-user-here-s-everything-you-need-to-know-about-using-cheerio-ai',
                            '_blank'
                          );
                          // console.log("app sumo here")
                        }}
                        backColor={colors.primary03}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <h2>{'Dashboard'}</h2>
                  </>
                )}

                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  {!MobileWidth &&
                    showSearchBar &&
                    localdata?._id == '6489675b4f598e055ed74e34' && (
                      <div
                        style={{
                          padding: '5px',
                          borderRadius: '10px',
                          border: 'none',
                          alignItems: 'center',
                          position: 'relative', // Necessary for absolute positioning
                          overflow: 'hidden', // Hi
                          border: '1px solid #CCCCCC',
                          borderRadius: '10px',
                          color: '#666666',
                          fontSize: '12px',
                        }}
                        className="d-flex justify-content-between"
                      >
                        <div
                          className="d-flex"
                          onClick={() => {
                            eventsend('Beacon_icon_Home_page_clicked');
                            if (localdata?._id == '6489675b4f598e055ed74e34') {
                              Beacon.open();
                            }
                          }}
                          style={{ alignItems: 'center', cursor: 'pointer', width: '250px' }}
                        >
                          {/* <img src={images.Stars} height={16}></img> */}
                          <IoIosSearch size={20} style={{ marginInline: '5px' }} />
                          <p style={{ width: '100%' }}>Quick search</p>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#F0F0F0',
                            borderRadius: '5px',
                            paddingInline: '12px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            fontSize: '12px',
                          }}
                        >
                          Alt+K
                        </div>
                        {/* <RxCrossCircled
                                size={19}
                                color="white"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowSearchBar(false)}
                              /> */}
                      </div>
                    )}
                  {!MobileWidth &&
                    (localdata?.isAppsumoUser ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-end align-items-center"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleTrackLinks(
                              `${window.location.origin}/partner`,
                              'dashboard_switch_workspace_clicked',
                              localdata
                            );
                            switchToPartnerAction();
                            // window.open(`${window.location.origin}/partner`, '_blank');
                            // navigate('/partner/dashboard');
                          }}
                        >
                          <div className="d-flex flex-column align-items-start">
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.linkblue,
                                marginInlineEnd: 8,
                              }}
                            >
                              {'Login to partner'}
                            </p>
                            <p style={{ fontSize: 13, fontWeight: 400, color: colors.linkblue }}>
                              {'(Switch Workspace)'}
                            </p>
                          </div>
                          <MdOutlineOpenInNew
                            size={20}
                            color={colors.linkblue}
                            style={{ marginBlockEnd: 16 }}
                          />
                        </div>
                        <img
                          className="notification"
                          src={readStatus ? images.NOTIFICATIONUNREAD : images.NOTIFICATIONLOGO}
                          height={'40px'}
                          onClick={() => {
                            eventsend('Notifications_home_page_clicked');
                            setOpenNotificationModal(true);
                            fetchNotifications();
                          }}
                          ref={notificationIconRef}
                        />
                        <NotificationModal
                          openNotificationModal={openNotificationModal}
                          setOpenNotificationModal={setOpenNotificationModal}
                          anchorEl={notificationIconRef.current}
                          allNotifications={allNotifications}
                          loading={loading}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          unreadNotifications={unreadNotifications}
                          fetchNotifications={fetchNotifications}
                        />
                      </>
                    ) : (
                      <>
                        {localdata?.premium_details &&
                          Object.keys(localdata?.premium_details).length > 0 && (
                            <h4
                              style={{
                                fontSize: '14px',
                                border: '1px solid black',
                                borderRadius: '8px',
                                padding: MobileWidth ? '16px' : '8px',
                              }}
                            >
                              {`${localdata?.premium_details?.plan_type} plan: Renewal on ${getDate(
                                localdata?.premium_details?.plan_end
                              )}`}
                            </h4>
                          )}
                        <CheerioButton
                          borderStyle={{
                            borderWidth: 0,
                            borderRadius: 12,
                            paddingInline: MobileWidth ? 16 : 32,
                            // paddingBlock: 4,
                            // width: '28%',
                          }}
                          backColor={'#8E53F7'}
                          textStyle={{ fontSize: MobileWidth ? 12 : 14, color: colors.white01 }}
                          btnText={'Upgrade'}
                          // icon={images.Calendar}
                          onclick={() => {
                            eventsend('Upgrade_Home_page_clicked');
                            handleTrackEvent('dashboard_upgrade_button_clicked', localdata);
                            // window.open('https://www.cheerio.in/pricingplan', '_blank');
                            // setShowPremiumModal(true);
                            navigate('/premiumpricing');
                          }}
                        />
                        <img
                          className="notification"
                          src={readStatus ? images.NOTIFICATIONUNREAD : images.NOTIFICATIONLOGO}
                          height={'40px'}
                          onClick={() => {
                            setOpenNotificationModal(true);
                            fetchNotifications();
                            eventsend("Notification_icon_button_clicked");
                          }}
                          ref={notificationIconRef}
                        />
                        <NotificationModal
                          openNotificationModal={openNotificationModal}
                          setOpenNotificationModal={setOpenNotificationModal}
                          anchorEl={notificationIconRef.current}
                          allNotifications={allNotifications}
                          loading={loading}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          unreadNotifications={unreadNotifications}
                          fetchNotifications={fetchNotifications}
                        />
                      </>
                    ))}
                </div>
              </div>

              <div className="content_container_new">
                {!MobileWidth &&
                  showReferralBanner && 
                  !localStorage.getItem('hasClosedReferralBanner') && 
                  !localdata?.isEmailBlocked &&
                  !localdata?.isBounceWarning && (
                    <ReferralBanner
                      setIsReferralModalOpen={setIsReferralModalOpen}
                      onclickCloseAction={() => {
                        localStorage.setItem('hasClosedReferralBanner','true')
                        Helper.ReferralBanner = false;
                        setShowReferralBanner(false);
                      }}
                    />
                  )}
                {isModalOpen && localdata?.isEmailBlocked && <WarningModal onClose={handleClose} />}
                {isBounceModalOpen && localdata?.isBounceWarning && (
                  <BounceWarningModal onClose={handleBounceModalClose} />
                )}

                <div className="content_container_new__row1">
                  {onlyPremium ? (
                    <>
                      <div className="premiumRow">
                        <PremiumTile
                          ItemHeader={'Add Contacts'}
                          ItemImage={images.DNContacts2}
                          ItemStyle={{
                            marginInlineEnd: MobileWidth ? 0 : 12,
                            backgroundColor: '#D7E4FF',
                            borderColor: '#99A7C7',
                            marginBlockEnd: MobileWidth ? 12 : 0,
                          }}
                          ItemText={
                            'Add contacts to Cheerio using CSV file or sync your Facebook account directly'
                          }
                          ButtonText={'Add contacts'}
                          onclickAction={() => {
                            eventsend('Add_contacts__home_page_clicked');
                            handleTrackEvent('dashboard_add_contacts_button_clicked', localdata);
                            if (!agentdata?.permission?.contact && agentdata && isPremiumUser) {
                              setIsAgentPermModalOpen(true);
                            } else if (isPremiumUser) {
                              // setOpenICModal(true);
                              // navigate('/creator/uploadContacts');
                              navigate('/creator/uploadContacts', {
                                state: { from: '/creator' },
                              });
                            } else {
                              setIsFTMOpen(true);
                            }
                          }}
                        />
                        <PremiumTile
                          ItemHeader={'Shoot Campaign'}
                          ItemImage={images.DNCampaign2}
                          ItemStyle={{
                            marginInlineStart: MobileWidth ? 0 : 12,
                            backgroundColor: '#FFDDD7',
                            borderColor: '#FFB1A3',
                            marginBlockEnd: MobileWidth ? 12 : 0,
                          }}
                          ItemText={
                            'Schedule or shoot your new campaign right from here with few easy steps'
                          }
                          ButtonText={'New campaign'}
                          onclickAction={() => {
                            eventsend('New_campaign__home_page_clicked');
                            handleTrackEvent('dashboard_new_campaign_button_clicked', localdata);
                            if (!agentdata?.permission?.campaign && agentdata && isPremiumUser) {
                              setIsAgentPermModalOpen(true);
                            } else if (isPremiumUser) {
                              setANMEmailBuilder(false);
                              setOpenANModal(true);
                            } else {
                              setIsFTMOpen(true);
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <BroadcastNew />
                  )}

                  {isPremiumUser && walletCurrency  ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '1%',
                          width: MobileWidth ? '100%' : 'default',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#FFF5E7',
                            borderRadius: '16px',
                            border: '2px solid #E5DCD0',
                            width: MobileWidth ? 'auto' : '25rem',
                            height: '45%',
                            marginBottom: '4%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '3%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '3px',
                              }}
                            >
                              <h2
                                style={{
                                  fontWeight: 700,
                                  fontSize: '16px',
                                }}
                              >
                                {'Available Credits'}
                              </h2>
                            </div>
                            {/* <div
                              style={{
                                display: 'flex',
                                gap: '6px',
                              }}
                            >
                              {(!excepted &&!hideAnalytics) && (
                                <Tooltip title={'Download Report'}>
                                  <img
                                    src={images.Download_CSV}
                                    alt="download credit report"
                                    onClick={() => {
                                      eventsend('Download_report_home_page_clicked');
                                      setIsDownloadCreditOpen(true);
                                    }}
                                    style={{
                                      height: 24,
                                      width: 24,
                                      objectFit: 'contain',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <Tooltip
                                title={
                                  'If you wish to move your email balance to your partner dashboard, please raise a ticket with us'
                                }
                              >
                                <img
                                  src={images.QAhelp}
                                  alt="Help Icon"
                                  style={{
                                    height: 22,
                                    width: 22,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip>
                            </div> */}
                          </div>
                          <div style={{ border: '1px solid #E5DCD0' }}></div>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '5%',
                              margin: '1%',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '21.28px',
                              }}
                            >
                              Email Credits
                            </p>
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: MobileWidth ? '20px' : '24px',
                                lineHeight: '19.92px',
                              }}
                            >
                              {localdata?.balance?.email ? localdata?.balance?.email : '0'}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            backgroundColor: '#FFF5E7',
                            borderRadius: '16px',
                            border: '2px solid #E5DCD0',
                            width: MobileWidth ? 'auto' : '25rem',
                            height: '45%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '3%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '3px',
                              }}
                            >
                              <h2
                                style={{
                                  fontWeight: 700,
                                  fontSize: '16px',
                                }}
                              >
                                {'Wallet'}
                              </h2>

                              {/* <Tooltip
                                title={
                                  'Add balance to use Whatsapp or SMS or any add ons beyond available limits'
                                }
                              >
                                <img
                                  src={images.InfoCircle}
                                  alt="wallet information"
                                  onClick={() => {
                                    if (walletCurrency) {
                                      setIsAddTopUpModalOpen(true);
                                    } else {
                                      setShowDefaultCurrencyModal(true);
                                    }
                                  }}
                                  style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip> */}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                gap: '6px',
                              }}
                            >
                              <Tooltip title={'Payment History'}>
                                <img
                                  src={images.History}
                                  alt="Payment History"
                                  onClick={() => {
                                    if (walletCurrency) {
                                      setIsHistoryModalOpen(true);
                                    } else {
                                      setShowDefaultCurrencyModal(true);
                                    }
                                  }}
                                  style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <div style={{ border: '1px solid #E5DCD0' }}></div>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '4%',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 700,
                                fontSize: MobileWidth ? '20px' : '24px',
                                lineHeight: '21.28px',
                              }}
                            >
                              {walletCurrency === 'INR'
                                ? `₹${walletBalance ?? 0}`
                                : `$${walletBalance ?? 0}`}
                            </p>
                            <CheerioButton
                              borderStyle={{
                                width: MobileWidth ? 'auto' : '45%',
                                border: '1px solid black',
                              }}
                              textStyle={{
                                color: colors.black,
                              }}
                              btnText={'Add Balance'}
                              backColor="transparent"
                              onclick={() => {
                                
                                handleTrackEvent('dashboard_add_balance_button_clicked', localdata);
                                console.log('###########################', autorechargdata.length);
                                if (autorechargdata.length > 0 && walletCurrency != 'USD') {
                                  setrechargeafterAutotopupsetModel(true);
                                } else {
                                  if (walletCurrency) {
                                    eventsend("Add_balance_wallet_dashboard_clicked");
                                    setIsAddTopUpModalOpen(true);
                                  } else {
                                    setShowDefaultCurrencyModal(true);
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <CreditsTile
                      onclickAction={() => {
                        setIsDownloadCreditOpen(true);
                      }}
                      balanceData={localdata?.balance}
                      freetrial={isFTPOpen}
                      excepted={excepted}
                      hideAnalytics={hideAnalytics}
                    />
                  )}
                </div>

                {isPremiumUser && !agentdata && DashboardEvent && !MobileWidth && (
                  // {(isPremiumUser && !agentdata && (DashboardEvent && DashboardEvent[0]?.Campaign === true)) &&
                  <div
                    className="w-100"
                    onClick={() => {
                      //setShowGpt(false);
                    }}
                  >
                    {!showGpt && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowGpt(true);
                        }}
                      >
                        <img className="gpt-bg" src={images.GPTNEW}></img>
                        {/* <img className="gpt-gif" src={images.GPTGIF}></img> */}
                      </div>
                    )}
                    {showGpt && <GptComponent setShow={setShowGpt}></GptComponent>}{' '}
                    {localdata?.isAppsumoUser && (
                      <>
                        <div className="content_container_new__subheader">
                          <h3>{'Get started with your Email Campaigns'}</h3>
                        </div>
                        <div className="content_container_new__accordian">
                          <div
                            className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                            style={{ flexWrap: 'wrap' }}
                          >
                            <AccordianItems
                              ItemHeader={'Create Sender ID'}
                              ItemImage={images.DNSenderID}
                              ItemText={'Setup email channel by creating your sender id on Cheerio'}
                              ActionText={'Create Sender ID'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_create_sender_id_button_clicked',
                                  localdata
                                );
                                eventsend('Setup_Email_home_page_clicked');
                                if (agentdata?.permission?.email || !agentdata) {
                                  navigate('/creator/manageSender');
                                }
                              }}
                              // completed={DashboardEvent[0]?.WhatsApp}
                              completed={senderId}
                            />
                            <AccordianItems
                              ItemHeader={'Design & Send Email via Dashboard'}
                              ItemImage={images.DNEmailDesign}
                              ItemText={
                                'Design email on our Cheerio using our drag & drop email builder'
                              }
                              ActionText={'Design Email'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_design_email_button_clicked',
                                  localdata
                                );
                                if (agentdata?.permission?.email || !agentdata) {
                                  navigate('/creator/design');
                                }
                              }}
                            />
                            <AccordianItems
                              ItemHeader={'Upload HTML & Send'}
                              ItemImage={images.DNUploadHTML}
                              ItemText={'Upload a HTML file of your email & send it your contacts'}
                              ActionText={'Upload HTML & Send'}
                              onclickAction={() => {
                                eventsend('Upload_file_home_page_clicked');
                                handleTrackEvent('dashboard_upload_html_button_clicked', localdata);
                                if (
                                  !agentdata?.permission?.campaign &&
                                  agentdata &&
                                  isPremiumUser
                                ) {
                                  setIsAgentPermModalOpen(true);
                                } else if (isPremiumUser) {
                                  setANMEmailBuilder(true);
                                  setOpenANModal(true);
                                } else {
                                  setIsFTMOpen(true);
                                }
                              }}
                            />
                            <AccordianItems
                              ItemHeader={'Browse Email Template'}
                              ItemImage={images.DNEmailTemplate}
                              ItemText={'Browse pre-crafted email templates in template library'}
                              ActionText={'Browse Templates'}
                              onclickAction={() => {
                                eventsend('Email_templates_Campaign_pop_up_clicked');
                                handleTrackEvent(
                                  'dashboard_browse_templates_button_clicked',
                                  localdata
                                );
                                if (agentdata?.permission?.chatReplies || !agentdata) {
                                  navigate('/creator/templates/emailTemplates');
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="content_container_new__subheader">
                      <h3>{'Get started with your Marketing Journey'}</h3>
                      <CheerioButton
                        borderStyle={{
                          border: `1px solid ${colors.primary03}`,
                          paddingInline: 20,
                        }}
                        textStyle={{
                          color: colors.primary03,
                        }}
                        btnText={'Schedule onboarding call'}
                        backColor="transparent"
                        onclick={() => {
                          eventsend('Schedule_onboarding_call_home_page_clicked');
                          handleTrackLinks(
                            'https://www.cheerio.in/post/how-to-get-onboarding-support-for-your-cheerio-premium-account',
                            'dasboard_schedule_onboarding_call_clicked',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/how-to-get-onboarding-support-for-your-cheerio-premium-account',
                            '_blank'
                          );
                        }}
                      />
                    </div>
                    <div className="content_container_new__accordian">
                      <Accordion style={{}} className="customAccordian">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className=""
                        >
                          <h4>
                            {'1. Setup a channel'}
                            {/* <span style={{ color: '#FFBF00' }}>
                              {' (Onboarding support recommended for WhatsApp and SMS!)'}
                            </span> */}
                          </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                            style={{ flexWrap: 'wrap' }}
                          >
                            <AccordianItems
                              ItemHeader={'WhatsApp'}
                              ItemImage={images.DNWhatsApp}
                              ItemText={'Installation takes few minutes'}
                              ActionText={'Setup Channel'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_setup_channel_button_clicked',
                                  localdata
                                );
                                eventsend('Setup_WhatsApp_home_page_clicked');
                                setPRWAModalOpen(true);
                                // setIsWAPresetupModalOpen(true);
                              }}
                              completed={DashboardEvent[0]?.WhatsApp} 
                              InfoTooltip={true}
                            />
                            <AccordianItems
                              ItemHeader={'Email'}
                              ItemImage={images.DNEmail}
                              ItemText={'Installation takes few seconds'}
                              ActionText={'Setup Channel'}
                              onclickAction={() => {
                                handleTrackEvent('dashboard_setup_email_button_clicked', localdata);
                                setOpenSenderIdModal(true);
                              }}
                              completed={DashboardEvent[0]?.Email}
                            />
                            <AccordianItems
                              ItemHeader={'SMS'}
                              ItemImage={images.DNSMS}
                              ItemText={'Installation takes few hours'}
                              ActionText={'Setup SMS'}
                              onclickAction={() => {
                                eventsend('Setup_SMS_home_page_clicked');
                                handleTrackEvent('dashboard_setup_sms_button_clicked', localdata);
                                setIsSMSDLTModalOpen(true);
                                // window.open(
                                //   'mailto:support@cheerio.freshdesk.com?subject=Setup SMS channel ticket'
                                // );
                              }}
                              completed={DashboardEvent[0]?.Sms ? DashboardEvent[0]?.Sms[0] : false} 
                              InfoTooltip={true}
                            />
                            {/* <AccordianItems
                              ItemHeader={'Facebook'}
                              ItemImage={images.DNFacebookNew}
                              ItemText={'Installation takes few seconds'}
                              ActionText={'Setup Channel'}
                              onclickAction={() => {
                                handleTrackEvent('dashboard_setup_facebook_button_clicked', localdata);
                                window.FB.login(
                                  function (response) {
                                    console.log({ response });
                                    if (response.authResponse) {
                                      const code = response.authResponse.accessToken;
                                      // console.log("CODE CODE CODE => ", code);
                                      dispatch({ type: 'tempFBToken', payload: code });
                                      // The returned code must be transmitted to your backend,
                                      // which will perform a server-to-server call from there to our servers for an access token
                                    } else {
                                      console.log(
                                        'User cancelled login or did not fully authorize.'
                                      );
                                    }
                                  },
                                  {
                                    scope: 'whatsapp_business_management',
                                    // response_type: 'code', // must be set to 'code' for System User access token
                                    // override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                                    // sessionInfoVersion: 2, //  Receive Session Logging Info
                                    extras: {
                                      feature: 'whatsapp_embedded_signup',
                                      setup: {
                                        solutionID: '883160333502568',
                                      },
                                    },
                                  }
                                );
                              }}
                              completed={DashboardEvent[0]?.WhatsApp || tempFBToken}
                            /> */}
                            {/* 
                            <AccordianItems
                              ItemHeader={'Instagram'}
                              ItemImage={images.Instagram}
                              ItemText={'Installation takes few seconds'}
                              ActionText={'Setup Channel'}
                              onclickAction={() => {
                                navigate('instagramReplies');
                              }}
                              completed={localdata?.instagramPageToken ?? false}
                            />
                             */}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="content_container_new__accordian">
                      <Accordion style={{}} className="customAccordian">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className=""
                        >
                          <h4>{'2. Build an audience'}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                            style={{ flexWrap: 'wrap' }}
                          >
                            <AccordianItems
                              ItemHeader={'CSV File'}
                              ItemImage={images.DNCSV}
                              ItemText={'Upload a list of contacts'}
                              ActionText={'Upload a File'}
                              onclickAction={() => {
                                handleTrackEvent('dashboard_upload_csv_button_clicked', localdata);
                                // setOpenICModal(true);
                                navigate('/creator/uploadContacts');
                              }}
                              completed={DashboardEvent[0]?.CSV}
                            />
                            <AccordianItems
                              ItemHeader={'Facebook Sync'}
                              ItemImage={images.DNFacebook}
                              ItemText={'Sync Leads from Facebook'}
                              ActionText={'Sync Contacts'}
                              onclickAction={() => {
                                handleTrackEvent(
                                  'dashboard_facebook_sync_button_clicked',
                                  localdata
                                );
                                eventsend('Sync_from_FB_home_page_clicked');
                                navigate('/creator/contacts/contacts', {
                                  state: { openFBSync: true },
                                });
                              }}
                            />
                            <AccordianItems
                              ItemHeader={'Shopify'}
                              ItemImage={images.DNShopify}
                              ItemText={'Integrate Shopify Store'}
                              ActionText={'Integrate Store'}
                              onclickAction={() => {
                                eventsend('Integrate_shopify_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_shopify_integration_button_clicked',
                                  localdata
                                );
                                navigate('/creator/shopifystore');
                              }}
                              completed={DashboardEvent[0]?.Shopify}
                            />
                            <AccordianItems
                              ItemHeader={'WooCommerce'}
                              ItemImage={images.DNWoo}
                              ItemText={'Integrate WooCommerce store'}
                              ActionText={'Integrate Store'}
                              onclickAction={() => {
                                eventsend('Integrate_WooCommerce_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_woocommerce_integration_button_clicked',
                                  localdata
                                );
                                navigate('/creator/woocommerce');
                              }}
                              completed={DashboardEvent[0]?.WooComm}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="content_container_new__accordian">
                      <Accordion style={{}} className="customAccordian">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className=""
                        >
                          <h4>{'3. Create content'}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                            style={{ flexWrap: 'wrap' }}
                          >
                            <AccordianItems
                              ItemHeader={'WhatsApp'}
                              ItemImage={images.DNWhatsApp}
                              ItemText={
                                'Chat with customers by creating engaging messages to convert'
                              }
                              ActionText={'Create a template'}
                              onclickAction={() => {
                                eventsend('Create_a_template_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_create_whatsapp_template_button_clicked',
                                  localdata
                                );
                                navigate('/creator/templates/create');
                              }}
                            />
                            <AccordianItems
                              ItemHeader={'Email'}
                              ItemImage={images.DNEmail}
                              ItemText={'Create stunning looking Emails that engage & convert'}
                              ActionText={'Create a template'}
                              onclickAction={() => {
                                eventsend('Create_email_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_create_email_template_button_clicked',
                                  localdata
                                );
                                navigate('/creator/design');
                              }}
                              completed={DashboardEvent[0]?.Email_Template}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="content_container_new__accordian">
                      <Accordion style={{}} className="customAccordian">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className=""
                        >
                          <h4>{'4. Broadcast campaigns'}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="my-2 d-flex flex-row justify-content-start align-items-stretch"
                            style={{ flexWrap: 'wrap' }}
                          >
                            <AccordianItems
                              ItemHeader={'Shoot a campaign'}
                              ItemImage={images.DNCampaign}
                              ItemText={
                                'Create your first campaign to reach out to your customers on Email, SMS, WhatsApp'
                              }
                              ActionText={'Send a campaign'}
                              onclickAction={() => {
                                eventsend('Run_a_campaign_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_send_campaign_button_clicked',
                                  localdata
                                );
                                setOpenANModal(true);
                              }}
                              completed={DashboardEvent[0]?.Campaign}
                            />
                            <AccordianItems
                              ItemHeader={'Schedule campaign'}
                              ItemImage={images.DNScheduleCampaign}
                              ItemText={
                                'Schedule your first campaign to run automatically at your desired time.'
                              }
                              ActionText={'Schedule campaign'}
                              onclickAction={() => {
                                eventsend('Schedule_a_campaign_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_schedule_campaign_button_clicked',
                                  localdata
                                );
                                setOpenANModal(true);
                              }}
                            // completed={DashboardEvent[0]?.Campaign}
                            />
                            <AccordianItems
                              ItemHeader={'Setup Workflows'}
                              ItemImage={images.DNCreateWorkflow}
                              ItemText={
                                'Setup an automation workflow for your campaigns, WhatsApp journeys & emails.'
                              }
                              ActionText={'Create a workflow'}
                              onclickAction={() => {
                                eventsend('Create a workflow_home_page_clicked');
                                handleTrackEvent(
                                  'dashboard_create_workflow_button_clicked',
                                  localdata
                                );
                                window.location.href = window.location.origin + '/workflow';
                              }}
                            // completed={DashboardEvent[0]?.Campaign}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    {/* Cards Section */}
                    <div className="content_container_new__subheader">
                      <h3>{'Reach large set of audience, Enable Channels you need.'}</h3>
                    </div>
                    <div className="content_container_new__cards_row verythinscrollnewinv">
                      <img 
                        src={images.HSCard1} 
                        className='cardImage'
                      />
                      <img 
                        src={images.HSCard2} 
                        className='cardImage'
                      />
                      <img 
                        src={images.HSCard3} 
                        className='cardImage'
                      />
                      <img 
                        src={images.HSCard4} 
                        className='cardImage'
                      />
                    </div>

                  </div>
                )}

                <div className="content_container_new__subheader">
                  <h3>{'Read documentations or watch videos to get started!'}</h3>
                </div>

                <div className="content_container_new__master_row2">
                  <div className="content_container_new__master_row2__row2">
                    <div className="video_container tile">
                      <h3>{'Getting Started with Cheerio'}</h3>
                      <div
                        className="player_container"
                        onclick={eventsend('Embedded_Youtube_video_Home_page_clicked')}
                      >
                        <ReactPlayer
                          url={'https://www.youtube.com/watch?v=SrxHNa0zvRU&t=191s'}
                          height="100%"
                          width="100%"
                          controls={true}
                          playing={true}
                          muted
                        />
                      </div>
                    </div>
                    <div className="blog_container tile">
                      <div className="d-flex flex-row justify-content-between align-items-center w-100">
                        <h3 style={{ marginBlock: 0 }}>{'Blogs/ Articles'}</h3>
                        <div className="d-flex flex-row jsutify-content-end align-items-center w-50">
                          <p
                            style={{ marginBlock: 0, textAlign: 'end', marginInlineEnd: 6 }}
                            onClick={() => {
                              eventsend('View_all_blogs_Home_page_clicked');
                              handleTrackLinks(
                                'https://www.cheerio.in/help',
                                'dashboard_view_all_blogs_link',
                                localdata
                              );
                              window.open('https://www.cheerio.in/help', '_blank');
                            }}
                          >
                            {'View all'}
                          </p>
                          <MdOutlineOpenInNew size={20} color={colors.linkblue} />
                        </div>
                      </div>
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/how-to-set-up-whatsapp-on-cheerio',
                            'dashboard_whatsapp_setup_help_link',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/how-to-set-up-whatsapp-on-cheerio',
                            '_blank'
                          );
                        }}
                      >
                        {'How to setup channels on Cheerio'}
                      </p>
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/how-to-integrate-your-shopify-store-with-cheerio',
                            'dashboard_shopify_integration_help_link',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/how-to-integrate-your-shopify-store-with-cheerio',
                            '_blank'
                          );
                        }}
                      >
                        {'How to integrate Shopify store on Cheerio'}
                      </p>
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/how-to-add-contacts-on-cheerio',
                            'dashboard_add_contacts_help_link',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/how-to-add-contacts-on-cheerio',
                            '_blank'
                          );
                        }}
                      >
                        {'How to add contacts on Cheerio'}
                      </p>
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/how-to-launch-campaigns-on-cheerio',
                            'dashboard_launch_campaign_help_link',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/how-to-launch-campaigns-on-cheerio',
                            '_blank'
                          );
                        }}
                      >
                        {'How to shoot a campaign on Cheerio'}
                      </p>
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/post/how-to-create-whatsapp-message-template-on-cheerio',
                            'dashboard_create_whatsapp_template_help_link',
                            localdata
                          );
                          window.open(
                            'https://www.cheerio.in/post/how-to-create-whatsapp-message-template-on-cheerio',
                            '_blank'
                          );
                        }}
                      >
                        {'How to create whatsapp template'}
                      </p>
                    </div>
                  </div>
                  <div className="content_container_new__master_row2__col2">
                    <div className="qr_tile_container tile">
                      <h3>{'WhatsApp QR Code'}</h3>
                      <p className="my-2">
                        {
                          'Enter the WhatsApp Mobile Number and Pre-filled text and generate your WhatsApp Link/QR code Instantly'
                        }
                      </p>
                      <h4
                        className="my-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          eventsend('Generate_QR_Code_home_page_clicked');
                          handleTrackEvent(
                            'dashboard_track_whatsapp_qr_code_button_clicked',
                            localdata
                          );
                          setIsQRModalOpen(true);
                        }}
                      >
                        <BiBarChartAlt2 color="#3366CC" size={24} style={{ marginInlineEnd: 8 }} />
                        {'Track QR code'}
                      </h4>
                      <CheerioButton
                        borderStyle={{
                          width: '100%',
                          marginBlockStart: 10,
                          border: '1px solid black',
                        }}
                        textStyle={{
                          color: colors.black,
                        }}
                        btnText={'Generate QR Code'}
                        backColor={colors.white01}
                        onclick={() => {
                          handleTrackLinks(
                            'https://www.cheerio.in/whatsappchatlink',
                            'dashboard_generate_qr_code_button_clicked',
                            localdata
                          );
                          window.open('https://www.cheerio.in/whatsappchatlink', '_blank');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="content_container_new__subheader" style={{ border: '' }}>
                  <h3>{'Other Stats'}</h3>
                </div>
                <div className="content_container_new__master_row2">
                  <div className="content_container_new__master_row2__row2" style={{ border: '' }}>
                    <div
                      className="quality_container tile"
                      style={{ width: MobileWidth ? 'auto' : 'default' }}
                    >
                      <h3>{'Daily WABA Limit:'}</h3>
                      <p className="my-2" style={{}}>
                        {(localdata._id ==="671cdedcf954d69eaef494cf" || localdata._id ==="6719309f3beb1c2c7b154326" || localdata._id === "67319ae99fb52a43f93b45cb") ? "TIER_10K":waStatusData?.limit ?? ''}
                      </p>
                      <h3 style={{ marginTop: '10px' }}>{'WhatsApp API Quality:'}</h3>
                      <p
                        className="my-2"
                        style={{
                          borderRadius: '12px',
                          backgroundColor:
                            waStatusData?.quality?.score === 'GREEN'
                              ? '#00BF13'
                              : waStatusData?.quality?.score === 'RED'
                                ? 'red'
                                : '#F0E68C',
                          width: '20%',
                          alignSelf: 'flex-start',
                          color: 'white',
                          textAlign: 'center',
                          padding: '5px',
                        }}
                      >
                        {waStatusData?.quality?.score === 'GREEN'
                          ? 'High'
                          : waStatusData?.quality?.score === 'RED'
                            ? 'Low'
                            : 'Medium'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Content Container */}
              {/* <div className="content_container">
                <div className="content_container__col_70">
                  
                  {MetaEmbeddedUser && (
                    <METAEmbeddedSignUp
                      onclickAction={() => {
                        window.launchWhatsAppSignup();
                      }}
                    />
                  )}

                  
                  {(Helper.EmbeddedAI == true ||
                    localdata?.is_v2_user == true ||
                    localdata?.ai_sensy_whatsapp_config == true) &&
                    !MetaEmbeddedUser && (
                      <EmbeddedSignUp
                        onClickApply={() => {
                          setIsEmbeddedModalOpen(true);
                        }}
                        openEmbedded={() => {
                          console.log('setEmbeddedOpen to true');
                          setEmbeddedOpen(true);
                        }}
                        WAStatus={WAStatus}
                      />
                    )}

                  
                  {onlyPremium ? (
                    <div className="progress_container">
                      <div className="progress_container__left_container">
                        <h4>{'You are few steps away from setting up Cheerio CRM'}</h4>
                        <div className="row_container">
                          <img src={images.HNProfileSetup} />
                          <p>{'Upload your contacts using Excel & create segments'}</p>
                        </div>
                        <div className="row_container">
                          <img src={images.HNProfileSetup} />
                          <p>{'Setup your WhatsApp channel to reach customers'}</p>
                        </div>
                        <div className="row_container">
                          <img src={images.HNProfileSetup} />
                          <p>{'Start a campaign by selecting templates'}</p>
                        </div>
                        <div className="row_container">
                          <img src={images.HNProfileSetup} />
                          <p>{'Your customers will start seeing your messages'}</p>
                        </div>
                      </div>

                      <div className="progress_container__right_container">
                        <div className="row_container">
                          <p>{'Profile'}</p>
                          <ProgressBar
                            variant="customcolor"
                            style={{
                              color: colors.primary03,
                              width: '60%',
                              height: 8,
                              borderRadius: 4,
                            }}
                            now={localdata?.profile_percentage}
                          />
                        </div>
                        <div className="row_container">
                          <p>{'Contacts'}</p>
                          <ProgressBar
                            variant="customcolor"
                            style={{
                              color: colors.primary03,
                              width: '60%',
                              height: 8,
                              borderRadius: 4,
                            }}
                            now={labelList?.length !== 0 ? 100 : 4}
                          />
                        </div>
                        <div className="row_container">
                          <p>{'WhatsApp'}</p>
                          <ProgressBar
                            variant="customcolor"
                            style={{
                              color: colors.primary03,
                              width: '60%',
                              height: 8,
                              borderRadius: 4,
                            }}
                            now={localdata?.whatsapp_config == 'Active' ? 100 : 4}
                          />
                        </div>

                        <CheerioButton
                          disabled={agentdata?.permission?.campaign || !agentdata ? false : true}
                          borderStyle={{ width: '100%' }}
                          textStyle={{
                            color: colors.white,
                          }}
                          btnText={'Start a Campaign'}
                          backColor={colors.black04}
                          onclick={() => {
                            if (isPremiumUser) {
                              setOpenANModal(true);
                            } else {
                              setIsFTMOpen(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <Broadcast></Broadcast>
                  )}

                  
                  {senderId && (
                    <div className="setup_email_container">
                      <h4>{'Setup & engage with your customers via email'}</h4>
                      <p>
                        {
                          'You are required to setup a sender ID for every promotional email you send in order to comply with the anti- spam laws such as CAN-SPAM and CASL.'
                        }
                      </p>
                      <div className="setup_email_container__button_container">
                        <CheerioButton
                          borderStyle={{
                            borderColor: '#FF7070',
                            width: MobileWidth && '100%',
                            marginBlock: 8,
                            marginInline: 8,
                          }}
                          textStyle={{
                            color: colors.white,
                          }}
                          btnText={'Setup your first email sender ID'}
                          backColor={'#FF7070'}
                          icon={images.WhiteEnvelope}
                          onclick={() => {
                            if (isPremiumUser) {
                              setOpenSenderIdModal(true);
                            } else {
                              setIsFTMOpen(true);
                            }
                          }}
                        />

                        <CheerioButton
                          borderStyle={{
                            width: MobileWidth && '100%',
                            marginBlock: 8,
                            border: '1px solid black',
                            marginInline: 8,
                          }}
                          textStyle={{
                            color: colors.black,
                          }}
                          btnText={'Schedule LIVE Demo'}
                          backColor="#FFDCD6"
                          icon={images.Calendar}
                          onclick={() => {
                            console.log('schedule demo clicked . . .');
                            window.open('https://calendly.com/avinash-cheerio/30min', '_blank');
                          }}
                        />

                        {openSenderIdModal && (
                          <SenderIdModal
                            openSenderIdModal={openSenderIdModal}
                            setOpenSenderIdModal={setOpenSenderIdModal}
                          ></SenderIdModal>
                        )}
                      </div>
                    </div>
                  )}

                  
                  <div className="contact_campaign_container">
                    {onlyPremium && (
                      <div className="contact_container">
                        <div className="contact_container__row">
                          <div className="image_container">
                            <img src={images.SNContacts} />
                          </div>
                          <p>
                            {
                              'Import Contacts on Cheerio via csv file and send them Emails, SMS & WhatsApp messages easily.'
                            }
                          </p>
                        </div>
                        <div className="contact_container__divider"></div>
                        <CheerioButton
                          borderStyle={{
                            width: '100%',
                            marginBlockStart: 16,
                            marginBlockEnd: 8,
                          }}
                          disabled={agentdata?.permission?.contact || !agentdata ? false : true}
                          btnText={'Upload Contacts'}
                          icon={images.HNUploadContacts}
                          backColor={'#D7E4FF'}
                          onclick={() => {
                            if (isPremiumUser) {
                              setOpenICModal(true);
                            } else {
                              setIsFTMOpen(true);
                            }
                          }}
                        />
                      </div>
                    )}

                    {onlyPremium && (
                      <div className="campaign_container">
                        <div className="campaign_container__row">
                          <div className="image_container">
                            <img src={images.SNCampaigns} />
                          </div>
                          <p>
                            {
                              'Share Offers, Product launches and Important notices with your customers with ease using Announcements.'
                            }
                          </p>
                        </div>
                        <div className="campaign_container__divider"></div>
                        <CheerioButton
                          borderStyle={{
                            width: '100%',
                            marginBlockStart: 16,
                            marginBlockEnd: 8,
                          }}
                          disabled={agentdata?.permission?.campaign || !agentdata ? false : true}
                          btnText={'New Campaign'}
                          icon={images.HNCampaign}
                          backColor={'#FFDDD7'}
                          onclick={() => {
                            if (isPremiumUser) {
                              setOpenANModal(true);
                            } else {
                              setIsFTMOpen(true);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                
                <div className="content_container__col_30">
                  {localdata?.whatsapp_credentials?.status === 'Active' ||
                    (WAStatus && <EmbeddedStatusModal />)}


                  {localdata?.whatsapp_credentials?.is_ai_sensy_user && (
                    <div className="aisensy_status_container">
                      <h4>{'WhatsApp Business API stats'}</h4>
                      <span>{waQualityStatus ? waQualityStatus.status : 'Active'}</span>
                      <h4>{'Quality Rating'}</h4>
                      <span>{waQualityStatus ? waQualityStatus.wa_quality_rating : 'High'}</span>
                    </div>
                  )}

                  
                  <div className="credits_container">
                    <div className="credits_container__row">
                      <h4>{'Available Message Credits'}</h4>
                      <Tooltip title={'Download Report'}>
                        <img
                          src={images.Download_CSV}
                          alt="download_img"
                          onClick={() => {
                            setIsDownloadCreditOpen(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className="credits_container__divider"></div>
                    <div className="credits_container__row_element">
                      <p>{'Emails'}</p>
                      <span>{localdata?.balance?.email ? localdata?.balance?.email : '0'}</span>
                    </div>
                    <div className="credits_container__divider"></div>
                    <div className="credits_container__row_element">
                      <p>{'SMS'}</p>
                      <span>{localdata?.balance?.sms ? localdata?.balance?.sms : '0'}</span>
                    </div>
                    <div className="credits_container__divider"></div>
                    <div className="credits_container__row_element">
                      <p>{'WhatsApp'}</p>
                      <span>
                        {localdata?.balance?.whatsapp ? localdata?.balance?.whatsapp : '0'}
                      </span>
                    </div>
                  </div>

                  {onlyPremium && !localdata?.ai_sensy_whatsapp_config ? (
                    <div
                      className="d-flex flex-column justify-content-between align-items-center"
                      style={{
                        width: '100%',
                        backgroundColor: '#C3FFCA',
                        marginBlock: 16,
                        borderRadius: 16,
                        border: '1px solid #E6E6E6',
                        paddingInline: 20,
                        paddingBlock: 10,
                      }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between align-items-center w-100"
                        style={{ marginBlock: 8 }}
                      >
                        <img
                          src={images.HNWhatsApp}
                          style={{
                            height: 40,
                            width: 40,
                            objectFit: 'contain',
                            marginInlineEnd: 16,
                            backgroundColor: colors.white,
                            border: '1px solid #E6E6E6',
                            borderRadius: 12,
                          }}
                        ></img>
                        <div className="d-flex flex-row justify-content-end align-items-center w-100">
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{
                              paddingInline: 8,
                              paddingBlock: 4,
                              border: '1px solid #1A6B27',
                              borderRadius: 12,
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={images.HNWRead}
                              style={{
                                height: 24,
                                width: 24,
                                objectFit: 'contain',
                                marginInlineEnd: 2,
                              }}
                            ></img>
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: '#1A6B27',
                              }}
                              onClick={() => {
                                navigate('/documentation/whatsapp');
                              }}
                            >
                              {'Read doc'}
                            </p>
                          </div>
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{
                              border: `1px solid #1A6B27`,
                              borderRadius: 8,
                              marginInlineStart: 8,
                              padding: 4,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setIsWARefreshOpen(true);
                            }}
                          >
                            <img
                              src={images.WARefreshToken}
                              style={{ height: 22, width: 22, objectFit: 'contain' }}
                            ></img>
                          </div>
                        </div>
                      </div>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 14,
                          fontWeight: 400,
                          color: '#1A6B27',
                          width: '100%',
                          flexWrap: 'wrap',
                          wordBreak: 'break-word',
                          marginBlockEnd: 8,
                        }}
                      >
                        {localdata?.whatsapp_config == 'Active'
                          ? 'Congratulations! Your WhatsApp channel is ready to use.'
                          : localdata?.whatsapp_config == 'Tested'
                          ? "You can test announcements using Cheerio's WhatsApp number in the meantime."
                          : 'Setup your WhatsApp Channel on Cheerio in 2 easy steps and start sharing messages on WhatsApp.'}
                      </p>
                      <div
                        style={{
                          height: 1,
                          width: 'calc( 100% + 40px )',
                          backgroundColor: '#AEE3B4',
                        }}
                      ></div>
                      <CheerioButton
                        borderStyle={{
                          width: '100%',
                          marginBlockStart: 16,
                          marginBlockEnd: 8,
                        }}
                        textStyle={{ fontSize: 14, fontWeight: 600 }}
                        btnText={
                          localdata?.whatsapp_config == 'Active'
                            ? 'Edit Channel details'
                            : localdata?.whatsapp_config == 'Tested'
                            ? 'Check Status'
                            : 'Setup WhatsApp'
                        }
                        backColor={'#C3FFCA'}
                        onclick={() => {
                          if (isPremiumUser) {
                            if (localdata?.whatsapp_config == 'Tested') {
                              setOpenWATemplate(true);
                            } else {
                              setIsWAOpen(true);
                            }
                          } else {
                            setIsFTMOpen(true);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {!localdata?.ai_sensy_whatsapp_config ? (
                    <>
                      <div
                        className="d-flex flex-column justify-content-between align-items-center"
                        style={{
                          width: '100%',
                          backgroundColor: '#9BDFA3',
                          marginBlock: 16,
                          borderRadius: 16,
                          border: '1px solid #AEE3B4',
                          paddingInline: 20,
                          paddingBlock: 10,
                        }}
                      >
                        <div
                          className="d-flex flex-row justify-content-between align-items-start w-100"
                          style={{ marginBlock: 8 }}
                        >
                          <img
                            src={images.HNWhatsApp}
                            style={{
                              height: 40,
                              width: 40,
                              objectFit: 'contain',
                              marginInlineEnd: 16,
                              backgroundColor: colors.white,
                              border: '1px solid #E6E6E6',
                              borderRadius: 12,
                            }}
                          ></img>
                        </div>

                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#1A6B27',
                            width: '100%',
                            flexWrap: 'wrap',
                            wordBreak: 'break-word',
                            marginBlockEnd: 8,
                          }}
                        >
                          {'Setup WhatsApp webhook to see analytics and user replies.'}
                        </p>
                        <div
                          style={{
                            height: 1,
                            width: 'calc( 100% + 40px )',
                            backgroundColor: '#77AD7D',
                          }}
                        ></div>
                        <CheerioButton
                          borderStyle={{
                            width: '100%',
                            marginBlockStart: 16,
                            marginBlockEnd: 8,
                          }}
                          textStyle={{ fontSize: 14, fontWeight: 600 }}
                          btnText={'View Steps'}
                          backColor={'#9BDFA3'}
                          onclick={() => {
                            setIsWebhookOpen(true);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                 
                  <QRTile
                    generateQRAction={() => {
                      window.open('https://www.cheerio.in/whatsappchatlink', '_blank');
                    }}
                    trackQRAction={() => {
                      setIsQRModalOpen(true);
                    }}
                  />
                </div>
              </div> */}
            </>
          )}
          <Routes>
            <Route path="contacts/:id/*" element={<ContactModule excepted={excepted} />} />
            <Route path="announcements" element={<AnnouncementsNew />} />
            <Route path="analytics" element={<CreatorAnalyticsNew excepted={excepted} />} />
            <Route path="shopifystore" element={<ShopifyStore />} />
            <Route path="woocommerce" element={<WooCommerce />} />
            <Route path="whatsappreplies/*" element={<WhatsAppReplies excepted={excepted} />} />
            <Route path="chatbot" element={<ChatBot />} />
            <Route path="manageSender/*" element={<ManageSender />} />
            <Route path="templates/*" element={<Templates />} />
            <Route path="templates/create" element={<CustomTemplateStart />} />
            <Route path="annnewcomp" element={<AnnNewComp />} />
            <Route path="createtemplate" element={<CreateTemplate />} />
            <Route path="design" element={<EmailBuilder />} />
            <Route path="workflow" element={<WorkflowTabels />} />
            <Route path="instagramReplies" element={<InstagramReplies />} />
            <Route path="messengerReplies" element={<MessengerReplies />} />
            <Route path="emailTemplates/edit" element={<EmailBuild />}></Route>
            <Route path="globalAnalytics" element={<GlobalAnalytics excepted={excepted} />}></Route>
            <Route path="agentAnalytics" element={<AgentAnalytics excepted={excepted} />}></Route>
            <Route path="discoverPlatforms/*" element={<DiscoverPlatforms />}></Route>
            <Route path="manageIntegrations/*" element={<ManageIntegrationsPage />}></Route>
            <Route path="keywords" element={<Keywords />}></Route>
            <Route path="timetriggers" element={<TimeTriggers />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="codPrepaid" element={<CodPrepaidFlow></CodPrepaidFlow>}></Route>
            <Route path="uploadContacts/" element={<ContactMapping />}></Route>
            <Route path="smartForms/*" element={<EmbeddableForms />}></Route>
            <Route path="excelLabel/*" element={<ExcelUpload />}></Route>
            <Route path="vendorsList/*" element={<VendorList />}></Route>
            <Route path="Sequences/*" element={<Sequences />}></Route>
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default CreatorNew;
