import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';
import SuccessModal from './SuccessModal';
import SpinnerLoader from '../../../Components/SpinnerLoader';

import '../../../../src/Pages/Templates/styles.css';
import { toast } from 'react-toastify';
import 'primeicons/primeicons.css';
import {
    setupautorecharge,
    paymentOrder,
    orderPayment,
    getUserApi,
    partnerCreatePaymentOrder,
    setupautorechargepayment,
} from '../../../Services';
import { eventsend } from '../../../Config/analyticsFunctions';

export const CreditDebitCardForm = ({ name, setName, phone, setPhone, email, setEmail, onValidatePhone, }) => {

    const handlenameChange = (e) => {

        const value = e.target.value;
        console.log(value)
        setName(value);
    };

    const handlemobileChange = (e) => {
        const value = e.target.value;
        console.log(value)
        setPhone(value);
        onValidatePhone(value)
    };
    const handleemailChange = (e) => {
        const value = e.target.value;
        console.log(value)
        setEmail(value);
    };
    return (
        <div>
            <p

                style={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#666666',
                    paddingRight: '3%',
                    paddingBottom: '4%',
                    marginTop: '14px'
                }}
            >
                Cheerio accepts VISA, Master card, Amex credit card and Rupay credit or debit cards as payment
            </p>
            <label style={{ display: 'block', marginBottom: '10px', fontWeight: 700, fontSize: '14px' }}>Name</label>
            <input
                type="text"
                value={name}
                onChange={handlenameChange}
                placeholder="Enter your name"
                className="form-control shadow-none"
                style={{
                    width: '96%',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: '#ddd',
                }}
            />
            <label style={{ display: 'block', marginBottom: '10px', fontWeight: 700, fontSize: '14px' }}>Mobile</label>
            <input
                type="text"
                value={phone}
                onChange={handlemobileChange}
                placeholder="Enter your mobile"
                className="form-control shadow-none"
                style={{
                    width: '96%',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: '#ddd',
                }}
            />
            <label style={{ display: 'block', marginBottom: '8px', fontWeight: 700, fontSize: '14px' }}>Email</label>
            <input
                type="email"
                value={email}
                onChange={handleemailChange}
                placeholder="Enter your email ID"
                className="form-control shadow-none"
                style={{
                    width: '96%',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: '#ddd',
                }}
            />
        </div>
    );
};

export const UpiMandateForm = ({ name, setName, phone, setPhone, email, setEmail, upiId, setUpiId, onValidatePhone }) => {

    // const [errors, setErrors] = useState({ email: '' });

    // const handleUpiIdChange = (e) => {
    //     const value = e.target.value;
    //     setUpiId(value);
    //     console.log(value)
    // };
    const handlenameChange = (e) => {

        const value = e.target.value;
        console.log(value)
        setName(value);
    };

    const handlemobileChange = (e) => {
        const value = e.target.value;
        console.log(value)
        setPhone(value);
        onValidatePhone(value);
    };
    const handleemailChange = (e) => {
        const value = e.target.value;
        console.log(value)
        setEmail(value);
    };
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#666666',
                    paddingRight: '3%',
                    paddingBottom: '4%',
                    marginTop: '14px'
                }}
            >
                Enter your details to continue
            </p>
            {/* 
            <label
                style={{
                    display: 'block',
                    marginBottom: '10px',
                    fontWeight: 700,
                    fontSize: '14px',
                }}
            >
                UPI ID
            </label>

            <div style={{ position: 'relative', width: '96%' }}>
                <input
                    type="text"
                    value={upiId}
                    onChange={handleUpiIdChange}
                    placeholder="Enter your UPI ID"
                    className="form-control shadow-none"
                    style={{
                        width: '100%',
                        padding: '10px',
                        marginBottom: '10px',
                        borderRadius: '8px',
                        borderColor: '#ddd',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                    }}
                />
            </div> */}
            <label style={{ display: 'block', marginBottom: '10px', fontWeight: 700, fontSize: '14px' }}>Name</label>
            <input
                type="text"
                value={name}
                onChange={handlenameChange}
                placeholder="Enter your name"
                className="form-control shadow-none"
                style={{
                    width: '96%',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: '#ddd',
                }}
            />
            <label style={{ display: 'block', marginBottom: '10px', fontWeight: 700, fontSize: '14px' }}>Mobile</label>
            <input
                type="text"
                value={phone}
                onChange={handlemobileChange}
                placeholder="Enter your mobile"
                className="form-control shadow-none"
                style={{
                    width: '96%',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: '#ddd',
                }}
            />
            <label style={{ display: 'block', marginBottom: '8px', fontWeight: 700, fontSize: '14px' }}>Email</label>
            <input
                type="email"
                value={email}
                onChange={handleemailChange}
                placeholder="Enter your email ID"
                className="form-control shadow-none"
                style={{
                    width: '96%',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: '#ddd',
                }}
            />
        </div>
    );
};

const AutomaticRechargeModal = ({
    setAutomaticrechargemodalopen,
    isAutomaticrechargemodalopen,
    setIsPaymentSuccessModalOpen,
    fetchautorechargedetails,
    setIsonetimerechargeSucceesmodelopen,
    isPartner = false,
    updatePartnerDashboard,
}) => {
    const handleClose = () => {
        console.log("button clicled")
        setAutomaticrechargemodalopen(false);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [clicked, setClicked] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [walletAmount, setwalletAmount] = useState('');
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    // const [isSubscription, setIsSubscription] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('card');
    const [name, setname] = useState(localdata.name)
    const [email, setEmail] = useState(localdata.email)
    const [phone, setPhone] = useState(localdata.mobile)
    const [upiname, setupiname] = useState(localdata.name)
    const [upiemail, setupiEmail] = useState(localdata.email)
    const [upiphone, setupiPhone] = useState(localdata.mobile)
    const [upiId, setUpiId] = useState('');
    const [isSubscription, setIsSubscription] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isCreditDebitPhoneValid, setIsCreditDebitPhoneValid] = useState(true);
    const [isUpiPhoneValid, setIsUpiPhoneValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const[totalAmount,SetTotalamount]  = useState('')
    const handleChange = (e) => {
        setIsChecked(e.target.checked);
    };
    console.log("#############", localdata.email, localdata.name, localdata.mobile)

    const validateCreditDebitPhone = (phone) => {
        const isValid = phone.startsWith('91') || phone.length === 10;
        setIsCreditDebitPhoneValid(isValid);
    };

    const validateUpiPhone = (upiphone) => {
        const isValid = upiphone.startsWith('91') || upiphone.length === 10;
        setIsUpiPhoneValid(isValid);
    };
    const validateForm = () => {
        if (paymentMethod === 'card') {
            if (phone && inputValue && walletAmount > 0 && isChecked) {
                setIsButtonEnabled(true);
            } else {
                setIsButtonEnabled(false);
            }
        } else if (paymentMethod === 'upi') {
            if (upiphone && inputValue && walletAmount > 0 && isChecked) {
                setIsButtonEnabled(true);
            }
            else {

                setIsButtonEnabled(false);
            }
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        validateForm();
    }, [name, email, phone, upiname, upiemail, upiphone, inputValue, walletAmount, isChecked, paymentMethod]);

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const autotopupayment = async (razorpay_payment_id, razorpay_order_id, razorpay_signature) => {
        let token = authtoken;
        const data = {
            razorpay_payment_id: razorpay_payment_id,
            razorpay_order_id: razorpay_order_id,
            razorpay_signature: razorpay_signature
        }
        console.log("after payment data")
        const res = await setupautorechargepayment(token, data);
        if (!res.flag) {
            toast.error('Payment failed. Please try again ');
        }
    }

    async function displayRazorpayOrderforautotopup(name, email, phone, totalAmount, walletAmount) {
        setIsLoading(true);
        let token = authtoken;
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            setIsLoading(false)
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        // const numericAmount = parseInt(amount, 10);
        // const jsonPlan = {
        //   amount: numericAmount,
        // };
        if (totalAmount > 0) {
            const data = {
                name: name,
                email: email,
                contact: phone,
                method: paymentMethod,
                recurring: "1",
                currency: 'INR',
                setting: {
                    minThreshold: parseInt(walletAmount),
                    addAmount: totalAmount
                },
                notes: "Your auto recharge request has been proceesed "
            }

            const res = await setupautorecharge(token, data);
            const options = {
                key: 'rzp_live_1IEjlHGI3q0yMR',
                order_id: res.data.order_id,
                customer_id: res.data.customer_id,
                recurring: "1",
                notes: {
                    "note_key 1": "Auto Mandate"
                },
                theme: {
                    color: "#F37254"
                },
                handler: function (response) {
                    toast.success('Your auto topup Recharge is done successfully.', { position: 'top-center' });
                    //  window.location.reload();
                    console.log("Payment REsponse", response)
                    eventsend("success_autopay_setup_opened");
                    const razorpay_payment_id = response.razorpay_payment_id
                    const razorpay_signature = response.razorpay_signature
                    const razorpay_order_id = response.razorpay_order_id
                    autotopupayment(razorpay_payment_id, razorpay_order_id, razorpay_signature);
                    // Close AutomaticRechargeModal
                    setAutomaticrechargemodalopen(false);
                    setIsLoading(false)
                    fetchautorechargedetails();
                    setIsPaymentSuccessModalOpen(true);
                },


            };
            //   console.log("optaions",options)
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            setIsLoading(false);
        } else {
            toast.error('Amount has to be greater than 0');
            setIsLoading(false);
        }
    }



    const handleInputChange = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setInputValue(event.target.value);
            console.log(event.target.value)
            setClicked(null);
        }
        const total = (event.target.value * 1.18).toFixed(2); 
        SetTotalamount(total); 
        console.log("Total amount with GST:", total);
    };

    const handlewalletAmount = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setwalletAmount(event.target.value);
            console.log(event.target.value)
            const eventProps = {
                amountEntered: event.target.value
            }
            eventsend("Wallet_balance_goes_below_clicked", eventProps);
        }
    }
    const MobileWidth = window.innerWidth < 700 ? true : false;
    useEffect(() => {
        if (inputValue === '') {
            setClicked(null);
        }
    }, [inputValue]);


    const handleClick = (amount) => {
        if(amount==="3000"){
            eventsend("INR3000_quick_selector_clicked")
        }else if(amount === "5000"){
            eventsend("INR5000_quick_selector_clicked");
        }else if(amount==="1000"){
            eventsend("INR1000_quick_selector_clicked");
        }else if(amount==="10000"){
            eventsend("INR10000_quick_selector_clicked");
        }else if(amount==="25000"){
            eventsend("INR25000_quick_selector_clicked");
        }
        const numericValue = parseInt(amount.replace('INR', ''), 10);
        if (clicked === amount) {
            setInputValue('');
            setClicked(null);
        } else {
            setInputValue(numericValue);
            setClicked(amount);
            const total = (numericValue * 1.18).toFixed(2);
            SetTotalamount(total);
            setIsSubscription(true);
        }
    };

    const handlesetupautopay = () => {
        console.log("button clicked")
        eventsend("setup_autopay_clicked")
        validateCreditDebitPhone(phone);
        validateUpiPhone(upiphone);
        if (paymentMethod === 'card') {
            if (!isCreditDebitPhoneValid) {
                toast.error('please enter valid mobile number');
            }
            else if (!isValidEmail(email)) {
                toast.error('please enter valid email id');
            }
            else if (walletAmount <= 0) {
                toast.error('walletAmount should be greater than 0');
            }
            else if (!name) {
                toast.error('Please enter your name');
            }
            else if (walletAmount > 0 && totalAmount > 0) {
                displayRazorpayOrderforautotopup(name, email, phone, totalAmount, walletAmount);
            }
        }
        if (paymentMethod === 'upi') {
            if (!isUpiPhoneValid) {
                toast.error('please enter valid mobile number');
            }
            else if (!isValidEmail(upiemail)) {
                toast.error('please enter valid email id');
            }
            else if (!upiname) {
                toast.error('Please enter your name');
            }
            else if (walletAmount <= 0) {
                toast.error('walletAmount should be greater than 0');
            }
            else if (walletAmount > 0 && totalAmount > 0) {
                displayRazorpayOrderforautotopup(upiname, upiemail, upiphone, totalAmount, walletAmount);
            }
        }

    }
    async function displayonetimetopup(totalAmount) {
        setIsLoading(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            setIsLoading(false)
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        // const numericAmount = parseInt(totalAmount, 10);
        const jsonPlan = {
            amount: totalAmount,
        };
        if (totalAmount > 0) {
            const data = await orderPayment(jsonPlan, authtoken);
            console.log("payment data", data);
            const options = {
                key: 'rzp_live_1IEjlHGI3q0yMR',
                order_id: data?.response?.orderId,
                amount: data?.response?.amount,
                currency: data?.response?.currency,
                name: 'Order',
                description: 'Order',
                handler: function (response) {
                    setIsonetimerechargeSucceesmodelopen(true);
                    setIsLoading(false)
                    toast.success('Your recharge is done successfully.', { position: 'top-center' });
                    // window.location.reload();
                    setAutomaticrechargemodalopen(false);
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            setIsLoading(false)
        } else {
            toast.error('Amount has to be greater than 0');
            setIsLoading(false)
        }
    }
    const handleonetimetopup = () => {
        displayonetimetopup(totalAmount);
    }
    const [showAmountBreakup, setShowAmountBreakup] = useState(false)
    return (
        <div>
            {isLoading && (
                <div className="d-flex flex-column justify-content-center align-items-center w-100"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 9999,
                    }}
                >
                    <SpinnerLoader></SpinnerLoader>
                </div>
            )}
            <Modal show={isAutomaticrechargemodalopen} onHide={handleClose} className="automatic-modal"

            >

                <Modal.Body
                    className=".automatic-modal-body"
                    style={{
                        padding: '.80%',
                        marginTop: '-6px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: MobileWidth ? 'column' : 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <div style={{ flex: MobileWidth ? 1 : 0.55, marginRight: MobileWidth ? '0' : '2%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: MobileWidth ? 'column' : 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={images.WalletIcon}
                                    style={{
                                        padding: '2%',
                                        height: MobileWidth ? '45%' : 'defailt',
                                        width: MobileWidth ? '40%' : 'default',
                                    }}
                                ></img>
                                <p
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        lineHeight: '25px',
                                        padding: '4%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop :'-20px',
                                    }}
                                >
                                    Never run out of credits
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            color: '#666666',
                                            paddingTop: '2%',
                                        }}
                                    >
                                        {isPartner ? 'Add balance to your wallet' : 'Add money to your wallet'}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        paddingTop: '0%',
                                        paddingLeft: '2%',
                                    }}
                                >
                                    Description
                                </p>
                                <ul
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        color: '#666666',
                                    }}
                                >
                                    <li
                                        style={{
                                            paddingRight: '3%',
                                            paddingBottom: '2%',
                                        }}
                                    >
                                        This balance will be used as your WhatsApp Conversations & SMS Balance
                                    </li>
                                    <li
                                        style={{
                                            paddingRight: '3%',
                                            paddingBottom: '2%',
                                        }}
                                    >
                                        The amount will be deducted from your wallet as per the conversation & SMS usage
                                    </li>
                                    <li
                                        style={{
                                            paddingRight: '3%',
                                            paddingBottom: '2%',
                                        }}
                                    >
                                        Per conversation & SMS pricing will vary as per the country terms.
                                    </li>
                                    <li
                                        style={{
                                            paddingRight: '3%',
                                            paddingBottom: '2%',
                                        }}
                                    >
                                        You will be able to track & monitor each transaction by accessing your wallet
                                        history
                                    </li>
                                    {!isPartner && (
                                        <li
                                            style={{
                                                paddingRight: '3%',
                                                paddingBottom: '-3%',
                                            }}
                                        >
                                            You can cancel your subscription anytime you want
                                        </li>
                                    )}
                                </ul>
                                <label style={{ display: 'block', marginBottom: '8px', marginBottom: '4px', fontWeight: 700, fontSize: '14px', marginLeft: '10px', }}>When wallet balance goes below</label>
                                <input
                                    type="number"
                                    style={{
                                        width: '90%',
                                        padding: '10px',
                                        marginBottom: '6px',
                                        marginLeft: '10px',
                                        borderRadius: '8px',
                                        borderColor: '#ddd',

                                    }}
                                    className="form-control shadow-none"
                                    inputMode="numeric"
                                    value={walletAmount}
                                    onChange={handlewalletAmount}
                                    placeholder="₹ 0"
                                    min="0"
                                    step="1"

                                ></input>
                                <label style={{ display: 'block', marginBottom: '8px', fontWeight: 700, fontSize: '14px', marginLeft: '10px', }}>Top Up Amount</label>
                                <input
                                    type="number"
                                    style={{
                                        width: '90%',
                                        padding: '10px',
                                        marginBottom: '6px',
                                        marginLeft: '10px',
                                        borderRadius: '8px',
                                        borderColor: '#ddd',


                                    }}
                                    className="form-control shadow-none"
                                    placeholder="₹ 0"
                                    inputMode="numeric"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    min="0"
                                    step="1"
                                ></input>

                                <label style={{ display: 'block', marginBottom: '6px', fontWeight: 700, fontSize: '14px', marginLeft: '10px', }}>Quick selectors</label>
                                <div style={{ paddingLeft: '2%', paddingTop: '1%', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>


                                    <Button
                                        variant="outline-secondary"
                                        style={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            color: clicked === 'INR1000' ? '#8E53F7' : '#666666',
                                            borderColor: clicked === 'INR1000' ? '#8E53F7' : '#666666',
                                            backgroundColor: clicked === 'INR1000' ? '#ebd6fd' : 'white',
                                            width: '67px',
                                            height: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleClick('1000')}
                                    >
                                        ₹1000
                                    </Button>

                                    <Button
                                        variant="outline-secondary"
                                        style={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            color: clicked === 'INR3000' ? '#8E53F7' : '#666666',
                                            borderColor: clicked === 'INR3000' ? '#8E53F7' : '#666666',
                                            backgroundColor: clicked === 'INR3000' ? '#ebd6fd' : 'white',
                                            width: '67px',
                                            height: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleClick('3000')}
                                    >
                                        ₹3000
                                    </Button>


                                    <Button
                                        variant="outline-secondary"
                                        style={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            color: clicked === 'INR5000' ? '#8E53F7' : '#666666',
                                            borderColor: clicked === 'INR5000' ? '#8E53F7' : '#666666',
                                            backgroundColor: clicked === 'INR5000' ? '#ebd6fd' : 'white',
                                            width: '67px',
                                            height: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleClick('5000')}
                                    >
                                        ₹5000
                                    </Button>

                                    <Button
                                        variant="outline-secondary"
                                        style={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            color: clicked === 'INR10000' ? '#8E53F7' : '#666666',
                                            borderColor: clicked === 'INR10000' ? '#8E53F7' : '#666666',
                                            backgroundColor: clicked === 'INR10000' ? '#ebd6fd' : 'white',
                                            width: '67px',
                                            height: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleClick('10000')}
                                    >
                                        ₹10000
                                    </Button>
                                    <Button
                                        variant="outline-secondary"
                                        style={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            color: clicked === 'INR25000' ? '#8E53F7' : '#666666',
                                            borderColor: clicked === 'INR25000' ? '#8E53F7' : '#666666',
                                            backgroundColor: clicked === 'INR25000' ? '#ebd6fd' : 'white',
                                            width: '67px',
                                            height: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onClick={() => handleClick('25000')}
                                    >
                                        ₹25000
                                    </Button>
                                </div>
                                <p

                                    style={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        color: '#666666',
                                        paddingRight: '3%',
                                        paddingTop: '5%',
                                        paddingLeft: '2%',
                                    }}
                                >
                                    You can change or cancel this auto-topup anytime
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: '4.5%',
                                        // paddingBottom: '2%',
                                        paddingTop: '-2%'
                                    }}
                                >
                                    <img src={images.CheerioAILogo} style={{ height: 30, width: 150, objectFit: 'contain', marginTop: '4%', marginLeft: '2%' }} />
                                    <i className="pi pi-lock" style={{ fontSize: '16px', color: '#666666', paddingLeft: '40%', paddingTop: '5.4%', paddingRight: '.80%', }}></i>

                                    <p

                                        style={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            color: '#666666',
                                            paddingRight: '1%',
                                            paddingTop: '5%',

                                        }}
                                    >
                                        Secure Transaction
                                    </p>

                                </div>

                            </div>

                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: '53%',
                                borderLeft: '1px solid #ccc',
                                marginLeft: '10px',
                                marginRight: '10px',
                            }}
                        />
                        <div style={{ flex: MobileWidth ? 1 : 0.45, marginLeft: MobileWidth ? '0' : '2%' }}>
                            <img
                                src={images.CMClose}
                                style={{
                                    cursor: 'pointer',
                                    height: 26,
                                    width: 26,
                                    marginLeft: '92%',
                                    marginTop:'6px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClose}
                            ></img>
                            <p style={{
                                fontWeight: 700,
                                fontSize: '20px',
                                lineHeight: '25px',
                                padding: '.80%',
                                marginTop: '-20px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                Payment method
                            </p>

                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2px', }}>
                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-evenly', position: 'relative' }}>
                                    <span
                                        onClick={() => setPaymentMethod('card')}
                                        style={{
                                            padding: '5px',
                                            cursor: 'pointer',
                                            color: paymentMethod === 'card' ? '#9370db' : '#000',
                                            position: 'relative',
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        Credit/Debit Card
                                        {paymentMethod === 'card' && (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-4.5px',
                                                    left: '0',
                                                    right: '0',
                                                    height: '2px',
                                                    backgroundColor: '#9370db',
                                                }}
                                            />
                                        )}
                                    </span>
                                    <span
                                        onClick={() => setPaymentMethod('upi')}
                                        style={{
                                            padding: '5px',
                                            cursor: 'pointer',
                                            color: paymentMethod === 'upi' ? '#9370db' : '#000',
                                            position: 'relative',
                                            transition: colors.primary03,
                                        }}
                                    >
                                        UPI Mandate
                                        {paymentMethod === 'upi' && (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-4.5px',
                                                    left: '0',
                                                    right: '0',
                                                    height: '2px',
                                                    backgroundColor: '#9370db',
                                                }}
                                            />
                                        )}
                                    </span>
                                </div>

                                <div style={{ width: '100%', borderBottom: '2px solid #ccc', marginTop: '3.5px' }}></div>
                            </div>

                            {paymentMethod === 'card' ? (
                                <CreditDebitCardForm
                                    name={name}
                                    setName={setname}
                                    phone={phone}
                                    setPhone={setPhone}
                                    email={email}
                                    setEmail={setEmail}
                                    onValidatePhone={validateCreditDebitPhone}

                                />
                            ) : (
                                <UpiMandateForm
                                    name={upiname}
                                    setName={setupiname}
                                    phone={upiphone}
                                    setPhone={setupiPhone}
                                    email={upiemail}
                                    setEmail={setupiEmail}
                                    upiId={upiId}
                                    setUpiId={setUpiId}
                                    onValidatePhone={validateUpiPhone}
                                />
                            )}


                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div style={{ display: 'flex', marginBottom: '1.7%' }}>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleChange}
                                        style={{ width: '15px', height: '15px' }}
                                    />
                                    <p style={{
                                        marginTop: '-1%',
                                        paddingLeft: '1%',
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        color: '#666666',
                                        paddingRight: '0px',
                                        paddingBottom: '1.7px',
                                        whiteSpace: 'nowrap',
                                    }}>

                                        I consent to save details securely for future payments
                                    </p>
                                </div>
                                <div
                                    style={{
                                        justifyContent: 'flex-start',
                                        gap: 30,
                                        paddingTop: showAmountBreakup
                                            ? paymentMethod === 'card'
                                                ? '.3%'
                                                : '4.6%'
                                            : paymentMethod === 'card'
                                                ? '25.5%'
                                                : '30%',
                                        marginRight: '8px'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            padding: '5px 10px',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            marginLeft: '0px',
                                            marginBottom: '6px'
                                        }}
                                        onClick={() => setShowAmountBreakup(!showAmountBreakup)}
                                    >
                                        Amount Breakup
                                        <i
                                            className={`pi ${showAmountBreakup ? 'pi-angle-up' : 'pi-angle-down'}`}
                                            style={{ paddingLeft: '66%' }}
                                        ></i>
                                    </div>

                                    {showAmountBreakup && (
                                        <div
                                            style={{
                                                padding: '10px 10px 10px 10px',
                                                marginLeft: '10px',
                                                backgroundColor: '#f9f9f9',
                                                border: '1px solid #ddd',
                                                borderRadius: '8px',
                                                width: '95%',
                                                marginBottom: '8px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    lineHeight: '26px',
                                                }}
                                            >
                                                <p style={{ fontWeight: 400 }}>Top-up amount</p>
                                                <p style={{ fontWeight: 400, }}> ₹{inputValue}</p>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    lineHeight: '26px',
                                                }}
                                            >
                                                <p style={{ fontWeight: 400 }}>GST</p>
                                                <p style={{ fontWeight: 400 }}> ₹{(inputValue * 0.18).toFixed(2)}</p>
                                            </div>
                                            <hr
                                            style={{
                                                marginBottom:'2px',
                                                marginTop:'2px'
                                            }}
                                            ></hr>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    lineHeight: '26px',
                                                }}
                                            >
                                                <p style={{ fontWeight: 400 }}>Total amount</p>
                                                <p style={{ fontWeight: 400 }}>₹{totalAmount}</p>
                                            </div>
                                        </div>
                                    )}

                                    <div>
                                        <CheerioButton
                                            borderStyle={{ borderColor: colors.primary03, width: '100%' }}
                                            textStyle={{ color: '#fff' }}
                                            btnText={`Setup auto pay (₹${totalAmount})`}
                                            backColor={isButtonEnabled ? colors.primary03 : '#9370db'}
                                            onclick={handlesetupautopay}
                                            disabled={!isButtonEnabled}
                                        />
                                    </div>
                                </div>
                            </div>

                            <u style={{
                                lineHeight: '20px',
                                color: colors.primary03,
                                marginTop: '2%',
                            }}>
                                <p
                                    style={{
                                        marginTop: '2%',
                                        paddingLeft: '1%',
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        color: colors.primary03,
                                        marginLeft: '25%',
                                        marginBottom: '1%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleonetimetopup}
                                >
                                    Recharge for one time only
                                </p>
                            </u>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default AutomaticRechargeModal;