import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import { Checkbox } from '@mui/material'
import CheerioButton from '../../../Components/CheerioButton'

const ImportSettingsModal = ({ setIsImportSettingsModalOpen, verifyEmail, setVerifyEmail }) => {
  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center' style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
            <div className='Column' style={{
                justifyContent:'flex-start',
                width: 600,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:40,
                paddingBlock:24,
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:20,
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'Import settings'}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}} 
                        onClick={()=>{setIsImportSettingsModalOpen(false)}}
                    >
                    </img>
                </div>
                <div className='Row justify-content-start w-100 mt-2'>
                    <Checkbox
                        checked={verifyEmail}
                        onChange={() => { setVerifyEmail(!verifyEmail)}}
                    />
                    <p>
                        {'Automatically verify all emails before importing'}
                    </p>
                </div>
                <CheerioButton
                    btnText={'Save'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,width:'98%',marginBlock:16}} 
                    onclick={()=>{setIsImportSettingsModalOpen(false)}} 
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default ImportSettingsModal