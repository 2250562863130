import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import { useNavigate } from 'react-router';
import './styles.css';
import { mailCsv, mailOverallTemplateBasedAnalytics, mailUserSpecificCsv } from '../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ValidateEmail } from '../Chatbot/Setupbot';
import { NewLoader } from '../../Components/SpinnerLoader';
import { eventsend } from '../../Config/analyticsFunctions';
import SpinnerLoader from '../../Components/SpinnerLoader';
import {getUserDateTimeSettings} from "../../Utils/localdate"

export const MailCsvModal = ({
  show,
  setShow,
  campaign_id,
  channel,
  userSpecific,
  templateName,
  selectedNumberOfDate,
  date,
  setUserSpecificCsv,
  agentAnalytics = false,
  partnerDashboard = false,
  agentAnalyticsReportAction,
  partnerDashboardReportAction,
}) => {
  const [success, setSuccess] = useState(false);
  const auth_token = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [email, setEmail] = useState(localdata?.email ? localdata?.email : '');
  const [loading, setLoading] = useState(false); 
  const { timeZone } = getUserDateTimeSettings();

  const mailCsvApiCall = async () => {
    setLoading(true); 
    const data = {
        email: email,
    };
    try {
        const res = await mailCsv(data, auth_token); 
        if (res?.flag) {
            setSuccess(true); 
        } else {
            console.log('err in mail csv', res);
            toast.error(res.message || 'Failed to send report. Please try again.'); 
        }
    } catch (error) {
        console.error('API error:', error);
        toast.error(error || 'Failed to send report. Please try again.'); 
    } finally {
        setLoading(false); 
    }
};


  useEffect(() => {
    eventsend("Export_report_Analytics_page_clicked");
  }, [])
  const mailUserSpecificCsvApiCall = () => {
    const data = {
      email: email,
      campaign_id: campaign_id,
      channel: channel,
      timeZone
    };
    mailUserSpecificCsv(data, auth_token).then((res) => {
      if (res?.flag) {
        console.log('mail User specific csv resp', res);
        //setIsLoading(false);
        //setUserSpecificCsv(false);
        setSuccess(true);
      } else {
        console.log('err in mail user specific csv', res);
      }
    });
  };

  const mailTemplateBasedAnalyticsApiCall = () => {
    const data = {
      data: {
        templateName: templateName,
        selectedNumberOfDate: selectedNumberOfDate,
        startDate: date.startDate,
        endDate: date.endDate,
      },
      email: email,
    };
    mailOverallTemplateBasedAnalytics(data, auth_token).then((res) => {
      if (res?.data) {
        setSuccess(true);
      } else {
        toast.error('errror: ' + res?.message, { position: 'top-center' });
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="mailCsv"
      >
         {loading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                    <SpinnerLoader />
                </div>
            ) : (
        <Modal.Body style={{ paddingX: '20px' }}>
          {/* { isLoading ? <div className="d-flex flex-column align-items-center" style={{height:"100%",margin:"30%"}}>
        <NewLoader></NewLoader>
      </div> :<> */}
          <div className="d-flex justify-content-between ">
            <p style={{ fontWeight: '700', fontSize: '24px' }}>Export Report</p>

            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setShow(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <hr style={{ marginTop: '-2px' }}></hr>
          {success ? (
            <>
              <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                <img
                  src={images.CircleCheckGreen}
                  style={{
                    alignItems: 'center',
                    marginInlineEnd: 8,
                  }}
                ></img>
                <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
                  Report Shared Successfully
                </p>
                <p style={{ fontSize: '14px', color: 'grey', marginTop: '' }}>
                  Kindly check your email.
                </p>
              </div>

              <div style={{ marginLeft: '10px', marginTop: '30px' }}>
                <p style={{ fontSize: '12px', color: 'grey' }}>
                  <i>
                    Please Note:-Please anticipate a potential delay in the delivery of the email,
                    especially in the case of a substantial report(~approx 30mins). We kindly
                    request your patience during this period... If not received try again.
                  </i>
                </p>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',
                    width: '95%',
                    marginBlockStart: 12,
                    marginBlockEnd: 8,
                    marginBottom: '20px',
                  }}
                  textStyle={{ color: 'black', fontSize: 16, fontWeight: 600 }}
                  btnText={'Resend Email'}
                  backColor={'white'}
                  onclick={() => {
                    if (partnerDashboard) {
                      partnerDashboardReportAction(email);
                    } else if (agentAnalytics) {
                      agentAnalyticsReportAction(email);
                    } else if (templateName !== undefined && !userSpecific) {
                      mailTemplateBasedAnalyticsApiCall();
                    } else if (!userSpecific) {
                      mailCsvApiCall();
                      // setIsLoading(true);
                    } else {
                      mailUserSpecificCsvApiCall();
                      //setIsLoading(true);
                    }

                    setShow(false);
                    toast.success('Report Sent', { position: 'top-center' });
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <div class="form-group mt-4">
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                >
                  Email Id
                </label>
                <input
                  class="form-control shadow-none"
                  placeholder="john@cheerio.in"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  style={{ color: 'grey', fontSize: '14px', fontWeight: '400', marginLeft: '10px' }}
                >
                  You will receive your report on this email
                </label>
              </div>
              <div style={{ marginLeft: '10px', marginTop: '10rem' }}>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',

                    marginBlockStart: 12,
                    marginBlockEnd: 8,
                    marginBottom: '20px',
                    border: 'none',
                  }}
                  textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                  btnText={'Send Report'}
                  backColor={'#8E53F7'}
                  onclick={() => {
                    if (ValidateEmail(email)) {
                      if (partnerDashboard) {
                        partnerDashboardReportAction(email);
                        setSuccess(true)
                      } else if (agentAnalytics) {
                        agentAnalyticsReportAction(email);
                        setSuccess(true)
                      } else if (templateName !== undefined && !userSpecific) {
                        mailTemplateBasedAnalyticsApiCall();
                        setSuccess(true);
                      } else if (!userSpecific) {
                        mailCsvApiCall();
                        // setSuccess(true);
                        // setIsLoading(true);
                      } else {
                        mailUserSpecificCsvApiCall();
                        setSuccess(true);
                        //setIsLoading(true);
                      }
                    }
                  }}
                />
              </div>
            </div>
          )}
          {/* </>} */}
        </Modal.Body>
            )}
      </Modal>
    </div>
  );
};
