import React, { useEffect } from 'react'
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';
import { Position } from 'reactflow';
import { eventsend } from '../../../Config/analyticsFunctions';

const handleStyle = { backgroundColor: '#3200C1', height: 8, width: 8, borderRadius: 4, top: '60%' };

const CampaignSentNode = ({ data, isConnectable }) => {
    useEffect(()=>{
        eventsend("Campaign_sent_WF_clicked");
    },[])
    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center'
                style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'
                    style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: '#3200C1', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <p style={{ fontWeight: 700, color: colors.white }}>
                        {'Campaign Sent'}
                    </p>
                    <img src={images.Edit}
                        style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}></img>
                </div>
                <div className='w-100 d-flex flex-column justify-content-center align-items-center'
                    style={{ paddingInline: 16, paddingBlock: 16 }}>
                    <p style={{ fontSize: 14, fontWeight: 400, color: colors.black, width: '100%', textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-all', }}>
                        {data?.campaignName ? data?.campaignName : 'Choose a campaign'}
                    </p>
                </div>
                <CustomHandle
                    type="source"
                    position={Position.Right}
                    id="Event_UserInit"
                    style={handleStyle}
                    isConnectable={1}
                />
            </div>
        </>
    )
}

export default CampaignSentNode