import { Route, Routes, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { styledSx } from '../Tags/variableDependencies';
import { ContactsDepsColsCustom } from '../ContactsAll/variableDependencies';
import { timeStampToDated } from '../Labels/variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchLabelContactsV2API, fetchTagsContactAPI, filterLabelContacts, AddEditcustomAttributes } from '../../../Services';
import EditSidebar from '../EditSidebar';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getLocaltime } from '../../../Utils/localdate'
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { toast } from 'react-toastify';
import { formatTimeForDisplay, formatDateForInput, formatTimestampForInput, formatDateForDisplay, formatTimestampForDisplay } from '../../../Utils/formatConvertor';
import CheerioDropDown from '../../../Components/CheerioDropDown';
const EditableTable = ({ header, search, userAttributes, excepted, labelFilter, setLabelFilter, setAllData, allData }) => {
  const FilterValues = ['name', 'phone', 'email'];

  const [userAttributesColumn, setUserAttributesColumn] = useState([]);
  const { reqId, type } = useParams();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);

  const addAttributesCheck = (attribute, value, contact_id) => {
    const attrType = userAttributes?.find((a) => a.attribute === attribute)?.type;
    if (attrType === 'date') {
      value = formatDateForInput(value);
    } else if (attrType === 'timestamp') {
      value = formatTimestampForInput(value);
    }
    let token = authtoken;
    let change = {};
    change[attribute] = value;
    let data = {
      contact_id: contact_id,
      newChange: change,
    };
    AddEditcustomAttributes(token, data).then((res) => {
      if (res?.status === 200) {
        fetchData();
        toast.success('Attribute successfully updated!', {
          autoClose: 500,
          position: 'top-right',
        });
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    });
  };

  useEffect(() => {
    // console.log("user attri ---> ",userAttributes)
    if (userAttributes?.length > 0) {
      setUserAttributesColumn(() => {
        let temp = [];
        userAttributes
          .filter((item) => item?.attribute && !FilterValues.includes(item?.attribute))
          .map((item) => {
            temp.push({
              field: item?.attribute + `AAA` + uuidv4(),
              headerName: item?.attribute,
              width: 200,
              align: 'center',
              headerAlign: 'center',
              attrtype: item?.type,
              options: item?.options ? item?.options : [],
              renderCell: (params) => {
                if (item?.type === 'dropdown') {
                  return (
                    <CheerioDropDown
                      name={item?.attribute}
                      id={params.row.id}
                      options={item?.options || []}
                      initialValue={params.value}
                      // isSelectable={false}
                      handleUpdate={addAttributesCheck}
                      isIdRequired={true}
                    />
                  );
                }
                return params.value;
              }
            });
          });
        return temp;
      });
    }
  }, [userAttributes]);

  function mergeArrays(array1, array2) {
    let mergedArray = array1.slice();
    let insertIndex = mergedArray.length - 1;
    mergedArray.splice(insertIndex, 0, ...array2);
    return mergedArray;
  }

  let pageData = {
    labelId: reqId,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    search: search,
    filter: labelFilter
  };
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  let metaData;
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await filterLabelContacts(pageData, authtoken);
      if (data?.flag) {
        setAllData(data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [search, paginationModel]);
  if (type === 'tags') {
    metaData = useQuery(['contactLabelDiff', trigger, paginationModel, search], () => {
      return fetchTagsContactAPI(pageData, authtoken);
    });
  }
  // const { data, isLoading, isError } = metaData;

  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }
  let row_data = [];
  allData?.data?.docs.forEach((vls) => {
    let AttrRowObject =
      userAttributesColumn?.length > 0 &&
      userAttributesColumn.reduce((obj, item) => {
        let varName = String(item?.field).split('AAA')[0];
        let attrType = item?.attrtype;
        let options = item?.options;
        // obj[item?.field] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        const attributeValue = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        if (attrType === 'date') {
          obj[item?.field] = attributeValue !== '--' ? formatDateForDisplay(attributeValue) : '--';
        } else if (attrType === 'time') {
          obj[item?.field] = attributeValue !== '--' ? formatTimeForDisplay(attributeValue) : '--';
        } else if (attrType === 'timestamp') {
          obj[item?.field] = attributeValue !== '--' ? formatTimestampForDisplay(attributeValue) : '--';
        } else {
          obj[item?.field] = attributeValue;
        }
        return obj;
      }, {});

    row_data.push(
      Object.keys(AttrRowObject).length > 0
        ? Object.assign(
          {},
          {
            id: vls._id,
            name: vls.name,
            addedOn: getLocaltime(vls.createdAt),
            updatedOn: getLocaltime(vls.updatedAt),
            phno: vls.mobile,
            email: vls.email,
            actions: vls,
            version: vls.version,
            visible: !excepted,
          },
          AttrRowObject
        )
        : {
          id: vls._id,
          name: vls.name,
          addedOn: getLocaltime(vls.createdAt),
          updatedOn: getLocaltime(vls.updatedAt),
          phno: vls.mobile,
          email: vls.email,
          actions: vls,
          version: vls.version,
          visible: !excepted,
        }
    );
  });
  return (
    <>
      <DataGrid
        columns={
          userAttributesColumn?.length > 0
            ? mergeArrays(ContactsDepsColsCustom, userAttributesColumn)
            : ContactsDepsColsCustom
        }
        rows={row_data}
        sx={styledSx}
        paginationModel={paginationModel}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rowCount={allData?.data?.totalDocs}
      />
      <Routes>
        <Route path={'/edit'} element={<EditSidebar />} />
      </Routes>
    </>
  );
};
export default EditableTable;
