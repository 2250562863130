import { Modal } from 'react-bootstrap';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import React, { useState } from 'react';
import '../../ContactModule/Labels/components/EmailModal/index.scss';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCampaignReportEmails } from '../../../Services';
import colors from '../../../Utils/colors';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const MailCSVModal = ({ show, setShow }) => {
    const auth_token = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [email, setEmail] = useState(localdata?.email);
    const [finalState, setFinalState] = useState(false);
    const [period, setPeriod] = useState('all');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false); 

    const handleSendCSV = async () => {
        setLoading(true);
    
        try {
            const res = await getCampaignReportEmails(auth_token, email, period);
            if (res?.data?.flag) {
                setFinalState(true);
            } else {
                toast.error(res.data.message || 'Failed to send CSV. Please try again.');
            }
        } catch (err) {
            toast.error(err || 'Failed to send CSV. Please try again.');
        } finally {
            setLoading(false); 
        }
    };
    

    return (
        <>
            <Modal
                show={show}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="my-modal"
                contentClassName="mailCsv"
            >
                 {loading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                    <SpinnerLoader />
                </div>
            ) : (
                <Modal.Body className='email_modal_body' style={{ paddingX: '20px' }}>
                    <div className="d-flex justify-content-between ">
                        <p style={{ fontWeight: '700', fontSize: '24px' }}>Export Campaign</p>

                        <div className="d-flex flex-row">
                            <img
                                src={images.CMClose}
                                onClick={() => setShow(false)}
                                style={{
                                    cursor: 'pointer',
                                    height: 22,
                                    width: 22,
                                    marginInlineEnd: 8,
                                }}
                            ></img>
                        </div>
                    </div>
                    <hr style={{ marginTop: '-2px' }}></hr>
                    {finalState ? (
                        <>
                            <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                                <img
                                    src={images.CircleCheckGreen}
                                    style={{
                                        alignItems: 'center',
                                        marginInlineEnd: 8,
                                    }}
                                ></img>
                                <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
                                    CSV Shared Successfully
                                </p>
                                <p style={{ fontSize: '14px', color: 'grey', marginTop: '-10px' }}>
                                    Kindly check your email.
                                </p>
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '30px' }}>
                                <p style={{ fontSize: '12px', color: 'grey' }}>
                                    <i>
                                        Please anticipate a potential delay in the delivery of the email,
                                        especially in the case of a substantial report. We kindly request your patience
                                        during this period. If not received try again.
                                    </i>
                                </p>
                                <CheerioButton
                                    className="button"
                                    borderStyle={{
                                        height: '36px',
                                        borderRadius: '9px',
                                        width: '95%',
                                        marginBlockStart: 12,
                                        marginBlockEnd: 8,
                                        marginBottom: '20px',
                                    }}
                                    textStyle={{ color: 'black', fontSize: 16, fontWeight: 600 }}
                                    btnText={'Resend Email'}
                                    backColor={'white'}
                                    onclick={() => {
                                        const data = {
                                            email: email,
                                        };
                                        getCampaignReportEmails(auth_token, email, period).then((res) => {
                                            setFinalState(true);
                                        });
                                        setShow(false);
                                        toast.success('Report Sent', { position: 'top-center' });
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div>
                            <p>
                                Please select the time period for which you want to export the campaign data.
                            </p>
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-evenly' }}>
                                <div style={{
                                    backgroundColor: period === '7d' ? colors.darkPurple : 'white',
                                    color: period === '7d' ? 'white' : 'black',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    cursor: 'pointer',
                                    border: period === '7d' ? 'none' : `1px solid black`,
                                    fontSize: '12px',
                                }}
                                    onClick={() => setPeriod('7d')}
                                >
                                    7 days
                                </div>
                                <div style={{
                                    backgroundColor: period === '15d' ? colors.darkPurple : 'white',
                                    color: period === '15d' ? 'white' : 'black',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    cursor: 'pointer',
                                    border: period === '15d' ? 'none' : `1px solid black`,
                                    fontSize: '12px',
                                }}
                                    onClick={() => setPeriod('15d')}
                                >
                                    15 days
                                </div>
                                <div style={{
                                    backgroundColor: period === '30d' ? colors.darkPurple : 'white',
                                    color: period === '30d' ? 'white' : 'black',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    cursor: 'pointer',
                                    border: period === '30d' ? 'none' : `1px solid black`,
                                    fontSize: '12px',
                                }}
                                    onClick={() => setPeriod('30d')}
                                >
                                    30 days
                                </div>
                                <div style={{
                                    backgroundColor: period === 'all' ? colors.darkPurple : 'white',
                                    color: period === 'all' ? 'white' : 'black',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    cursor: 'pointer',
                                    border: period === 'all' ? 'none' : `1px solid black`,
                                    fontSize: '12px',
                                }}
                                    onClick={() => setPeriod('all')}
                                >
                                    Export All
                                </div>
                            </div>
                            <div className="form-group mt-4">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                                >
                                    Email Id
                                </label>
                                <input
                                    className="form-control shadow-none"
                                    placeholder="john@cheerio.in"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        if (!e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                                            setError(true);
                                            setErrorMessage('Please enter a valid email');
                                        } else {
                                            setError(false);
                                            setErrorMessage('');
                                        }
                                    }
                                    }
                                    autoComplete={true}
                                />
                                {error && (
                                    <p
                                        style={{ color: 'red', fontSize: '14px', fontWeight: '400', marginLeft: '5px', fontSize: "12px" }}
                                    >
                                        {errorMessage}
                                    </p>
                                )}
                                <label
                                    style={{ color: 'grey', fontSize: '14px', fontWeight: '400', marginLeft: '10px' }}
                                >
                                    You will receive your CSV on this email
                                </label>
                            </div>
                            <div style={{ marginLeft: '10px', marginTop: '10rem' }}>
                                <CheerioButton
                                    className="button"
                                    borderStyle={{
                                        height: '36px',
                                        borderRadius: '9px',

                                        marginBlockStart: 12,
                                        marginBlockEnd: 8,
                                        marginBottom: '20px',
                                        border: 'none',
                                    }}
                                    textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                                    btnText={'Send CSV'}
                                    backColor={'#8E53F7'}
                                    disabled={!email || error}
                                     onclick={handleSendCSV}
                                />
                            </div>
                        </div>
                    )}

                </Modal.Body>
            )}
            </Modal>
        </>
    );
};

export default MailCSVModal;