import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import { getAllAttributes, getAttributeValue, getClientAttributes } from '../../../Services';

const CheckAfterComponent = ({ item, setScheduleAtAction }) => {
  const [hours, setHours] = useState(item?.hours);
  const [minutes, setMinutes] = useState(item?.min);
  const [days, setDays] = useState(item?.days);

  useEffect(() => {
    if (minutes) {
      const timer = setTimeout(() => {
        const value = Math.max(0, Math.min(59, Number(minutes)));
        const multiplesValue = Math.floor(value / 5) * 5;
        if (multiplesValue == minutes) {
          return;
        } else {
          setMinutes(multiplesValue);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [minutes]);

  useEffect(() => {
    if (hours) {
      const htimer = setTimeout(() => {
        // const value = Math.max(0, Math.min(Number(hours)))
        const value = Math.max(0, Math.min(23, Number(hours)));
        console.log('hvalue ====> ', value);
        const multiplesValue = Math.round(value / 1) * 1;
        if (multiplesValue === hours) {
          return;
        } else {
          setHours(value);
        }
      }, 1000);
      return () => clearTimeout(htimer);
    }
  }, [hours]);

  useEffect(() => {
    if (days || hours || minutes) {
      setScheduleAtAction(days, hours, minutes);
    }
  }, [days, hours, minutes]);

  const getExecutionDate = () => {
    let execDate = new Date();
    execDate = new Date(
      execDate.getTime() +
      Number(days) * 24 * 60 * 60 * 1000 +
      Number(hours) * 60 * 60 * 1000 +
      Number(minutes) * 60 * 1000
    );
    return (
      execDate.toLocaleDateString() +
      ' , ' +
      execDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    );
  };

  return (
    <>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 py-4 px-3"
        style={{ borderBottom: `1px solid ${colors.borderwhite}` }}
      >
        <h4 style={{ fontWeight: 600, width: '100%' }}>{'Check After (optional) '}</h4>

        <p style={{ color: colors.greys01, width: '100%' }}>
          {'Condition will be checked from current date & time, Set the time accordingly.'}
        </p>
        <div className="d-flex flex-row justify-content-evenly align-items-center w-100">
          <InputGroup className="mb-2" style={{ width: '30%', marginBlock: 16 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Days'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select days"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={days}
              onChange={(e) => {
                setDays(e.target.value);
              }}
              min={0}
            // max={23}
            ></Form.Control>
          </InputGroup>
          <InputGroup className="mb-2" style={{ width: '30%', marginBlock: 16 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Hours'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select hours"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={hours}
              onChange={(e) => {
                setHours(e.target.value);
              }}
              min={0}
              max={23}
            ></Form.Control>
          </InputGroup>
          <InputGroup className="mb-2" style={{ width: '30%', marginBlock: 16 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Minutes'}
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="select minutes"
              className="w-100 btncustom dropdown"
              style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
              value={minutes}
              onChange={(e) => {
                setMinutes(e.target.value);
              }}
              min={0}
              step={5}
              max={59}
            ></Form.Control>
          </InputGroup>
        </div>
        <p style={{ color: colors.black04, width: '100%' }}>
          {`Condition will execute approx on ${getExecutionDate()}`}
        </p>
      </div>
    </>
  );
};

const ConditionRowComponent = ({
  item,
  attributesData,
  prevNode,
  setOptionsAction,
  onDeleteAction,
}) => {
  const [variable, setVariable] = useState(item?.attributeName);
  const [condition, setCondition] = useState(item?.conditionOperator);
  const [value, setValue] = useState(item?.value);
  const [variables, setVariables] = useState([]);
  const conditionArr = [
    { key: 'equal to', value: 'equal to (==)' },
    { key: 'not equal to', value: 'not equal to (!=)' },
    { key: 'greater than', value: 'greater than (>)' },
    { key: 'less than', value: 'less than (<)' },
    { key: 'contains', value: 'contains' },
    { key: 'not contains', value: 'not contains' },
    { key: 'starts with', value: 'starts with' },
    { key: 'ends with', value: 'ends with' },
  ];

  useEffect(() => {
    if (variable && item?.attributeName !== variable) {
      setVariable(item?.attributeName);
    }
    if (condition && item?.conditionOperator !== condition) {
      setCondition(item?.conditionOperator);
    }
    if (value && item?.value !== value) {
      setValue(item?.value);
    }
  }, [item]);

  useEffect(() => {
    setOptionsAction(variable, condition, value);
  }, [variable, condition, value]);

  useEffect(() => {
    if (prevNode?.data?.variable) {
      setVariables((prev) => {
        return [prevNode?.data?.variable];
      });
    }
  }, []);

  console.log('attrubute', attributesData);

  return (
    <>
      <div
        style={{ marginTop: '10px', alignItems: 'center' }}
        className="d-flex flex-row justify-content-evenly align-items-center w-100 "
      >
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Attribute'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="variable"
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
              backgroundColor: '#FAFAFA',
            }}
            value={variable}
            onChange={(e) => setVariable(e.target.value)}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            <option
              style={{
                fontWeight: 'bold',
                width: '100%',
                marginTop: 4,
                marginBottom: 4,
                borderRadius: 8,
              }}
              key="attributesDataHeading"
              disabled
            >
              {'Attributes'}
            </option>

            {attributesData.map((item) => {
              return (
                <option
                  style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                  value={item}
                >
                  {item}
                </option>
              );
            })}
            {variables.length > 0 && (
              <option
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  marginTop: 4,
                  marginBottom: 4,
                  borderRadius: 8,
                }}
                key="attributesVariableHeading"
                disabled
              >
                {'Variables'}
              </option>
            )}

            {variables.length > 0 &&
              variables.map((item) => {
                return (
                  <option
                    style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={item}
                  >
                    {item}
                  </option>
                );
              })}
          </Form.Select>
        </InputGroup>
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Condition'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="condition"
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
              backgroundColor: '#FAFAFA',
            }}
            value={condition}
            onChange={(e) => {
              setCondition(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              key="blankchoice"
              hidden
              value
            >
              {'Select'}
            </option>
            {conditionArr.length > 0 &&
              conditionArr.map((cond) => {
                return (
                  <option
                    style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                    value={cond.key}
                    key={cond.key}
                  >
                    {cond.value}
                  </option>
                );
              })}
          </Form.Select>
        </InputGroup>
        {
          <InputGroup className="mb-2" style={{ width: '30%' }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Value'}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={'Enter here'}
              className="w-100 btncustom"
              style={{
                borderRadius: 8,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys01,
                backgroundColor: '#FAFAFA',
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </InputGroup>
        }
        <img
          src={images.DeleteBlack}
          style={{
            height: 24,
            width: 20,
            objectFit: 'contain',
            marginInlineStart: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            onDeleteAction();
          }}
        ></img>
      </div>
    </>
  );
};

const AttributeConditionModal = ({ data, setData, prevNode }) => {
  const [existingData, setExistingData] = useState();
  const [attributesData, setAttributesData] = useState(['name', 'email', 'phone', 'createdAt', 'updatedAt']);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [options, setOptions] = useState(
    data?.options
      ? data?.options
      : [
        {
          defaultNode: 'defaultCondition',
        },
        {
          condition: [
            {
              attributeName: '',
              conditionOperator: '',
              value: '',
            },
          ],
          query: 'AND',
        },
      ]
  );
  //   const [scheduleAt, setScheduleAt] = useState(
  //     data?.scheduleAt
  //       ? data?.scheduleAt
  //       : {
  //           days: '00',
  //           hours: '00',
  //           min: '00',
  //         }
  //   );

  useEffect(() => {
    getUserAttributesAPICall();

    if (data?.options) {
      setExistingData(data?.options);
    }

  }, []);

  useEffect(() => {
    if (prevNode?.type === "wooWFNode") {
      setData({ ...data, wooNode: true, options: options });
    } else {
      setData({
        ...data,
        wooNode: false,
        options: options,
      });
    }
    console.log('options changed ---> ', options);
  }, [options]);

  //   useEffect(() => {
  //     setData({
  //       ...data,
  //       scheduleAt: scheduleAt,
  //     });
  //   }, [scheduleAt]);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributesData((prev) => {
          const arr = res?.data.map((item) => item.attribute);
          if (prevNode?.type === "wooWFNode") {
            return [...prev, "Product Name", ...arr];
          } else {
            return [...prev, ...arr];
          }
        });
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  return (
    <>
      <div className="w-100 h-100 flex-column justify-content-start align-items-start">
        {/* <CheckAfterComponent
          item={scheduleAt}
          setScheduleAtAction={(days, hours, minutes) => {
            setScheduleAt({
              days: days,
              hours: hours,
              min: minutes,
            });
          }}
        /> */}
        <div className="py-2" style={{ fontWeight: '600' }}>
          Conditions
        </div>

        <div className="d-flex flex-column justify-content-start align-items-center w-100 py-2 px-3">
          {/*   <div
            style={{
              padding: '20px',
              backgroundColor: '#FAFAFA',
              marginBlock: '10px',
              borderRadius: '20px',
            }}
            className="d-flex flex-column justify-content-start align-items-center w-100 py-3 px-3"
          >
        
          </div> */}
          {options.map((option, index) => {
            return (
              <>
                <div
                  style={{
                    padding: '20px',
                    backgroundColor: '#FAFAFA',
                    borderRadius: '20px',
                  }}
                  className="d-flex flex-column justify-content-start align-items-center w-100 py-3 px-3"
                >
                  {index > 0 ? (
                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                      <p style={{ fontWeight: '700', fontSize: '16px' }}>Condition Node {index}</p>
                      {options.length > 2 && (
                        <img
                          src={images.DeleteBlack}
                          style={{
                            height: 24,
                            width: 20,
                            objectFit: 'contain',
                            marginInlineStart: 8,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (index === 0) {
                              toast.error(
                                'Cannot delete first condition node, at least one condition is mandatory',
                                {}
                              );
                            } else {
                              setOptions((prevObjects) => {
                                return prevObjects.filter((obj, i) => i !== index);
                              });
                              toast.error('Deleted condition node successfully!', {

                              });
                            }
                          }}
                        ></img>
                      )}
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <p style={{ fontWeight: '700', fontSize: '16px' }}>Default Response*</p>
                      <p style={{ fontWeight: '400', fontSize: '10px' }}>
                        <i>
                          This default response will execute if none of the below conditions match.
                        </i>
                      </p>
                      {/* <Form.Control
                type="text"
                placeholder={'Enter here'}
                className="w-100 btncustom my-2"
                style={{
                  borderRadius: 8,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.greys01,
                  backgroundColor: '#FAFAFA',
                }}
                value={defaultResponse}
                onChange={(e) => {
                  setDefaultResponse(e.target.value);
                  setData({
                    ...data,
                    defaultResponse: e.target.value,
                  });
                }}
              /> */}
                    </div>
                  )}
                  {index > 0 &&
                    option.condition.length > 0 &&
                    option.condition.map((item, innerIndex) => {
                      return (
                        <div>
                          {innerIndex > 0 && (
                            <div
                              class="container"
                              style={{
                                width: '30%',
                                marginBlock: '10px',
                                backgroundColor: '#FAFAFA',
                                borderRadius: '12px',
                              }}
                            >
                              <div class="row" style={{ borderRadius: '12px', width: '100%' }}>
                                <div
                                  class="col"
                                  style={{
                                    borderRight: '1px solid black',
                                    padding: '7px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    width: '100%',
                                    borderRadius: '8px 0px 0px 8px',
                                    backgroundColor: option?.query === 'AND' ? '#8E53F7' : 'white',
                                    color: option?.query === 'AND' ? 'white' : 'black',
                                  }}
                                  onClick={() =>
                                    setOptions((prev) => {
                                      return prev.map((obj, i) => {
                                        if (i === index) {
                                          // Create a new object to modify only the condition array
                                          return {
                                            ...obj,

                                            query: 'AND',
                                          };
                                        }
                                        return obj;
                                      });
                                    })
                                  }
                                >
                                  AND
                                </div>
                                <div
                                  class="col"
                                  onClick={() =>
                                    setOptions((prev) => {
                                      return prev.map((obj, i) => {
                                        if (i === index) {
                                          // Create a new object to modify only the condition array
                                          return {
                                            ...obj,

                                            query: 'OR',
                                          };
                                        }
                                        return obj;
                                      });
                                    })
                                  }
                                  style={{
                                    backgroundColor: option?.query === 'OR' ? '#8E53F7' : 'white',
                                    color: option?.query === 'OR' ? 'white' : 'black',
                                    width: '100%',
                                    padding: '7px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    borderRadius: '0px 8px 8px 0px',
                                  }}
                                >
                                  OR
                                </div>
                              </div>
                            </div>
                          )}
                          <ConditionRowComponent
                            item={item}
                            attributesData={attributesData}
                            prevNode={prevNode}
                            setOptionsAction={(
                              attributeName,
                              conditionOperator,
                              attributeValue
                            ) => {
                              setOptions((prev) => {
                                return prev.map((obj, i) => {
                                  if (i === index) {
                                    // Create a new object to modify only the condition array
                                    return {
                                      ...obj,
                                      condition: obj.condition.map((conditionItem, indx) => {
                                        if (indx === innerIndex) {
                                          return {
                                            ...conditionItem,
                                            attributeName: attributeName,
                                            conditionOperator: conditionOperator,
                                            value: attributeValue,
                                          };
                                        }
                                        return conditionItem;
                                      }),
                                    };
                                  }
                                  return obj;
                                });
                              });
                            }}
                            onDeleteAction={() => {
                              if (innerIndex === 0) {
                                toast.error(
                                  'Cannot delete first attribute row, at least one attribute row is mandatory',
                                  {}
                                );
                              } else {
                                setOptions((prev) => {
                                  const updatedOptions = [...prev];
                                  const conditionArray = updatedOptions[index].condition;

                                  if (conditionArray.length > 1) {
                                    conditionArray.splice(innerIndex, 1);
                                    toast.error('Deleted attribute row successfully!', {

                                    });
                                  }

                                  return updatedOptions;
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  {index > 0 && (
                    <CheerioButton
                      borderStyle={{
                        marginBlockStart: 16,
                        width: '100%',
                        borderColor: '#666666',
                        borderRadius: '10px',
                      }}
                      textStyle={{
                        color: '#666666',
                        fontWeight: '600',
                      }}
                      btnText={'Add sub condition'}
                      icon={images.IconAdd}
                      backColor={'#FAFAFA'}
                      onclick={() => {
                        if (options.length <= 10) {
                          setOptions((prev) => {
                            return prev.map((obj, i) => {
                              if (i === index) {
                                return {
                                  ...obj,

                                  condition: [
                                    ...obj.condition,
                                    { attributeName: '', conditionOperator: '', value: '' },
                                  ],
                                };
                              }
                              return obj;
                            });
                          });
                          toast.success('Added a sub-condition successfully!', {});
                        } else {
                          toast.error('You can only set up to 9 conditions', {});
                        }
                      }}
                    />
                  )}
                </div>
                <hr style={{ color: 'grey' }}></hr>
              </>
            );
          })}
        </div>

        <div className="px-3" style={{ paddingBlockEnd: '5px' }}>
          {' '}
          <CheerioButton
            borderStyle={{ borderColor: '#666666' }}
            textStyle={{
              color: 'black',
              fontWeight: '600',
            }}
            btnText={'Add a node'}
            icon={images.IconAdd}
            backColor={''}
            onclick={() => {
              if (options.length <= 10) {
                setOptions((prev) => {
                  return [
                    ...prev,
                    {
                      condition: [
                        {
                          attributeName: '',
                          conditionOperator: '',
                          value: '',
                        },
                      ],
                      query: 'AND',
                    },
                  ];
                });
                toast.success('Added a condition node successfully!', {});
              } else {
                toast.error('You can only set up to 9 conditions', {});
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AttributeConditionModal;
