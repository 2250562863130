import React, { useEffect, useRef, useState } from 'react';
import images from '../../../Utils/images';
import { Autocomplete, TextField } from '@mui/material';
import DropzoneCustom from '../../Chatbot/Dropzone';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from 'react-router-dom';
import { contactlabellistV2API } from '../../../Services';
import { useSelector } from 'react-redux';
import UploadingFile from './UploadingFile';
import Mapping from './Mapping';
import AddContacts from './AddContacts';
import { useNavigate,useLocation } from 'react-router-dom'
import { eventsend } from '../../../Config/analyticsFunctions';
export const ContactMapping = ({excelLabel=false, vendorsList=false}) => {
    const [step, setStep] = useState(1);
    const [inputLabel, setInputLabel] = useState(null);
    const [error, setError] = useState('');
    const [labelName, setLabelName] = useState('');

    const [labelList, setLabelList] = useState([]);
    const [existing, setExisting] = useState(false);
    const [fileUpload, setFileUpload] = useState(false);
    // const [dragActive, setDragActive] = useState(false);
    const [CSVfile, setCSVfile] = useState();
    const [countOfProgress, setCountOfProgress] = useState(0);
    const [fetchedCSV, setFetchedCSV] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const authtoken = useSelector((state) => state.main.auth_token);



    useEffect(() => {
        if(vendorsList){
            setLabelName('unnwrap');
        }else{
            getlabellistapicall();
        }
    }, []);

    const getlabellistapicall = () => {
        let token = authtoken;
        contactlabellistV2API(token).then((res) => {
            if (res.flag) {
                let allLabels = [
                    ...res.data.v2.map((vls) => {
                        return { label: vls.label_name };
                    }),
                ];
                setLabelList(allLabels.filter((item)=>{
                    return !(
                        item?.label.includes('abandoned-cart-on') ||
                        item?.label.includes('orders-feedback-on') ||
                        item?.label.includes('orders-cancelled-on') ||
                        item?.label.includes('Workflows -') ||
                        item?.label.includes('Workflows-') ||
                        item?.label.includes('WhatsApp Contacts who') ||
                        item?.label.includes('orders-fulfilled-on') ||
                        item?.label.includes('orders-placed-on') ||
                        item?.label.includes('cod-confirmation-on')
                    );
                }));
            } else {
                console.log('err===>', res);
            }
        });
    };

    useEffect(() => {
        if (CSVfile) {
            setFileUpload(!fileUpload);
        }
    }, [CSVfile]);

    useEffect(() => {
        if (CSVfile) {
            const timer = setInterval(() => {
                setCountOfProgress((oldProgress) => {
                    if (oldProgress == 100) clearInterval(timer);
                    return Math.min(oldProgress + Math.random() * 10, 100);
                });
            }, 200);
        }
    }, [CSVfile]);




    return (
        <div className="d-flex" style={{ width: '100%', height: '100%', overflowY:'hidden' }}>
            <div
                className=""
                style={{
                    width: '20%',
                    backgroundColor: '#FFFFFF',
                    borderRight: '1px solid #CDCDCD',
                    padding: '20px',
                }}
            >
                <div className="d-flex">
                    <img
                        src={images.BackArrowBlack}
                        height={20}
                        width={20}
                        style={{ marginRight: '10px', marginTop: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            if (step === 1) {
                                // navigate("/creator/contacts/contacts");
                                if(vendorsList){
                                    navigate('/creator/vendorsList')
                                } else if(excelLabel){
                                    navigate('/creator/excelLabel');
                                }else{
                                    const previousPage = location.state?.from; 
                                    navigate(previousPage, { state: { openModal: true } });
                                }                    
                            } else if (step > 1) {
                                setStep(step - 1);
                            }
                        }}
                    ></img>
                    <p style={{ fontWeight: 700, fontSize: '18px' }}>Upload a File</p>
                </div>
                <div
                    className="d-flex justify-content-between"
                    style={{ width: '60%', padding: '5%', marginTop: '10%' }}
                >
                    <div className="d-flex flex-column align-items-start" style={{}}>
                        <img
                            height={18}
                            width={18}
                            style={{ marginTop: '0px' }}
                            src={step > 1 ? images.CircleCheckGreen : images.CircleUncheck}
                        ></img>
                        <hr
                            style={{ height: '30px', backgroundColor: step > 1 ? 'green' : 'black', width: '1px', margin: '0 auto' }}
                        ></hr>
                        <img
                            height={18}
                            width={18}
                            style={{ marginTop: '0px' }}
                            src={step > 2 ? images.CircleCheckGreen : images.CircleUncheck}
                        ></img>
                        <hr
                            style={{ height: '30px', backgroundColor: step > 2 ? 'green' : 'black', width: '1px', margin: '0 auto' }}
                        ></hr>
                        <img
                            height={18}
                            width={18}
                            style={{}}
                            src={step > 3 ? images.CircleCheckGreen : images.CircleUncheck}
                        ></img>
                    </div>
                    <div className="d-flex flex-column align-items-start" style={{ marginTop: '' }}>
                        <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>Upload a File</p>
                        <hr
                            style={{
                                height: '21%',
                                color: 'white',
                                backgroundColor: 'white',
                                width: '2px',
                                margin: '0px',
                            }}
                        ></hr>
                        <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>Mapping</p>
                        <hr
                            style={{
                                height: '23%',
                                color: 'white',
                                backgroundColor: 'white',
                                width: '2px',
                                margin: '0px',
                            }}
                        ></hr>
                        <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>Add Contacts</p>
                    </div>
                </div>
            </div>
            {step === 1 && <UploadingFile
                excelLabel={excelLabel}
                vendorsList={vendorsList}
                setExisting={setExisting}
                setLabelName={setLabelName}
                labelName={labelName}
                labelList={labelList}
                existing={existing}
                setError={setError}
                error={error}
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
                CSVfile={CSVfile}
                setCSVfile={setCSVfile}
                countOfProgress={countOfProgress}
                setCountOfProgress={setCountOfProgress}
                setStep={setStep}
                setFetchedCSV={setFetchedCSV}
            />}

            {step === 2 && <Mapping 
                excelLabel={excelLabel}
                vendorsList={vendorsList}
                fetchedCSV={fetchedCSV}
                setStep={setStep}
                CSVfile={CSVfile}
                labelName={labelName}
            />}

            {step === 3 && <AddContacts excelLabel={excelLabel} vendorsList={vendorsList} />}
        </div>
    );
};