
import { Modal } from 'react-bootstrap';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import colors from '../../Utils/colors';
import SpinnerLoader from '../../Components/SpinnerLoader';

const ImportWorkflow = ({ show, setShow, apiAction ,title}) => {
    const ModalText = title === 'Import Workflows' ? 'Note: If any workflow with the same name already exists. The same workflow will be updated and no duplicate will be created. Please verify before importing' : title === 'Import Templates'? 'Please note: All imported templates will be automatically submitted and displayed in the table. If a template with the same name already exists, it will not be created again.' :'Note: If any of the keywords with the same name already exists. The content of the keyword will be updated and no duplicate will be created. Please verify before importing'
    const authtoken = useSelector((state) => state.main.auth_token);
    const [dragActive, setDragActive] = useState(false);
    const [jsonFile, setJsonFile] = useState(null);
    const [loading, setLoading] = useState(false); 
    const fileRef = useRef(null);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleFileUpload = async () => {
        setLoading(true);
        try {
            if (jsonFile && jsonFile.type === 'application/json') {
                const base64Data = await convertToBase64(jsonFile);
                const payload = { file: base64Data };

                const response = await apiAction(payload, authtoken);

                if (response?.flag) {
                    toast.success('File Imported successfully');
                    setJsonFile(null); 
                    window?.location?.reload();
                } else {
                    toast.error(response.message ||'Error in importing json file');
                }
            } else {
                toast.error('Please upload a JSON file');
            }
        } catch (error) {
            console.error('Upload error:', error);
            toast.error('Error uploading file');
        } finally {
            setLoading(false); 
            setShow(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (file.type === 'application/json') {
                setJsonFile(file);
            } else {
                toast.error('Please upload a JSON file');
            }
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(e.type === 'dragenter' || e.type === 'dragover');
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setJsonFile(file);
        }
    };

    const handleRemoveFile = () => {
        setJsonFile(null);
    };

    return (
        <>
        <Modal
            show={show}
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="my-modal"
            contentClassName="mailCsv"
        >
            {loading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                    <SpinnerLoader />
                </div>
            ) : (
            <Modal.Body className="email_modal_body">
                <div className="d-flex flex-column" style={{ padding: '8px' }}>
                    <div className="d-flex justify-content-between align-items-center" style={{ marginTop: '-4px' }}>
                        <p style={{ fontWeight: '700', fontSize: '24px', margin: 0 }}>{title}</p>
                        <img
                            src={images.CMClose}
                            onClick={() => setShow(false)}
                            style={{
                                cursor: 'pointer',
                                height: 22,
                                width: 22,
                            }}
                            alt="Close"
                        />
                    </div>
                    <hr />

                    <p>{ModalText}</p>
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                            width: '100%',
                            height: '240px',
                            backgroundColor: colors.white02,
                            border: `1px dashed ${dragActive ? '#8E53F7' : colors.greys03}`,
                            borderRadius: 16,
                            marginBottom: '24px',
                            cursor: 'pointer',
                            marginTop: '16px',
                        }}
                        onDragEnter={handleDrag}
                        onDragOver={handleDrag}
                        onDragLeave={handleDrag}
                        onDrop={handleDrop}
                        onClick={() => fileRef.current?.click()}
                    >
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={fileRef}
                            accept=".json"
                            onChange={handleFileSelect}
                        />
                            {jsonFile ? (
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{
                                        textAlign: 'center',
                                        padding: '12px',
                                    }}
                                >
                                    <img
                                        src={images.JSON_ICON}
                                        style={{
                                            width: 80,
                                            height: 80,
                                            objectFit: 'contain',
                                            marginBottom: 8,
                                        }}
                                        alt="JSON File"
                                    />
                                    <div className="d-flex align-items-center justify-content-center">
                                        <span
                                            style={{
                                                fontSize: 16,
                                                color: colors.black,
                                                marginRight: 8,
                                                fontWeight: 400,
                                                fontFamily :'Segoe UI'
                                            }}
                                        >
                                            {jsonFile.name}
                                        </span>
                                        <img
                                            src={images.DeleteBlack}
                                            onClick={handleRemoveFile}
                                            style={{
                                                cursor: 'pointer',
                                                height: 18,
                                                width: 18,
                                                fontWeight: 600,
                                            }}
                                            alt="Remove"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                        <img
                            src={images.ImgUpIcon}
                            style={{
                                width: 80,
                                height: 80,
                                objectFit: 'contain',
                                marginBottom: 16,
                            }}
                            alt="Upload"
                        />
                        <p style={{
                            padding: 0,
                            margin: 0,
                            fontSize: 16,
                            color: colors.black,
                            fontWeight: 400,
                            fontFamily :'Segoe UI'
                          }}>
                                        
                            Upload or drag & drop .json file
                        </p>
                        </>
                    )}
                    </div>
                    <CheerioButton
                        className="button"
                        borderStyle={{ height: '44px', borderRadius: '8px', border: 'none', width: '100%' }}
                        textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                        btnText={'Import'}
                        backColor={'#8E53F7'}
                        disabled={!jsonFile}
                        onclick={handleFileUpload}
                    />
                </div>
            </Modal.Body>
            )}
        </Modal>
        </>
    );
};

export default ImportWorkflow;
