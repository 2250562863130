import React, { useState } from 'react'
import images from '../../../Utils/images'
import { useNavigate } from 'react-router-dom'
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { saveSMTPSettings } from '../../../Services';
import { toast } from 'react-toastify';

const SettingsPage = ({ SenderData, onSettingsUpdate }) => {

    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token);
    const [messagesPerDay, setMessagesPerDay] = useState(SenderData?.settings?.messagePerDay ?? '')
    const [minGapTime, setMinGapTime] = useState(SenderData?.settings?.minGapTime ?? '')
    const [replyTo, setReplyTo] = useState(SenderData?.settings?.replyTo ?? '')
    const [senderName, setSenderName] = useState(SenderData?.settings?.senderName ?? '')
    const [emailSignature, setEmailSignature] = useState(SenderData?.settings?.emailSignature ?? '')

    const saveSettingsAction = () => {
        let token = authToken;
        let data = {
            email: SenderData?.from?.email,
            setting: {
                messagePerDay: messagesPerDay,
                minGapTime: minGapTime,
                replyTo: replyTo,
                senderName: senderName,
                emailSignature: emailSignature,
            }
        };
        saveSMTPSettings(data, token).then((res) => {
            if (res?.flag) {
                toast.success('Successfully saved settings', { position: 'top-right', autoClose: true })
                onSettingsUpdate();
            } else {
                toast.error(res?.message ?? 'Unexpected error occurred, please try again later', { position: 'top-right', autoClose: true })
            }
        })
    }

    return (
        <>
            <div className='d-flex flex-row justify-content-start align-items-center mb-3'>
                <img
                    src={images.BackArrowBlack}
                    style={{ height: 20, width: 20, objectFit: 'contain', marginInlineEnd: 16, cursor: 'pointer' }}
                    onClick={() => { navigate('/creator/manageSender') }}
                >
                </img>
                <h3 style={{ fontSize: 20, fontWeight: 700 }}>
                    {SenderData?.from?.name}
                </h3>
                <p style={{ marginInline: 16, color: colors.black04 }}>
                    {`(${SenderData?.from?.email})`}
                </p>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center my-3 pb-2 w-100' style={{ borderBottom: `1px solid ${colors.black}` }}>
                <p style={{ fontWeight: 600 }}>
                    {`General`}
                </p>
                <CheerioButton
                    className="btncustom"
                    borderStyle={{
                        paddingInline: 24,
                        borderColor: colors.primary03
                    }}
                    textStyle={{ color: colors.white01, fontSize: 16, fontWeight: 600 }}
                    btnText={'Save Settings'}
                    backColor={colors.primary03}
                    onclick={() => { saveSettingsAction() }}
                />
            </div>
            <div className='d-flex flex-column justify-content-start align-items-start w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 16,
                paddingInline: 24,
                paddingBlock: 20,
                marginBlock: 24,
            }}>
                <h4 className='mb-3' style={{ fontWeight: 700 }}>
                    {'SMTP Settings'}
                </h4>
                <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                    <InputGroup className="mb-2" style={{ width: '40%' }}>
                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                            {'Messages per day'}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={messagesPerDay}
                            onChange={(e) => {
                                setMessagesPerDay(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="mb-2 ms-3" style={{ width: '40%' }}>
                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                            {'Minimum gap time (min)'}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={minGapTime}
                            onChange={(e) => {
                                setMinGapTime(e.target.value);
                            }}
                        />
                    </InputGroup>
                </div>
                <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                    <InputGroup className="mb-2" style={{ width: '40%' }}>
                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                            {'Reply to email address'}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={replyTo}
                            onChange={(e) => {
                                setReplyTo(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="mb-2 ms-3" style={{ width: '40%' }}>
                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                            {'Sender Name'}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={senderName}
                            onChange={(e) => {
                                setSenderName(e.target.value);
                            }}
                        />
                    </InputGroup>
                </div>
                <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                    <InputGroup className="mb-2" style={{ width: '82%' }}>
                        <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                            {'Email Signature'}<br />
                            <span style={{ fontWeight: 400 }}>
                                {'Enter your email signature below (manually or by copy-pasting it from your email provider)'}
                            </span>
                        </Form.Label>
                        <Form.Control
                            as={'textarea'}
                            rows={3}
                            // type="text"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={emailSignature}
                            onChange={(e) => {
                                setEmailSignature(e.target.value);
                            }}
                        />
                    </InputGroup>
                </div>
            </div>
        </>
    )
}

export default SettingsPage