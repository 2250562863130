import React, { useEffect, useRef, useState } from 'react';
import '../styles.css';
import images from '../../../Utils/images';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSelector } from 'react-redux';
import {
  V2WorkflowS3Link,
  deleteResponse,
  getAllRespones,
  getClientAttributes,
  saveResponse,
  updateResponse,
} from '../../../Services';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import UploadValidator from '../../../Components/UploadValidator';
import { getImagePath } from '../../../Utils';
import UserAttributesModal from './UserAttributesModal';
import { BsFileEarmark } from 'react-icons/bs';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { Dropdown } from 'react-bootstrap';
import SendTemplateModal from './SendTemplateModal';
import QuickReplyModal from './QuickReplyModal';
import { IoIosSearch } from 'react-icons/io';
import { InputGroup } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RxCross1 } from 'react-icons/rx';
import { eventsend } from '../../../Config/analyticsFunctions';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    id="DropdownToggle"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();

      onClick(e);
    }}
  >
    {children}
    <Tooltip title={'Add new response'}>
      <img src={images.IconAdd} />
    </Tooltip>
  </a>
));

const mobileWidth = window.innerWidth < 700 ? true : false;

const AddNewComponent = ({
  nick,
  response,
  setNickName,
  setResponse,
  setAddNew,
  saveResponseApi,
  mediaFile,
  setMediaFile,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const upDocRef = useRef();
  const upMediaRef = useRef();
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [userAttributes, setUserAttributes] = useState();
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [UAFilterValue, setUAFilterValue] = useState('');
  const [keyPressed, setKeyPressed] = useState('none');

  useEffect(() => {
    getUserAttributesAPICall();
  }, []);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const selectedAttributeAction = (value) => {
    // console.log("selected ---> ",value)
    let currentWord = getWordAtCursor();
    setResponse((prev) => {
      let before = prev.substring(0, currentWord[1]);
      let after = prev.substring(currentWord[2] + 1);
      let editedText = before + `%${value}%` + after;
      return editedText;
    });
    document.getElementById('RespInputAttr').focus();
    setUAModalOpen(false);
  };

  function getWordAtCursor() {
    const input = document.getElementById('RespInputAttr');
    const cursorPosition = input.selectionStart;
    // Get the text content of the input field
    const text = input.value;
    // Find the start and end indices of the current word
    let startIndex = cursorPosition;
    let endIndex = cursorPosition;
    // Move startIndex to the beginning of the word
    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }
    // Move endIndex to the end of the word
    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }
    // Extract the current word
    const currentWord = text.substring(startIndex, endIndex);
    // console.log("Current word:", currentWord);
    return [currentWord, startIndex, endIndex];
  }

  function isWordBoundary(char) {
    // Check if the character is a space or newline
    return char === ' ' || char === '\n';
  }

  useEffect(() => {
    if (response) {
      let currentWordArray = getWordAtCursor();
      let currentWord = currentWordArray[0];
      if (currentWord[0] === '%' && currentWord?.length > 1) {
        setUAFilterValue(currentWord?.substring(1));
        // console.log("ccccccc ",currentWord?.substring(1));
      } else {
        setUAFilterValue('');
      }
    }
  }, [response]);

  const handlefileupload = async (e, type = 'media') => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, type === 'document' ? 'document' : file?.type?.split('/')[0])) {
        setUpFile(file);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64 && upFile) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    const loadingToast = toast.loading('Uploading file. Please wait.');
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        toast.update(loadingToast, {
          render: 'File uploaded.',
          type: 'success',
          isLoading: false,
          autoClose: true

        });
        // console.log("resp s3 ---> ",res)
        setMediaFile({
          format: upFile?.type?.split('/')[1],
          s3URL: res?.data?.url,
          type: upFile?.type?.split('/')[0],
          fileName: upFile?.name ? upFile?.name : res?.data?.fileName,
        });
      } else {
        toast.update(loadingToast, {
          render: 'Error occurred while uploading file, please try again later',
          type: 'error',
          isLoading: false,
          autoClose: true

        });
        // toast.error("Error occurred while uploading media file, please try again later",{autoClose:true});
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between" style={{ padding: '10px', height: '10%' }}>
        <div className="d-flex">
          <img
            id="AddNewResponse"
            style={{ height: '20px', marginRight: '8px', marginTop: '2px', cursor: 'pointer' }}
            src={images.BackArrowBlack}
            onClick={() => setAddNew(false)}
          ></img>
          <p style={{ fontSize: '16px', fontWeight: '600' }}> Add new response</p>
        </div>
        <img
          id="CheckNewResponse"
          style={{ height: '22px', cursor: 'pointer', marginTop: '2px' }}
          src={images.IconCheck}
          onClick={() => {
            if (nick !== '' && response !== '') {
              saveResponseApi();
            } else {
              toast.error('Required field missing', { position: 'top-center' });
            }
          }}
        ></img>
      </div>
      <hr></hr>
      {UAModalOpen && (
        <UserAttributesModal
          setUserAttributesOpen={setUAModalOpen}
          userAttributesData={userAttributes}
          selectedUserAttributeAction={selectedAttributeAction}
          filterValue={UAFilterValue}
          keyPressed={keyPressed}
          setKeyPressed={setKeyPressed}
        />
      )}
      <div style={{ padding: '10px', marginTop: '-18px', flex: 1, overflowY: 'auto' }}>
        <div>
          <div className="d-flex justify-content-between">
            <label
              for="exampleFormControlInput1"
              style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
            >
              Canned Response
            </label>
            <Tooltip title="Give a nickname to your saved responses for your own reference.">
              <img height="20px" src={images.InfoCircle}></img>
            </Tooltip>
          </div>
          <input
            class="form-control shadow-none"
            placeholder="Add Canned Response"
            onChange={(e) => setNickName(e.target.value)}
            value={nick}
            required
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
          >
            Response
          </label>
          {mediaFile?.s3URL && (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center w-100 my-1">
                {mediaFile?.type === 'image' ? (
                  <img
                    src={getImagePath(mediaFile?.s3URL)}
                    style={{ height: 32, width: 32, objectFit: 'contain', marginInlineEnd: 6 }}
                  ></img>
                ) : mediaFile?.type === 'video' ? (
                  <video
                    src={getImagePath(mediaFile?.s3URL)}
                    style={{ height: 32, width: 40, objectFit: 'contain', marginInlineEnd: 6 }}
                  ></video>
                ) : (
                  <BsFileEarmark size={24} />
                )}

                <p
                  style={{
                    flex: 1,
                    fontSize: 14,
                    fontWeight: 400,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                  }}
                >
                  {mediaFile?.fileName}
                </p>
                <img
                  src={images.CMClose}
                  style={{
                    height: 20,
                    width: 20,
                    objectFit: 'contain',
                    marginInlineStart: 6,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setUpFile(null);
                    setUpFile64(null);
                    setMediaFile(null);
                  }}
                ></img>
              </div>
            </>
          )}
          <textarea
            id="RespInputAttr"
            rows="5"
            class="form-control shadow-none"
            placeholder="Add response"
            style={{ resize: 'none' }}
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            required
            onKeyDown={(event) => {
              if (event.key === '%' && event.shiftKey) {
                setUAModalOpen(true);
              } else if (
                event.key === 'Backspace' &&
                response.charAt(event.target.selectionStart - 1) === '%'
              ) {
                setUAModalOpen(false);
              } else if (event.key === 'ArrowUp' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Up');
              } else if (event.key === 'ArrowDown' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Down');
              } else if (event.key === 'Tab' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Tab');
              } else if (event.code === 'Space') {
                setUAModalOpen(false);
              }
            }}
          />
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center w-100 my-2">
          <Tooltip title="Upload image/video">
            <img
              src={images.CRphotoSelect}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInline: 4,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (!upFile64) {
                  upMediaRef.current.click();
                }
              }}
            ></img>
          </Tooltip>
          <Tooltip title="Upload document">
            <img
              src={images.CRdocumentSelect}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInline: 4,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (!upFile64) {
                  upDocRef.current.click();
                }
              }}
            ></img>
          </Tooltip>
          <Tooltip title="File upload formats and sizes info">
            <img
              src={images.InfoCircleGrey}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInline: 4,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(
                  'https://developers.facebook.com/docs/whatsapp/on-premises/reference/media/',
                  '_blank'
                );
              }}
            ></img>
          </Tooltip>
        </div>
        <input
          type="file"
          id="DocumentUpload"
          style={{ display: 'none' }}
          ref={upDocRef}
          accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.pdfx,.csv"
          onChange={(e) => {
            handlefileupload(e, 'document');
          }}
        />
        <input
          type="file"
          id="MediaUpload"
          style={{ display: 'none' }}
          ref={upMediaRef}
          accept="image/*,video/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
      </div>
    </>
  );
};

const EditComponent = ({
  nick,
  response,
  setNickName,
  setResponse,
  setEdit,
  updateResponseApi,
  deleteResponseApi,
  mediaFile,
  setMediaFile,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const upDocRef = useRef();
  const upMediaRef = useRef();
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [userAttributes, setUserAttributes] = useState();
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [UAFilterValue, setUAFilterValue] = useState('');
  const [keyPressed, setKeyPressed] = useState('none');

  useEffect(() => {
    getUserAttributesAPICall();
  }, []);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const selectedAttributeAction = (value) => {
    // console.log("selected ---> ",value)
    let currentWord = getWordAtCursor();
    setResponse((prev) => {
      let before = prev.substring(0, currentWord[1]);
      let after = prev.substring(currentWord[2] + 1);
      let editedText = before + `%${value}%` + after;
      return editedText;
    });
    document.getElementById('RespInputAttr').focus();
    setUAModalOpen(false);
  };

  function getWordAtCursor() {
    const input = document.getElementById('RespInputAttr');
    const cursorPosition = input.selectionStart;
    // Get the text content of the input field
    const text = input.value;
    // Find the start and end indices of the current word
    let startIndex = cursorPosition;
    let endIndex = cursorPosition;
    // Move startIndex to the beginning of the word
    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }
    // Move endIndex to the end of the word
    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }
    // Extract the current word
    const currentWord = text.substring(startIndex, endIndex);
    // console.log("Current word:", currentWord);
    return [currentWord, startIndex, endIndex];
  }

  function isWordBoundary(char) {
    // Check if the character is a space or newline
    return char === ' ' || char === '\n';
  }

  useEffect(() => {
    if (response) {
      let currentWordArray = getWordAtCursor();
      let currentWord = currentWordArray[0];
      if (currentWord[0] === '%' && currentWord?.length > 1) {
        setUAFilterValue(currentWord?.substring(1));
        // console.log("ccccccc ",currentWord?.substring(1));
      } else {
        setUAFilterValue('');
      }
    }
  }, [response]);

  const handlefileupload = async (e, type = 'media') => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, type === 'document' ? 'document' : file?.type?.split('/')[0])) {
        setUpFile(file);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',

      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64 && upFile) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    const loadingToast = toast.loading('Uploading file. Please wait.');
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        toast.update(loadingToast, {
          render: 'File uploaded.',
          type: 'success',
          autoClose: true,
          isLoading: false,

        });
        // console.log("resp s3 ---> ",res)
        setMediaFile({
          format: upFile?.type?.split('/')[1],
          s3URL: res?.data?.url,
          type: upFile?.type?.split('/')[0],
          fileName: upFile?.name ? upFile?.name : res?.data?.fileName,
        });
      } else {
        toast.update(loadingToast, {
          render: 'Error occurred while uploading file, please try again later',
          type: 'error',
          autoClose: true,
          isLoading: false,


        });
        // toast.error("Error occurred while uploading media file, please try again later",{autoClose:true});
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between" style={{ padding: '10px', height: '10%' }}>
        <div className="d-flex">
          <img
            style={{ height: '20px', marginRight: '8px', marginTop: '2px', cursor: 'pointer' }}
            src={images.BackArrowBlack}
            onClick={() => setEdit(false)}
          ></img>
          <p style={{ fontSize: '16px', fontWeight: '600' }}> Edit response</p>
        </div>
        <div>
          <img
            style={{ height: '22px', cursor: 'pointer', marginTop: '2px', marginRight: '5px' }}
            src={images.DeleteBlack}
            onClick={() => {
              deleteResponseApi();
            }}
          ></img>
          <img
            style={{ height: '22px', cursor: 'pointer', marginTop: '2px' }}
            src={images.IconCheck}
            onClick={() => {
              if (nick !== '' && response !== '') {
                updateResponseApi();
              } else {
                toast.error('Required field missing', { position: 'top-center' });
              }
            }}
          ></img>
        </div>
      </div>
      <hr></hr>

      {UAModalOpen && (
        <UserAttributesModal
          setUserAttributesOpen={setUAModalOpen}
          userAttributesData={userAttributes}
          selectedUserAttributeAction={selectedAttributeAction}
          filterValue={UAFilterValue}
          keyPressed={keyPressed}
          setKeyPressed={setKeyPressed}
        />
      )}

      <div style={{ padding: '10px', marginTop: '-18px' }}>
        <div>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
          >
            Nickname
          </label>
          <input
            class="form-control shadow-none"
            placeholder="Add Nickname"
            onChange={(e) => setNickName(e.target.value)}
            value={nick}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '14px', color: 'black' }}
          >
            Response
          </label>

          {mediaFile?.s3URL && (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center w-100 my-1">
                {mediaFile?.type === 'image' ? (
                  <img
                    src={getImagePath(mediaFile?.s3URL)}
                    style={{ height: 32, width: 32, objectFit: 'contain', marginInlineEnd: 6 }}
                  ></img>
                ) : mediaFile?.type === 'video' ? (
                  <video
                    src={getImagePath(mediaFile?.s3URL)}
                    style={{ height: 32, width: 40, objectFit: 'contain', marginInlineEnd: 6 }}
                  ></video>
                ) : (
                  <BsFileEarmark size={24} />
                )}
                <p
                  style={{
                    flex: 1,
                    fontSize: 14,
                    fontWeight: 400,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                  }}
                >
                  {mediaFile?.fileName}
                </p>
                <img
                  src={images.CMClose}
                  style={{
                    height: 20,
                    width: 20,
                    objectFit: 'contain',
                    marginInlineStart: 6,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setUpFile(null);
                    setUpFile64(null);
                    setMediaFile(null);
                  }}
                ></img>
              </div>
            </>
          )}

          <textarea
            id="RespInputAttr"
            rows="5"
            class="form-control shadow-none"
            placeholder="Add response"
            onChange={(e) => setResponse(e.target.value)}
            style={{ resize: 'none' }}
            value={response}
            onKeyDown={(event) => {
              if (event.key === '%' && event.shiftKey) {
                setUAModalOpen(true);
              } else if (
                event.key === 'Backspace' &&
                response.charAt(event.target.selectionStart - 1) === '%'
              ) {
                setUAModalOpen(false);
              } else if (event.key === 'ArrowUp' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Up');
              } else if (event.key === 'ArrowDown' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Down');
              } else if (event.key === 'Tab' && UAModalOpen) {
                event.preventDefault();
                setKeyPressed('Tab');
              } else if (event.code === 'Space') {
                setUAModalOpen(false);
              }
            }}
          />
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center w-100 my-2">
          <Tooltip title="Upload image/video">
            <img
              src={images.CRphotoSelect}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInline: 4,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (!upFile64) {
                  upMediaRef.current.click();
                }
              }}
            ></img>
          </Tooltip>
          <Tooltip title="Upload document">
            <img
              src={images.CRdocumentSelect}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInline: 4,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (!upFile64) {
                  upDocRef.current.click();
                }
              }}
            ></img>
          </Tooltip>
          <Tooltip title="File upload formats and sizes info">
            <img
              src={images.InfoCircleGrey}
              style={{
                height: 24,
                width: 24,
                objectFit: 'contain',
                marginInline: 4,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(
                  'https://developers.facebook.com/docs/whatsapp/on-premises/reference/media/',
                  '_blank'
                );
              }}
            ></img>
          </Tooltip>
        </div>
        <input
          type="file"
          id="DocumentUpload"
          style={{ display: 'none' }}
          ref={upDocRef}
          accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.pdfx,.csv"
          onChange={(e) => {
            handlefileupload(e, 'document');
          }}
        />
        <input
          type="file"
          id="MediaUpload"
          style={{ display: 'none' }}
          ref={upMediaRef}
          accept="image/*,video/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
      </div>
    </>
  );
};

export const SavedResponses = ({ setButtonMsg, setText, setShow, setMedia, attributesList }) => {
  const [addNew, setAddNew] = useState(false);
  const [data, setData] = useState(true);
  const [edit, setEdit] = useState(false);
  const token = useSelector((state) => state.main.auth_token);
  const [nickName, setNickName] = useState('');
  const [response, setResponse] = useState('');
  const [values, setValues] = useState([]);
  const [responseId, setResponseId] = useState('');
  const [mediaFile, setMediaFile] = useState();
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [templateData, setTemplateData] = useState();
  const [saveTemplate, setSaveTemplate] = useState(true);
  const [InputOptions, setInputOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    eventsend("Saved_replies_Inbox_page_clicked");
  }, [])

  const getAllResponsesApiCall = () => {
    getAllRespones(token).then((res) => {
      if (res?.flag) {
        setData(true);
        setValues(res?.data);
      } else {
        toast.error(res);
      }
    });
  };

  const saveResponseApiCall = () => {
    const data = {
      nickName: nickName,
      response: response,
      file: mediaFile,
    };
    saveResponse(token, data).then((res) => {
      if (res?.flag) {
        setAddNew(false);
        toast.success('Response saved', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };

  const SearchResponse = () => {
    let searchData = values.filter((value) => {
      if (searchText === '/') {
        return true;
      }
      let subText = searchText?.substring(1);
      if (
        searchText &&
        searchText[0] === '/' &&
        (value?.response.toLowerCase().includes(subText.toLowerCase()) ||
          value?.nickName.toLowerCase().includes(subText.toLowerCase()))
      ) {
        return value;
      } else if (
        value?.response.toLowerCase().includes(subText.toLowerCase()) ||
        value?.nickName.toLowerCase().includes(subText.toLowerCase())
      ) {
        return value;
      } else {
        return false;
      }
    });
    console.log('SEarch ', searchText, 'ffee', searchData);
    return (
      <>
        {searchData.map((value) => (
          <div
            id={value?._id}
            onClick={(e) => {
              e.stopPropagation();
              if (value?.templateResponse) {
                setTemplateData({ ...value?.templateData, nickName: value.nickName });
                setSaveTemplate(false);
                setShowSaveTemplateModal(true);
              } else {
                setText(value?.response);
                setMedia(value?.file?.s3URL, value?.file?.fileType, value?.file?.fileName);
                if (value?.buttons.length > 0) {
                  setButtonMsg(value?.buttons);
                } else {
                  setButtonMsg([]);
                }
                setShow(false);
              }
            }}
            style={{ marginBottom: '10px', padding: '10px' }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <p style={{ fontWeight: '600', height: '10px', marginBottom: '10px' }}>
                  {value.nickName}
                </p>
                <p style={{ color: 'grey' }}>{value.response}</p>
              </div>
              <div className="d-flex">
                {value?.file && !value?.templateResponse && value?.file?.fileType !== '' && (
                  <Tooltip
                    title={
                      value?.file?.fileType == 'image'
                        ? 'This message contains image.'
                        : value?.file?.fileType == 'document'
                          ? 'This message contains document.'
                          : 'This message contains video.'
                    }
                  >
                    <img
                      style={{ height: '20px' }}
                      src={
                        value?.file?.fileType == 'image'
                          ? images.SBMedia
                          : value?.file?.fileType == 'document'
                            ? images.DocIcon
                            : images.VideoIcon
                      }
                    ></img>
                  </Tooltip>
                )}
                {value?.buttons.length > 0 && (
                  <Tooltip title="This message contains buttons.">
                    <img style={{ height: '18px' }} src={images.ReplyButtonIcon}></img>
                  </Tooltip>
                )}{' '}
                <img
                  style={{ height: '20px', cursor: 'pointer' }}
                  src={images.EditSender}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (value?.templateResponse) {
                      setTemplateData({
                        ...value?.templateData,
                        nickName: value.nickName,
                      });
                      setResponseId(value._id);
                      setSaveTemplate(true);
                      setShowSaveTemplateModal(true);
                    } else {
                      setResponse(value.response);
                      setNickName(value.nickName);
                      setResponseId(value._id);
                      setMediaFile({
                        format: value?.file?.format,
                        s3URL: value?.file?.s3URL,
                        type: value?.file?.fileType,
                        fileName: value?.file?.fileName,
                      });
                      if (value?.buttons.length > 0) {
                        setInputOptions(value?.buttons);
                      } else {
                        setInputOptions([]);
                      }
                      setEdit(true);
                    }
                  }}
                ></img>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const updateResponseApiCall = () => {
    const data = {
      nickName: nickName,
      response: response,
      file: mediaFile,
      buttons: InputOptions,
    };
    updateResponse(token, responseId, data).then((res) => {
      if (res?.flag) {
        setEdit(false);
        toast.success('Message updated', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };

  const deleteResponseApiCall = () => {
    deleteResponse(token, responseId).then((res) => {
      if (res?.flag) {
        setEdit(false);
        toast.success('Deleted Successfully', { position: 'top-center' });
      } else {
        toast.error(res);
      }
    });
  };

  useEffect(() => {
    if (addNew) {
      setNickName('');
      setResponse('');
    }
  }, [addNew]);

  useEffect(() => {
    getAllResponsesApiCall();
  }, [addNew, edit, showSaveTemplateModal]);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div
        class="responses"
        style={{
          left: mobileWidth ? '0%' : '1%',
          width: mobileWidth ? '100%' : '25%',
          bottom: mobileWidth ? 60 : 60,
        }}
      >
        {data ? (
          addNew ? (
            <QuickReplyModal
              nick={nickName}
              response={response}
              setNickName={setNickName}
              setResponse={setResponse}
              setAddNew={setAddNew}
              saveResponseApi={saveResponseApiCall}
              mediaFile={mediaFile}
              setMediaFile={setMediaFile}
              attributeList={attributesList}
              show={addNew}
              setShow={setAddNew}
              InputOptions={InputOptions}
              setInputOptions={setInputOptions}
            ></QuickReplyModal>
          ) : edit ? (
            <QuickReplyModal
              nick={nickName}
              response={response}
              setNickName={setNickName}
              setResponse={setResponse}
              setAddNew={setEdit}
              updateResponseApi={updateResponseApiCall}
              deleteResponseApi={deleteResponseApiCall}
              edit={true}
              mediaFile={mediaFile}
              setMediaFile={setMediaFile}
              attributeList={attributesList}
              show={edit}
              setShow={setEdit}
              InputOptions={InputOptions}
              setInputOptions={setInputOptions}
            ></QuickReplyModal>
          ) : (
            <>
              <div
                className="d-flex justify-content-between"
                style={{ padding: '10px', height: '10%' }}
              >
                <p style={{ fontSize: '16px', fontWeight: '600' }}>Saved Replies</p>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                  <Dropdown.Menu style={{ backgroundColor: '#f9f9f9' }}>
                    {/* <Dropdown.Header
                      style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}
                    ></Dropdown.Header> */}
                    <Dropdown.Item
                      onClick={() => {
                        setMediaFile();

                        setAddNew(true);
                      }}
                      className="d-flex"
                    >
                      {' '}
                      {/* <img
                        height={18}
                        width={18}
                        style={{ marginRight: '5px' }}
                        src={images.distribute}
                      ></img> */}
                      Quick Reply
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setMediaFile();
                        setSaveTemplate(true);
                        setTemplateData();
                        setShowSaveTemplateModal(true);
                      }}
                    >
                      {/* <img height={15} src={images.download} style={{ marginRight: '5px' }}></img>{' '} */}
                      Template Reply{' '}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <hr></hr>
              <div
                class="response-content"
                style={{
                  padding: '10px',
                  marginTop: '-17px',
                  fontSize: '14px',
                  height: '82%',
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  scrollbarColor: 'white',
                }}
              >
                <InputGroup className="my-2" style={{ zIndex: 1 }}>
                  {/* <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                    {'Sear'}
                  </Form.Label> */}
                  <InputGroup.Text
                    style={{
                      borderRightWidth: 0,
                      borderColor: '#B3B3B3',
                      paddingInline: 8,
                    }}
                  >
                    <IoIosSearch size={16} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search here"
                    style={{
                      borderTopRightRadius: searchText ? '0' : 8,
                      borderBottomRightRadius: searchText ? '0' : 8,
                      borderColor: '#B3B3B3',
                      borderLeftWidth: 0,
                      paddingInlineStart: 0,
                      zIndex: 1,
                      outline: 'none',
                      boxShadow: 'none',
                      borderRightWidth: searchText ? '0' : '',
                    }}
                    onFocus={(e) => {
                      e.target.style.outline = 'none'; // Remove outline when focused
                      e.target.style.borderColor = '#B3B3B3';
                    }}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  {searchText && (
                    <InputGroup.Text
                      onClick={() => {
                        setSearchText('');
                      }}
                      style={{
                        borderLeftWidth: 0,
                        borderColor: '#B3B3B3',
                        paddingInline: 8,
                        cursor: 'pointer',
                      }}
                    >
                      <RxCross1 size={16} />
                    </InputGroup.Text>
                  )}
                </InputGroup>
                {searchText && <SearchResponse />}
                {!searchText &&
                  values.map((value) => (
                    <div
                      id={value?._id}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (value?.templateResponse) {
                          setTemplateData({ ...value?.templateData, nickName: value.nickName });
                          setSaveTemplate(false);
                          setShowSaveTemplateModal(true);
                        } else {
                          setText(value?.response);
                          setMedia(
                            value?.file?.s3URL,
                            value?.file?.fileType,
                            value?.file?.fileName
                          );
                          if (value?.buttons.length > 0) {
                            setButtonMsg(value?.buttons);
                          } else {
                            setButtonMsg([]);
                          }
                          setShow(false);
                        }
                      }}
                      style={{ marginBottom: '10px', padding: '10px' }}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <p style={{ fontWeight: '600', height: '10px', marginBottom: '10px' }}>
                            {value.nickName}
                          </p>
                          <p style={{ color: 'grey' }}>{value.response}</p>
                        </div>
                        <div className="d-flex">
                          {value?.file &&
                            !value?.templateResponse &&
                            value?.file?.fileType !== '' && (
                              <Tooltip
                                title={
                                  value?.file?.fileType == 'image'
                                    ? 'This message contains image.'
                                    : value?.file?.fileType == 'document'
                                      ? 'This message contains document.'
                                      : 'This message contains video.'
                                }
                              >
                                <img
                                  style={{ height: '20px' }}
                                  src={
                                    value?.file?.fileType == 'image'
                                      ? images.SBMedia
                                      : value?.file?.fileType == 'document'
                                        ? images.DocIcon
                                        : images.VideoIcon
                                  }
                                ></img>
                              </Tooltip>
                            )}
                          {value?.buttons.length > 0 && (
                            <Tooltip title="This message contains buttons.">
                              <img style={{ height: '18px' }} src={images.ReplyButtonIcon}></img>
                            </Tooltip>
                          )}{' '}
                          <img
                            style={{ height: '20px', cursor: 'pointer' }}
                            src={images.EditSender}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (value?.templateResponse) {
                                setTemplateData({
                                  ...value?.templateData,
                                  nickName: value.nickName,
                                });
                                setResponseId(value._id);
                                setSaveTemplate(true);
                                setShowSaveTemplateModal(true);
                              } else {
                                setResponse(value.response);
                                setNickName(value.nickName);
                                setResponseId(value._id);
                                setMediaFile({
                                  format: value?.file?.format,
                                  s3URL: value?.file?.s3URL,
                                  type: value?.file?.fileType,
                                  fileName: value?.file?.fileName,
                                });
                                if (value?.buttons.length > 0) {
                                  setInputOptions(value?.buttons);
                                } else {
                                  setInputOptions([]);
                                }
                                setEdit(true);
                              }
                            }}
                          ></img>
                        </div>
                      </div>
                    </div>
                  ))}
                {showSaveTemplateModal && (
                  <SendTemplateModal
                    show={showSaveTemplateModal}
                    setShow={setShowSaveTemplateModal}
                    saveTemplate={saveTemplate}
                    attributesList={attributesList}
                    existingData={templateData}
                    responseId={responseId}
                  ></SendTemplateModal>
                )}
              </div>
            </>
          )
        ) : addNew ? (
          <QuickReplyModal
            nick={nickName}
            response={response}
            setNickName={setNickName}
            setResponse={setResponse}
            setAddNew={setAddNew}
            saveResponseApi={saveResponseApiCall}
            mediaFile={mediaFile}
            setMediaFile={setMediaFile}
            attributeList={attributesList}
            show={addNew}
            setShow={setAddNew}
            InputOptions={InputOptions}
            setInputOptions={setInputOptions}
          ></QuickReplyModal>
        ) : (
          <>
            <div
              className="d-flex justify-content-between"
              style={{ padding: '10px', height: '10%' }}
            >
              <p style={{ fontSize: '16px', fontWeight: '600' }}>Saved Replies</p>
              <img
                style={{ height: '22px', cursor: 'pointer' }}
                src={images.IconAdd}
                onClick={() => {
                  setAddNew(true);
                }}
              ></img>
            </div>
            <hr></hr>

            <div
              className="d-flex align-items-center flex-column"
              style={{ fontSize: '14px', paddingTop: '15%' }}
            >
              <img style={{ height: '15%', width: '15%' }} src={images.IconReply}></img>
              <p style={{ textAlign: 'center', width: '70%' }}>
                Save responses for frequently asked questions to reply quickly
              </p>
              <p
                style={{ fontWeight: '600', color: 'blue', cursor: 'pointer' }}
                onClick={() => {
                  setAddNew(true);
                }}
              >
                Add New Response
              </p>
            </div>
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export const MatchResponses = ({
  text,
  values,
  setText,
  event,
  setMedia,
  attributesList,
  setShow,
  setButtonMsg,
}) => {
  const [selected, setSelected] = useState('');
  const [arr, setArr] = useState(values);
  const [responseId, setResponseId] = useState('');
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [templateData, setTemplateData] = useState();

  let data = values.filter((value) => {
    if (text === '/') {
      return true;
    }
    let subText = text?.substring(1);
    if (
      text &&
      text[0] === '/' &&
      (value?.response.toLowerCase().includes(subText.toLowerCase()) ||
        value?.nickName.toLowerCase().includes(subText.toLowerCase()))
    ) {
      return value;
    } else if (
      value?.response.toLowerCase().includes(subText.toLowerCase()) ||
      value?.nickName.toLowerCase().includes(subText.toLowerCase())
    ) {
      return value;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (selected === '' && text !== '') {
      setSelected(data[0]);
    } else if (data.length !== arr.length) {
      setArr(data);
      setSelected(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (arr) {
      const newVal = arr.indexOf(selected);
      if (event.code === 'Tab') {
        event.preventDefault();
        if (selected?.templateResponse) {
          setTemplateData({ ...selected?.templateData, nickName: selected.nickName });
          setResponseId(selected._id);
          setShowSaveTemplateModal(true);
        } else {
          setText(selected?.response);
          setMedia(selected?.file?.s3URL, selected?.file?.fileType, selected?.file?.fileName);
          if (selected?.buttons?.length > 0) {
            setButtonMsg(selected?.buttons);
          } else {
            setButtonMsg([]);
          }
          setShow(false);
        }
      } else if (event.code === 'ArrowDown') {
        event.preventDefault();

        if (arr[newVal + 1]) {
          setSelected(arr[newVal + 1]);
          document
            .getElementById('cr' + arr[newVal + 1]?._id)
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (event.code === 'ArrowUp') {
        event.preventDefault();

        if (arr[newVal - 1]) {
          setSelected(arr[newVal - 1]);
          document
            .getElementById('cr' + arr[newVal - 1]?._id)
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [event]);

  if (!data.length) {
    return null;
  }

  return (
    <div
      class="responses-matched"
      style={{
        right: mobileWidth ? '0%' : '32%',
        width: mobileWidth ? '100%' : '25%',
        bottom: mobileWidth ? 60 : 60,
      }}
    >
      <div className="d-flex justify-content-between" style={{ padding: '10px', height: '10%' }}>
        <p style={{ fontSize: '16px', fontWeight: '600' }}>Saved Replies</p>
        {/* <img
          style={{ height: '22px', cursor: 'pointer' }}
          src={images.IconAdd}
          onClick={() => {
            setAddNew(true);
          }}
        ></img> */}
      </div>
      <p style={{ color: 'grey', fontSize: '12px', marginLeft: '10px' }}>
        Press tab to select message
      </p>
      <hr></hr>
      <div
        class="response-content"
        style={{
          padding: '10px',
          marginTop: '-17px',
          fontSize: '14px',
          height: '81%',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'white',
        }}
      >
        {data.length &&
          data.map((value) => {
            return (
              <div
                id={'cr' + value?._id}
                key={value?._id}
                onClick={() => {
                  if (value?.templateResponse) {
                    setTemplateData({ ...value?.templateData, nickName: value.nickName });
                    setResponseId(value._id);
                    setShowSaveTemplateModal(true);
                  } else {
                    setText(value?.response);
                    setMedia(value?.file?.s3URL, value?.file?.fileType, value?.file?.fileName);
                    if (value?.buttons.length > 0) {
                      setButtonMsg(value?.buttons);
                    } else {
                      setButtonMsg([]);
                    }
                    data = [];
                    setShow(false);
                  }
                }}
                style={{
                  borderRadius: '12px',
                  marginBottom: '2px',
                  backgroundColor: selected == value ? '#f1f1f1' : '',
                  paddingLeft: '10px',
                  paddingTop: '5px',
                }}
              >
                <div className="d-flex justify-content-between" style={{}}>
                  <div>
                    <p style={{ fontWeight: '600', height: '10px', marginBottom: '10px' }}>
                      {value.nickName}
                    </p>
                    <p style={{ color: 'grey' }}>{value.response}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {showSaveTemplateModal && (
        <SendTemplateModal
          show={showSaveTemplateModal}
          setShow={setShowSaveTemplateModal}
          saveTemplate={false}
          attributesList={attributesList}
          existingData={templateData}
          responseId={responseId}
          matchResponseShow={setShow}
        ></SendTemplateModal>
      )}
    </div>
  );
};
