import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import images from '../../../Utils/images'
import { useNavigate, useParams } from 'react-router-dom'
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ImportSettingsModal from '../Components/ImportSettingsModal';
import { IoMdSettings } from "react-icons/io";
import CheerioButton from '../../../Components/CheerioButton';
import ReactFlow, {
    useEdgesState,
    useNodesState,
  } from 'reactflow';
import 'reactflow/dist/style.css';
import Helper from '../../../Utils/Helper';
import AddaStepNode from '../Nodes/AddaStepNode';
import { v4 as uuidv4 } from 'uuid';
import AddStepModal from '../Components/AddStepModal';
import SequenceFlow from '../Components/SequenceFlow';
import { useSelector } from 'react-redux';
import { contactlabellistWithoutCountAPI, createEmptySequence, sendSequenceAnnouncement, sendSequenceTest, updateSequence } from '../../../Services';
import { toast } from 'react-toastify';
import CampaignSetup from '../Components/CampaignSetup';
import CampaignReviewSend from '../Components/CampaignReviewSend';
import VerifyEmailModal from '../Components/VerifyEmailModal';
import RunTestModal from '../Components/RunTestModal';

const getId = () => uuidv4();


const CreateSequence = () => {

    const navigate = useNavigate();
    const [campaignName, setCampaignName] = useState('');
    const [receiveOn, setReceiveOn] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [isISModalOpen, setIsISModalOpen] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
    const [labelList, setLabelList] = useState();
    const [selectedLabel, setSelectedLabel] = useState();
    const [nodes, setNodes, onNodesChange] = useNodesState([
            {
                id: getId(),
                type: 'addStepNode',
                position: { x:0, y:0}
            }
        ])
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedSenderID, setSelectedSenderID] = useState([])
    const [testEmail, setTestEmail] = useState(''); 
    const [isRTModalOpen, setIsRTModalOpen] = useState(false);
    const [sequenceID, setSequenceID] = useState('');

    const auth_token = useSelector((state) => state.main.auth_token);

    useEffect(()=>{
        setIsISModalOpen(true);
        getlabellistapicall();
    },[])

    const steps = [
        'Audience',
        'Create Sequence',
        'Campaign Setup',
        'Review & Publish'
    ];

    const getlabellistapicall = () => {
        let token = auth_token;
        contactlabellistWithoutCountAPI(token).then((res) => {
            if (res?.flag) {
                setLabelList(res.data.v1.filter((item)=>{
                    return !(
                        item?.label_name.includes('abandoned-cart-on') ||
                        item?.label_name.includes('orders-feedback-on') ||
                        item?.label_name.includes('orders-cancelled-on') ||
                        item?.label_name.includes('Workflows -') ||
                        item?.label_name.includes('Workflows-') ||
                        item?.label_name.includes('WhatsApp Contacts who') ||
                        item?.label_name.includes('orders-fulfilled-on') ||
                        item?.label_name.includes('orders-placed-on') ||
                        item?.label_name.includes('cod-confirmation-on')
                    )
                }));
            } else {
                toast.error('Unable to fetch labels list, please try again later',{position:'top-right',autoClose:true})
            }
        });
    };

    const startSequenceAction = (type) => {
        if(sequenceID){
            const toastId = toast.loading("Preparing to send announcement...");
            if(type === 'test'){
                sendSequenceTestAction(sequenceID, toastId);
            }else if(type === 'announcement'){
                sendSequenceAnnouncementAction(sequenceID, toastId);
            }
        }else{
            createSequenceAction(type)
        }
    }

    const createSequenceAction = (type) => {
        const toastId = toast.loading("Creating Sequence...",{position:'top-right',autoClose:true})
        let token = auth_token;
        let data = {
            campaignName: campaignName,
        };
        createEmptySequence(data, token).then((res)=>{
            if(res?.flag){
                setSequenceID(res?.data?._id)
                toast.update(toastId,{
                    render:'Sequence created successfully. Updating sequence...',
                    type: toast.TYPE.INFO,
                    isLoading:true,
                    position:'top-right',
                    autoClose:true,
                })
                updateSequenceAction(res?.data?._id, type, toastId)
                console.log('sequence created ---> ',res)
            }else{
                toast.update(toastId,{
                    render:'Sequence creation failed.',
                    type: toast.TYPE.ERROR,
                    isLoading:false,
                    position:'top-right',
                    autoClose:2000,
                })
                console.log('sequence creation failed ---> ',res)
            }
        })
    }

    const updateSequenceAction = (_id, type, toastId) => {
        let token = auth_token;
        let data = {
            setting: {
                replyTo: receiveOn,
            },
            campaignSchedule: {
                days: []
            },
            campaignName: campaignName,
            status: 'Active',
            senderIds: selectedSenderID,
            frontend: {
                nodes: nodes.slice(0,-1),
                edges: edges,
            },
            labelId: selectedLabel?._id,
        }
        updateSequence(data,token,_id).then((res)=>{
            if(res?.flag){
                toast.update(toastId,{
                    render:"Sequence updated successfully. Preparing to send...", 
                    type: toast.TYPE.INFO,
                    isLoading: true,
                    autoClose:false,
                    position: 'top-right',
                })
                console.log("successfully created sequence --- ",res)
                if(type === 'announcement'){
                    sendSequenceAnnouncementAction(_id, toastId);
                }else if(type === 'test'){
                    sendSequenceTestAction(_id, toastId);
                }
            }else{
                toast.update(toastId,{
                    render:"Error updating sequence.", 
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose:2000,
                    position: 'top-right',
                })
                console.log("error in creating sequence ",res);
            }
        })
    }

    const sendSequenceAnnouncementAction = (_id, toastId) => {
        let token = auth_token;
        let data = {
            _id: sequenceID ? sequenceID : _id,
        }
        sendSequenceAnnouncement(data,token).then((res)=>{
            if(res?.flag){
                toast.update(toastId,{
                    render:"Sequence Announcement sent successfully!", 
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose:2000,
                    position: 'top-right',
                })
                setTimeout(() => {
                    navigate('/creator/sequences');
                }, 2500);
                console.log("success started announcement ",res);
            }else{
                toast.update(toastId,{
                    render:"Failed to send announcement.", 
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose:2000,
                    position: 'top-right',
                })
                console.log("failed to send announcement ",res)
            }
        })
    }

    const sendSequenceTestAction = (_id, toastId) => {
        let token = auth_token;
        let data = {
            campaignId: sequenceID ? sequenceID : _id,
            toEmail: testEmail
        }
        sendSequenceTest(data,token).then((res)=>{
            if(res?.flag){
                toast.update(toastId,{
                    render:"Test sent successfully!", 
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose:2000,
                    position: 'top-right',
                })
                console.log("res -- ",res);
            }else{
                toast.update(toastId,{
                    render:"Test failed to send.", 
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose:2000,
                    position: 'top-right',
                })
                console.log("res failed ",res)
            }
        })
    }

  return (
    <>
    <div className='Column justify-content-start align-items-center w-100 h-100' style={{
        overflow: 'hidden',
    }}>
        {isISModalOpen && 
            <ImportSettingsModal 
                setIsImportSettingsModalOpen={setIsISModalOpen} 
                verifyEmail={verifyEmail} 
                setVerifyEmail={setVerifyEmail}
            />
        }
        {isRTModalOpen && 
            <RunTestModal 
                setIsModalOpen={setIsRTModalOpen} 
                testEmail={testEmail} 
                setTestEmail={setTestEmail} 
                onclickruntestaction={()=>{
                    startSequenceAction('test');
                }}
            />
        }
        <div className='Column justify-content-start align-items-center w-100' style={{
            paddingBlock: 24,
            paddingInline: 30,
            borderBottom:`1px solid ${colors.borderwhite}`,
            backgroundColor: colors.white01,
        }}>
            <div className='Row justify-content-start w-100 mb-3'>
                <img 
                    src={images.BackArrowBlack} 
                    style={{height:24,width:24,objectFit:'contain',cursor:'pointer',marginInlineEnd:16}} 
                    onClick={()=>{
                        if(activeStep){
                            setActiveStep(activeStep-1)
                        }else{
                            navigate(-1)
                        }
                    }}
                />
                <h3 style={{fontSize:24,fontWeight:700}} onClick={()=>{
                    console.log("999999 nodes ",nodes)
                    console.log("999999 edges ",edges)
                }}>
                    {'Create a new sequence'}
                </h3>
            </div>
            <div className='Row justify-content-between w-100 my-3'>
                <InputGroup className="mb-2" style={{width:'30%',marginInlineEnd:20}}>
                    {/* <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                        {'Messages per day'}
                    </Form.Label> */}
                    <Form.Control
                        type="text" 
                        placeholder='Campaign Name'
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={campaignName}
                        onChange={(e) => {
                            setCampaignName(e.target.value);
                        }}
                    />
                </InputGroup>
                <div style={{flex:1, marginInline:20}}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </div>
        </div>

        {activeStep === Number(0) && 
        <>
        <div className='Column justify-content-center w-100' style={{flex:1,paddingBlock:30}}>
            {isVerifyModalOpen && 
                <VerifyEmailModal 
                    labelId={selectedLabel?._id} 
                    onclickproceedaction={()=>{
                        setIsVerifyModalOpen(false);
                        setActiveStep(1)
                    }}
                />
            }
            <div className='Column justify-content-start' style={{
                width:450,
                maxHeight:'100%',
                overflow: 'hidden',
                backgroundColor:colors.white01,
                borderRadius:16,
                paddingBlock:24,
                paddingInline:30,
                border:`1px solid ${colors.borderwhite}`
            }}>

                <div className='Row justify-content-between w-100'>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'Select audience segment'}
                    </h4>
                    <IoMdSettings 
                        size={18} 
                        style={{cursor:'pointer',objectFit:'contain'}} 
                        onClick={()=>{
                            setIsISModalOpen(true)
                        }}
                    />
                </div>
                <div className='Column justify-content-start w-100' style={{marginBlock:24}}>
                    <h4 style={{fontSize:16,fontWeight:400,width:'100%'}}>
                        {'Select Existing Label'}
                    </h4>
                    <Form.Select
                        type="text"
                        placeholder="Select label"
                        className="btncustom dropdown mt-2"
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400}}
                        value={selectedLabel?._id}
                        onChange={(e) => {
                            setSelectedLabel(labelList.filter((item)=>item?._id === e.target.value)[0]);
                        }}
                    >
                        <option
                            style={{ width: '100%', marginBlock: 4, borderRadius: 8}}
                            key="blankchoice"
                            hidden
                            value
                        >
                            {'Select label'}
                        </option>
                        {labelList &&
                        labelList.map((e) => {
                            return (
                                <option
                                style={{
                                    width: '100%',
                                    marginBlock: 4,
                                    borderRadius: 8,
                                }}
                                value={e._id}
                                >
                                {e.label_name}
                                </option>
                            );
                        })}
                    </Form.Select>
                </div>
                {/* <div className='Column justify-content-start w-100' style={{marginBlock:24}}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'OR'}
                    </h4>
                </div>
                <div className='Column justify-content-start w-100' style={{marginBlock:24}}>
                    <h4 style={{fontSize:16,fontWeight:400,width:'100%'}}>
                        {'Upload a new list'}
                    </h4>
                    <div className='mt-2 Row justify-content-center w-100' style={{
                        border:`2px dashed ${colors.borderwhite}`,
                        paddingBlock:40,
                        paddingInline:30,
                        borderRadius:16
                    }}>
                        <img 
                            src={images.ImgUpIcon} 
                            style={{height:40,width:60,objectFit:'contain'}}
                        ></img>
                        <p className='mx-2'>
                            {'Drag & drop file here/ upload'}
                        </p>
                    </div>
                </div> */}
                <CheerioButton 
                    btnText={'Next'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,width:'100%'}} 
                    onclick={()=>{
                        if(verifyEmail){
                            setIsVerifyModalOpen(true);
                        }else{
                            setActiveStep(1)
                        }
                    }} 
                    disabled={selectedLabel ? false : true}
                />
            </div>
        </div>
        </>
        }

        {activeStep === Number(1) && 
        <>
        <div className='Row justify-content-center w-100' style={{
            flex:1,
        }}>
            <SequenceFlow 
                setActiveStep={setActiveStep} 
                nodes={nodes} 
                setNodes={setNodes} 
                onNodesChange={onNodesChange} 
                edges={edges} 
                setEdges={setEdges} 
                onEdgesChange={onEdgesChange}
            />
        </div>
        </>
        }

        {activeStep === Number(2) && 
        <>
        <div className='Column justify-content-center align-items-center w-100' style={{
            flex:1,
        }}>
            <CampaignSetup 
                setActiveStep={setActiveStep} 
                campaignName={campaignName} 
                setCampaignName={setCampaignName} 
                receiveOn={receiveOn} 
                setReceiveOn={setReceiveOn} 
                selectedSenderID={selectedSenderID} 
                setSelectedSenderID={setSelectedSenderID} 
            />
        </div>
        </>
        }

        {activeStep === Number(3) && 
        <>
        <div className='Row justify-content-center w-100' style={{
            flex:1,
        }}>
            <SequenceFlow 
                setActiveStep={setActiveStep} 
                nodes={nodes} 
                setNodes={setNodes} 
                onNodesChange={onNodesChange} 
                edges={edges} 
                setEdges={setEdges} 
                onEdgesChange={onEdgesChange} 
                review={true} 
                selectedLabel={selectedLabel} 
                onclickruntestaction={()=>{setIsRTModalOpen(true)}} 
                onclickstartsequenceaction={()=>{
                    startSequenceAction('announcement');
                }}
            />
            {/* <CampaignReviewSend selectedLabel={selectedLabel} /> */}
        </div> 
        </>
        }

    </div>
    </>
  )
}

export default CreateSequence