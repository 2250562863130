import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, InputGroup } from 'react-bootstrap';

const UpdateBroadCastModal = ({ data, setData }) => {
  const authtoken = useSelector((state) => state.main.auth_token);

  const initialMode = data.broadcast ? 'Allow' : 'Disallow';

  const handleModeChange = (mode) => {
    setData({
      ...data,
      broadcast: mode === 'Allow', 
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p
          style={{
            fontWeight: 400,
            fontSize: 16,
            paddingTop: '3.5%',
            color: '#666666',
            paddingLeft: '2%',
            textAlign: 'left',
          }}
        >
          You can set the broadcast as allow or disallow to enable or disable the broadcasting on WhatsApp
        </p>
      </div>
      <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
        <Form.Select
          type="text"
          placeholder="select"
          className="w-100 btncustom dropdown"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: '#000' }}
          value={initialMode}
          onChange={(e) => handleModeChange(e.target.value)}
        >
          <option
            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
            value={'Allow'}
            key={'Allow'}
          >
            {'Allow'}
          </option>
          <option
            style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
            value={'Disallow'}
            key={'Disallow'}
          >
            {'Disallow'}
          </option>
        </Form.Select>
      </InputGroup>
    </div>
  );
};

export default UpdateBroadCastModal;
